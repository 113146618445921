import traced from '../../../../shared/assets/images/traced.png';
import s from './MobileStartTrainingForFree.module.scss';
import { TextConstructor } from "../../../../shared/ui/TextConstructor";

export const MobileStartTrainingForFree = () => (
  <div className={s.mobileStartTrainingForFree}>
    <TextConstructor fz='30' color="#202020" font='Akzidenz-Grotesk' fw='700' lh='32'>
      Начни обучение <br />
    </TextConstructor>
    <TextConstructor fz='30' color="#74B500" font='Akzidenz-Grotesk' fw='700' lh='32'>
      бесплатно
    </TextConstructor>
    <img src={traced} alt="*" className={s.mobileStartTrainingForFreeImage} />
  </div>
)
