import { FC } from "react";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import { NewButton } from "../../../../shared/ui/NewButton";
import s from './DesktopTextBlock.module.scss';
import Exclude from '../../../../shared/assets/images/Exclude.svg'
import classNames from "classnames";
import { toCourses } from "../../../../shared/config/routeConfig/routeConfig";

interface DesktopTextBlockProps {
  className?: string;
}

export const DesktopTextBlock: FC<DesktopTextBlockProps> = (props) => {
  const {
    className,
  } = props;

  return (
    <VStack align="end" className={classNames(className, s.desktopTextBlock)}>
      <NewText name="Text" className={s.blackLittleText}>
        Начни применять знания сразу после прохождения курса
      </NewText>

      <TextConstructor color="#202020" fw="500" lh="150" fz="180" font="Akzidenz-Grotesk" className={s.blackText}>
        Учись
      </TextConstructor>
      <TextConstructor color="#202020" fw="500" lh="150" fz="180" font="Akzidenz-Grotesk" className={s.blackText}>
        на чужом
      </TextConstructor>

      <HStack align="end">
        <TextConstructor color="#74B500" fw="500" lh="150" fz="180" font="Akzidenz-Grotesk" className={s.text}>
          опыте
        </TextConstructor>
        <div className={s.buttonWrapper}>
          <NewButton
            typeButton="LinkSquare"
            to={toCourses()}
            color="black"
            size="l"
            classNameButton={s.btn}
          >
            <NewText name="Bt" color="white" className={s.buttonText}>
              Выбрать <br /> курс
            </NewText>
          </NewButton>

          <img src={Exclude} alt="*" className={classNames(s.svg, s.svgFirst)} />
          <img src={Exclude} alt="*" className={classNames(s.svg, s.svgSecond)} />
        </div>
      </HStack>
    </VStack>
  );
};