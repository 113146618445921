import { FC } from "react";
import paint from '../../../shared/assets/images/paint.svg';
import s from './TitleReviews.module.scss';
import classNames from "classnames";
import { TextConstructor } from "../TextConstructor";
import { NewText } from "../NewTypography/NewTypography";

interface TitleReviewsProps {
  devices: 'desktop' | 'tablet' | 'mobile';
}

export const TitleReviews: FC<TitleReviewsProps> = ({ devices }) => {
  const Content = () => {
    if (devices === 'mobile') {
      return (
        <>
          <TextConstructor font="Akzidenz-Grotesk" fw="700" fz="30" lh="32">
            Свежие отзывы о нас
          </TextConstructor>

          <img src={paint} alt="*" className={classNames(s.image, devices)} />
        </>
      )
    }

    return (
      <>
        <NewText name="H2">
          Свежие <br /> отзывы <br /> о нас
        </NewText>

        <img src={paint} alt="*" className={classNames(s.image, devices)} />
      </>
    )
  }

  return (
    <div className={s.titleReviews}>
      {Content()}
    </div>
  );
};
