import React, { FC } from 'react'
import classes from './LessonItem.module.scss'
import DoneIcon from '../../../../../shared/assets/images/done.svg'
import LockIcon from '../../../../../shared/assets/images/lock.svg'
import { getTimeLesson } from '../../../../../shared/utils/getTimeLesson'
import { LessonRequestResultType } from '../../../../../entities/Lesson/types/lessonTypes'
import { Link, useParams } from 'react-router-dom'
import { toLessonPage } from '../../../../../shared/lib/links/toLessonPage'

interface IProps {
  lesson: LessonRequestResultType
  idx: number
}
export const LessonItem:FC<IProps> = ({idx, lesson}):JSX.Element => {
  const { courseId } = useParams()
  return (
    <div className={classes.root}>

        <Link className={classes.link} to={toLessonPage(String(courseId), String(lesson?.id))}>
        <div className={classes.text__wrapper}>
          {lesson?.user_complete ? (
            <div className={classes.icon}>
              <img src={DoneIcon} alt="done" />
            </div>
          ) : null}
          {!lesson?.is_available ? (
            <div className={classes.icon}>
              <img src={LockIcon} alt="done" />
            </div>
          ) : null}
          <span style={{paddingLeft: lesson?.user_complete ? '0px' : '40px'}} className={classes.lesson}>{idx + 1} урок</span>
          <span style={{color: lesson?.user_complete ? '#0D8E74' :' #0E0E06'}}  className={classes.lesson__name}>{lesson.name}</span>
        </div>
    </Link>

        {/* <span className={classes.time}>{getTimeLesson(lesson.learn_time)}</span> */}
      </div>
  )
}
