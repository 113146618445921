import { Dispatch, FC, SetStateAction } from "react"
import classes from './Timing.module.scss'
import { getTimeLesson } from "../../../../../shared/utils/getTimeLesson"

interface IProps {
  timing: Record<string, string>
  setTiming: Dispatch<SetStateAction<string>> 
}

export const Timing:FC<IProps> = ({timing, setTiming}):JSX.Element => {



  function convertTimeToMs(timeString: string) {
    const [minutes = 0, seconds = 0] = timeString.split(':').map(Number);
    const totalSeconds = minutes * 60 + seconds;
    const minutesPart = Math.floor(totalSeconds / 60);
    const secondsPart = totalSeconds % 60;
    return `${minutesPart}m${secondsPart}s`;
}

  return (
    <div className={classes.root}>
      {Object.keys(timing)?.map((el, idx) => (
        <div className={classes.wrapper} onClick={() => setTiming(convertTimeToMs(el))} key={idx}>
          <span className={classes.time}>{el}</span>
          <span className={classes.text}>{timing[el]}</span>
        </div>
      ))}
    </div>
  )
}
