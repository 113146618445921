import { LMS_AUTH_API } from "../../const/ENV";
import { REFRESH_TOKEN_LOCALSTORAGE_KEY } from "../../const/localstorage";

const requestCash: { value: any, time: any } = {
  value: null,
  time: null,
};

const fetchToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

  return fetch(`${LMS_AUTH_API}refresh_tk/`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${refreshToken}`
    }
  }).then((res) => res.json());
}

export const getNewToken = () => {
  if (
    !requestCash.value
    || (performance.now() - requestCash.time) > 90_000
  ) {
    const res = fetchToken();

    requestCash.value = res;
    requestCash.time = performance.now();

    return res;
  } else {
    return requestCash.value;
  }
}