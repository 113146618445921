import { ButtonHaveQuestions } from "../ButtonHaveQuestions/ButtonHaveQuestions";
import { TextHaveQuestions } from "../TextHaveQuestions/TextHaveQuestions";
import { VisualElementsHaveQuestions } from "../VisualElementsHaveQuestions/VisualElementsHaveQuestions";
import s from './TabletHaveQuestions.module.scss';

export const TabletHaveQuestions = () => {
  return (
    <div className={s.tabletHaveQuestions}>
      <TextHaveQuestions className={s.textHaveQuestions} device="tablet" />
      <VisualElementsHaveQuestions className={s.visualElementsHaveQuestions} />
      <ButtonHaveQuestions />
    </div>
  );
};
