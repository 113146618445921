import { useMediaQuery } from "../../../shared/hooks";
import { Container } from "../../../shared/ui/Container";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { Calendar } from "../../../widgets/Calendar";
import { Footer } from "../../../widgets/Footer";
import { Header } from "../../../widgets/Header";
import { MyCoursesList } from "../../../widgets/MyCoursesList";

export const MyStudyPage = () => {
  const match = useMediaQuery('(min-width: 1300px)');

  const Componetn = match ? HStack : VStack;

  return (
    <>
      <Header />

      <VStack gap="32" style={{ marginTop: 70 }}>
        <Container>
          {/* @ts-ignore */}
          <Componetn gap="20" align="start" {...match ? ({ direction: 'row-reverse' }) : ({})}>
            <HStack max justify="center">
              <Calendar />
            </HStack>

            <VStack gap="32">
              <TextConstructor color="#0E0E06" fw="600" fz="36" lh="40">
                Мое обучение
              </TextConstructor>

              <MyCoursesList />
            </VStack>
          </Componetn>
        </Container>
        <Footer/>
      </VStack>
    </>
  );
};
