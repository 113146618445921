import { HStack } from "../../../../shared/ui/Stack";
import { SliderBlock } from "../SliderBlock/SliderBlock";
import { TextBlock } from "../TextBlock/TextBlock";
import s from './TabletIntroBlock.module.scss';

export const TabletIntroBlock = () => {
  return (
    <HStack>
      <TextBlock device="tablet" className={s.tabletIntroBlock} />
      <SliderBlock device="tablet" />
    </HStack>
  );
};
