import { FC } from 'react';
import { HStack, VStack } from '../../../../../shared/ui/Stack';
import { TextConstructor } from '../../../../../shared/ui/TextConstructor';
import s from './CourseCardMine.module.scss';
import { ProgressBar } from '../../../../../shared/ui/ProgressBar';
import { Button } from '../../../../../shared/ui/Button';
import { toCoursePage } from '../../../../../shared/lib/links';
import classNames from 'classnames';
import starFillImage from '../../../../../shared/assets/images/star-fill.svg'

interface CourseCardMineProps {
  image: string;
  isShowTagHitSales: boolean;
  duration: string;
  courseName: string;
  description: string;
  href: string;
  courseId: number;
  progress: number;
  rating: number;
  commentCount: number;
  onClickRating: (courseId: number) => void;
}

export const CourseCardMine: FC<CourseCardMineProps> = (props) => {
  const { image, courseName, description, progress, courseId, rating, commentCount, onClickRating } = props;

  return (
    <VStack gap='12' className={s.courseCardMine} justify='between'>
      <VStack gap='12' max>
        <HStack gap='12' align='start'>
          <img className={s.image} src={image} alt='*' />
          <VStack gap='10'>
          <TextConstructor fz='32' color='#0E0E06' fw='600' lh='32'>
            {courseName}
          </TextConstructor>
          <TextConstructor fz='20' color='#5B5B5B' fw='400' lh='28'>
            {description?.length > 100 ? description?.slice(0, 100) + '...' : description}
          </TextConstructor>
          </VStack>
        </HStack>
      </VStack>

      <VStack gap='12' max justify='stretch'>
        <ProgressBar
          progress={progress}
          leftNode={
            <TextConstructor color='#9F9FA0' fz='15' lh='20' fw='400'>
              Пройдено
            </TextConstructor>
          }
          rightNode={
            <TextConstructor color='#9F9FA0' fz='15' lh='20' fw='400'>
              {progress}%
            </TextConstructor>
          }
        />

        <Button typeButton='LinkSquare' color='green' to={toCoursePage(String(courseId))} stretch>
          Продолжить учиться
        </Button>
      </VStack>
      <button className={classNames('resetButtonTagStyles', s.rating)} onClick={() => onClickRating(courseId)}>
          <HStack gap="24">
            <HStack gap="8">
              <img src={starFillImage} alt="*" />
              <TextConstructor color="#0E0E06" fz="20" lh="24" fw="500">
                {rating}
              </TextConstructor>
            </HStack>

            <TextConstructor>
              {commentCount} отзыв
            </TextConstructor>
          </HStack>
        </button>
    </VStack>
  );
};
