import React, { FC, ReactNode, useRef } from 'react';
import s from './Input.module.scss';
import { useFocus } from '../../../hooks/useFocus/useFocus';
import classNames from 'classnames';

interface InputProps {
  value: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  label?: string;
  errorText?: string;
  className?: string;
  rightNode?: ReactNode;
  type?: 'text' | 'password';
  placeholder?: string;
  inputRefProp?: React.MutableRefObject<HTMLInputElement | null>;
  readOnly?: boolean; 
}

export const Input: FC<InputProps> = (props) => {
  const {
    value,
    onChange,
    label,
    errorText,
    className,
    rightNode,
    type = 'text',
    placeholder,
    inputRefProp,
    readOnly,
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);
  const currentInputRef = inputRefProp || inputRef;
  const isFocus = useFocus(currentInputRef);
  const isLabelTop = value || isFocus;

  const onFocus = ({ currentTarget }: React.FocusEvent<HTMLElement>) => {
    if (readOnly) {
      currentTarget.blur();
    }
  }
  
  return (
    <div className={classNames(s.inputContainer, className)}>
      {label && (
        <label
          className={classNames(s.label, isLabelTop ? s.labelTop : s.labelCenter, value && s.filled, errorText && s.labelError)}
        >
          {label}
        </label>
      )}
      <input
        ref={currentInputRef}
        className={classNames(s.input, errorText && s.inputError, value && s.filled, readOnly && s.readOnly)}
        value={value}
        type={type}
        placeholder={placeholder}
        tabIndex={-1}
        onFocus={onFocus}
        {...readOnly ? {} : { onChange }}
      />
      {errorText &&(
        <div className={s.errorText}>
          {errorText}
        </div>
      )}
      {rightNode && (
        <div className={s.rightNode}>
          {rightNode}
        </div>
      )}
    </div>
  );
}
