import { CourseCard, CoursesList, mapCoursesWithAuthors, mapCoursesWithVisualElements } from "../../../../entities/Course";
import { useCoursesQuery } from "../../../../entities/Courses";
import { useMyCoursesQuery } from "../../../MyCoursesList/api/myCoursesListApi";
import { CardSoonMore } from "../../../CardSoonMore";

export const FreeCoursesShort = () => {
  const { data } = useCoursesQuery();
  const { data: dataMyCourses } = useMyCoursesQuery();

  const courses = [...(data?.result || [])].filter(({ free }) => free).splice(0, 3);
  const myCourses = dataMyCourses?.result;

  const coursesWithVisualElements = mapCoursesWithVisualElements(courses);
  const coursesWithAuthors = mapCoursesWithAuthors(coursesWithVisualElements);
  const coursesWithAvaible = coursesWithAuthors.map((course) => ({ ...course, isAvailable: !!myCourses?.some(({ id }) => id === course.id) }));

  return (
    <div>
      <CoursesList>
        {coursesWithAvaible.map(({
          // @ts-ignore
          id, name, description, pay_button_text, author, landing_link, isAvailable,
        }) => (
          <CourseCard
            id={String(id)}
            name={name}
            description={description}
            buttonText={pay_button_text}
            author={author}
            href={landing_link}
            isAvailable={isAvailable}
            tags={['бесплатно', 'Хит']}
          />
        ))}
        <CardSoonMore />
      </CoursesList>
    </div>
  );
};
