import { FC, ReactNode } from 'react';
import s from './Grid.module.scss';
import classNames from 'classnames';

interface GridProps {
  horizontalGap?: '20' | '32';
  colums?: '2' | '1' | '4' | '7';
  verticalGap?: '32' | '20' | '30';
  children: ReactNode;
  maxHorizontal?: boolean;
  maxVertical?: boolean;
  className?: string;
}

export const Grid: FC<GridProps> = (props) => {
  const {
    colums,
    children,
    horizontalGap,
    verticalGap,
    maxHorizontal,
    maxVertical,
    className,
  } = props;

  return (
    <div
      className={classNames(s.grid, className, {
        [s.maxHorizontal]: maxHorizontal,
        [s.maxVertical]: maxVertical,
      })}
      style={{ gridTemplateColumns: `repeat(${colums}, 1fr)`, columnGap:  `${horizontalGap}px`, rowGap: `${verticalGap}px` }}
    >
      {children}
    </div>
  );
};
