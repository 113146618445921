import { FC } from "react";
import { VStack } from "../../../../shared/ui/Stack";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import s from './TextWhyChooseUs.module.scss';
import classNames from "classnames";

interface TextWhyChooseUsProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

const options = [
  {
    title: 'Эксперты-практики',
    text: 'Авторы курсов имеют собственный успешный бизнес или работают в крупных компаниях и хорошо известны в профессиональных кругах.',
  },
  {
    title: 'Обучение 24/7',
    text: 'Приобретайте навыки в удобном для себя темпе и из любой точки мира, а приступить к изучению любого курса можно сразу же после оплаты.',
  },
  {
    title: 'Курсы «без воды»',
    text: 'Главный принцип наших обучающих программ - давать информацию, применимую на практике. Мы предлагаем вам не утопать в теории годами, поэтому даем вам пошаговое руководство по применению полученных навыков.',
  },
  {
    title: 'Бонус от «Цифрового поколения»',
    text: 'Вы можете пройти бесплатные курсы, чтобы "опробовать" функционал платформы, убедиться в ценности информации и комфорте обучения, а также оценить качество записанных видео-уроков.',
  },
  {
    title: 'Бизнес-тиндер',
    text: 'Закрытое сообщество «выпускников» платформы, в котором можно найти партнера/сотрудника/работодателя. Нетврокинг от Цифрового Поколения.',
  },
];

export const TextWhyChooseUs: FC<TextWhyChooseUsProps> = ({ device }) => {
  const isMobile = device === 'mobile';

  const getContent = (title: string, text: string) => {
    if (isMobile) {
      return (
        <VStack gap="10">
          <TextConstructor color="#202020" fw="500" fz="20" lh="25" font="Akzidenz-Grotesk">
            {title}
          </TextConstructor>
          <TextConstructor color="#464646" fw="500" fz="16" lh="20">
            {text}
          </TextConstructor>
        </VStack>
      );
    }

    return (
      <VStack gap="10">
        <NewText name="H4">
          {title}
        </NewText>
        <TextConstructor color="#464646" fw="400" fz="16" lh="20">
          {text}
        </TextConstructor>
      </VStack>
    )
  }

  return (
    <VStack gap={isMobile ? '30' : '50'} className={classNames(s.textWhyChooseUs, s[device])}>
      {options.map(({ title, text }) => getContent(title, text))}
    </VStack>
  );
};
