import React, { FC } from 'react'
import { BlockDataFile } from '../../../../../entities/Lesson/types/lessonTypes'
import { Card } from '../../../../../shared/ui/Card'
import { FileItem } from '../FileItem/FileItem'


interface IProps {
  data: BlockDataFile
}
export const Material:FC<IProps> = ({data}):JSX.Element => {

  return (
    <Card title='Материалы:'>
      <FileItem data={data}/>
    </Card>
  )
}
