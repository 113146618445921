import { useDispatch, useSelector,  } from "react-redux";
import { LoginForm, LoginFormProps } from "../../../entities/LoginForm";
import { getAuthorizationFormPassword, getAuthorizationFormPhone } from "../../../shared/model/authorizationForm";
import { FC, useCallback, useEffect, useState } from "react";
import { useLoginMutation } from "../api/loginApi";
import { getPhoneForServer, isCorrectPhone } from "../../../shared/lib/phone";
import { authorizationFormActions } from "../../../shared/model/authorizationForm/slices/authorizationForm";

interface ValidateErrors {
  phone: 'Это обязательное поле' | 'Неккоректный номер телефона' | 'Телефон не найден' | 'Телефон заблокирован' | '',
  password: 'Это обязательное поле' | 'Неверный пароль' | '',
}

export interface LoginProps {
  toRegistration: () => void;
  goRestore: () => void;
  onStepCompleted: (obj: { isPhoneConfirmed: boolean }) => void;
}

export const Login: FC<LoginProps> = (props) => {
  const {
    toRegistration,
    goRestore,
    onStepCompleted,
  } = props;

  const dispatch = useDispatch();

  const phone = useSelector(getAuthorizationFormPhone);
  const password = useSelector(getAuthorizationFormPassword);

  const [isShow, setIsShow] = useState(false);
  const [validateErrors, setValidateErrors] = useState<ValidateErrors>({
    password: 'Это обязательное поле',
    phone: 'Это обязательное поле',
  });

  const [login, { isSuccess, data, isError, error, isLoading }] = useLoginMutation();

  useEffect(() => {
    if (isSuccess) {
      const isPhoneConfirmed = !!data?.result.phone_confirmed;

      onStepCompleted({ isPhoneConfirmed });
    }
  }, [data?.result.phone_confirmed, data?.result.tk, data?.result.tk_refresh, isSuccess, onStepCompleted]);

  const onChangePhone = (phoneNumber: string) => {
    dispatch(authorizationFormActions.setAuthorizationFormPhone(phoneNumber));

    if (!isCorrectPhone(phoneNumber)) {
      setValidateErrors((prev) => ({ ...prev, phone: 'Неккоректный номер телефона' }));
    } else if (phoneNumber) {
      setValidateErrors((prev) => ({ ...prev, phone: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, phone: 'Это обязательное поле' }));
    };
  };

  const onChangePassword: LoginFormProps['onChangePassword'] = useCallback(({ currentTarget: { value } }) => {
    dispatch(authorizationFormActions.setAuthorizationFormPassword(value));

    if (value) {
      setValidateErrors((prev) => ({ ...prev, password: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, password: 'Это обязательное поле' }));
    };
  }, []);

  useEffect(() => {
    if (phone) {
      onChangePhone(phone);
    }

    if (password) {
      onChangePassword({ currentTarget: { value: password } } as React.FormEvent<HTMLInputElement>);
    }
  }, []);

  const onEnter = () => {
    setIsShow(true);
    
    if (!Object.values(validateErrors).some((error) => error)) {
      const newPhone = getPhoneForServer(phone);

      login({
        phone: newPhone,
        password,
      });
    };
  };

  useEffect(() => {
    if (isError && error && 'data' in error && typeof error.data === 'object' && error.data && 'message' in error.data) {
      if (error?.data?.message === 'User not found') {
        setValidateErrors((prev) => ({ ...prev, phone: 'Телефон не найден' }));
      }

      if (error?.data?.message === 'Authorization denied') {
        setValidateErrors((prev) => ({ ...prev, phone: 'Телефон заблокирован' }));
      }

      if (error?.data?.message === 'Authorization failed') {
        setValidateErrors((prev) => ({ ...prev, password: 'Неверный пароль' }));
      }
    }
  }, [error, isError]);

  return (
    <LoginForm
      phone={phone}
      password={password}
      onChangePhone={onChangePhone}
      onChangePassword={onChangePassword}
      errorPhone={isShow ? validateErrors.phone : ''}
      errorPassword={isShow ? validateErrors.password : ''}
      goToRegistrationStep={toRegistration}
      goToRestoreStep={goRestore}
      onEnter={onEnter}
      isLoading={isLoading}
    />
  );
};
