export const text = [
  {
   question : 'Как зарегистрироваться на Платформе?',
    answer: `<p style="font-weight: 400; font-size: 20px;"><span style="font-weight: 800;">Для того чтобы зарегистрироваться на сайте,</span> вам достаточно перейти на форму регистрации. При первичной авторизации понадобится привязать ваш номер моб. телефона и задать пароль. Повторная авторизация будет использовать ваш пароль, который вы указывали на первичном этапе регистрации. <br/> <br/> <span style="font-weight: 800;">Для того чтобы обучаться на нашей платформе,</span> необходима регистрация вас, как пользователя. Сразу после регистрации вам будут доступны БЕСПЛАТНЫЕ КУРСЫ!!!</p>`,
  },
  {
   question : 'Как приобрести КУРСЫ?',
   answer : `<p style="font-weight: 400; font-size: 20px;"><span style="font-weight: 800;">Курсы можно приобрести непосредственно на платформе</span> - в разделе "Каталог Курсов", также рекомендую следить за нашими маркетинговыми активностями - лэндинги никто не отменял=) <br/><br/> <span style="font-weight: 800;">Каждый образовательный курс состоит из следующих  элементов:</span> лекции, проверочные тесты, доп. материалы - их можно скачать прямо из урока. <br/><br/> <span style="font-weight: 800;">ВАЖНО:</span> Если вы еще не авторизованный юзер, то с формы оплаты вы будете перенаправлены на регистрацию, после этого вы сразу сможете приобрести курсы. Это сделано, чтобы обезопасить вас от мошеннических действий в сети.</p>`,
  },

]