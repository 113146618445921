import { ButtonHaveQuestions } from "../ButtonHaveQuestions/ButtonHaveQuestions";
import { TextHaveQuestions } from "../TextHaveQuestions/TextHaveQuestions";
import { VisualElementsHaveQuestions } from "../VisualElementsHaveQuestions/VisualElementsHaveQuestions";
import s from './DesktopHaveQuestions.module.scss';

export const DesktopHaveQuestions = () => {
  return (
    <div className={s.desktopHaveQuestions}>
      <TextHaveQuestions className={s.textHaveQuestions} device="desktop" />
      <VisualElementsHaveQuestions className={s.visualElementsHaveQuestions} />
      <ButtonHaveQuestions />
    </div>
  );
};
