import picture from '../../../../../shared/assets/images/young-blonde-woman-blue-sweater 1 (2).jpg';
import { FC } from "react";
import figureOne from '../../../../../shared/assets/images/money png (10).png';
import { CourseSlideTemplate } from "../CourseSlideTemplate/CourseSlideTemplate";

interface SlideThirdProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SlideThird: FC<SlideThirdProps> = ({ device }) => {
  return (
    <CourseSlideTemplate
      device={device}
      figureSrc={figureOne}
      href="https://цифровоепоколение.рф/2"
      picture={picture}
      subTitle="Курс по социальным сетям"
      title="Поздравьте меня с первым клиентом. Полёт нормальный!"
      backgroundColor="#A0D7FF"
      labelTitle="Ирина"
      labelSubtitle="Начинающий СММ специалист"
      labelBackgroundColor="#0053B5"
    />
  )
};
