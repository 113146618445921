import { Grid } from "../../../shared/ui/Grid";
import { NewText } from "../../../shared/ui/NewTypography/NewTypography";
import { VStack } from "../../../shared/ui/Stack";
import star from '../../../shared/assets/images/Star png.png';
import percent from '../../../shared/assets/images/Group 1597880745.png';
import s from './ReviewsBlocks.module.scss';
import { useMediaQuery } from "../../../shared/hooks";

const blocksContent = [
  {
    title: '4.7',
    subtitle: 'Средний рейтинг курсов',
  },
  {
    title: '89%',
    subtitle: 'Высоко оценили материалы курсов',
  },
  {
    title: '98%',
    subtitle: 'Оценили общение с куратором',
  },
  {
    title: '78%',
    subtitle: 'Освоили новую специальность',
  },
];

export const ReviewsBlocks = () => {
  const showDesktop = useMediaQuery('(min-width: 1300px)');

  return (
    <Grid colums={showDesktop ? '4' : '1'} horizontalGap="20" verticalGap="20" className={s.blocks}>
      {blocksContent.map(({ title, subtitle }) => (
        <VStack className={s.block} gap="24" align="center">
          <NewText name="Chapter_MediumExtended" textAlign="center">{title}</NewText>
          <NewText name="Title_3_Regular" textAlign="center">{subtitle}</NewText>
        </VStack>
      ))}

      <img src={star} alt="" className={s.star} />
      <img src={percent} alt="" className={s.percent} />
    </Grid>
  );
};
