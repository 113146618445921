import React, { useEffect, useState } from 'react';
import { TextConstructor } from '../../../shared/ui/TextConstructor';
import { HStack, VStack } from '../../../shared/ui/Stack';
import { useLazyFormByIdQuery, usePostFormByIdMutation } from '../api/paymentApi';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import s from './Payment.module.scss';
import { Price } from '../../../shared/ui/Price';
import { declOfNum } from '../../../shared/lib/numbers';
import { PaymentMethod } from '../../../entities/PaymentMethod';
import LogoImage from '../../../shared/assets/images/logo.png';
import InfoCircleImage from '../../../shared/assets/images/info-circle.svg';
import { RoutePath } from '../../../shared/config/routeConfig/routeConfig';
import { Input } from '../../../shared/ui/Input';
import { PhoneInput } from '../../../shared/ui/PhoneInput';
import { Checkbox } from '../../../shared/ui/Checkbox';
import { Button } from '../../../shared/ui/Button';
import { useIsAuth } from '../../../shared/hooks/useIsAuth';
import { useDispatch, useSelector } from 'react-redux';
import { authorizationFormActions } from '../../../shared/model/authorizationForm/slices/authorizationForm';
import { getAuthorizationFormEmail, getAuthorizationFormFullName, getAuthorizationFormPhone } from '../../../shared/model/authorizationForm';
import { getUserAuthData } from '../../../entities/User/model/selectors/getUserData/getUserData';
import { PaymentRequestResultType } from '../types/paymentTypes';
import { getPhoneForServer } from '../../../shared/lib/phone';
import { Text } from '../../../shared/ui/Typography/Typography';

type ActivePayMethodType = PaymentRequestResultType['payment_methods'][0]['info']['pay_method_type'] | null;

interface ValidateErrors {
  payMethod: 'Необходимо выбрать платежный метод' | '';
  isCheckPrivacy: 'Необходимо выбрать платежный метод' | '';
  isCheckOferta: 'Необходимо выбрать платежный метод' | '';
}

export const Payment = () => {
  const { formId } = useParams<{ formId: string }>();
  const dispatch = useDispatch();
  const isAuth = useIsAuth();

  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source') || 'null';
  const utmMedium = searchParams.get('utm_medium') || 'null';
  const utmCampaign = searchParams.get('utm_campaign') || 'null';
  const utmContent = searchParams.get('utm_content') || 'null';
  const utmTerm = searchParams.get('utm_term') || 'null';

  const [reqFormById, { data: dataForm }] = useLazyFormByIdQuery();
  const [reqPostOrder, { error, isLoading, isError }] = usePostFormByIdMutation();

  const getHtml = () => {
    if (error && 'status' in error && error.status === 'PARSING_ERROR' && 'data' in error && typeof error.data === 'string' && error.data.includes('<!DOCTYPE html>')) {
      return error.data
    }

    return null;
  }
  const html = getHtml();

  const params = new URLSearchParams(window.location.search);

  const paramsObj = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );

  useEffect(() => {
    if (html) {
      // @ts-ignore
      ym(96586181,'reachGoal','createneworder');
    }
  }, [html]);

  const getLink = () => {
    if (error && 'status' in error && error.status === 'PARSING_ERROR' && 'data' in error && typeof error.data === 'string' && error.data.includes('https://') && !error.data.includes('<!DOCTYPE html>')) {
      return error.data
    }
    
    return null;
  }
  const link = getLink();

  useEffect(() => {
    if (isError && link) {
      const elementA = document.createElement('a');
      elementA.setAttribute('href', link);
      elementA.setAttribute('target', '_blank');
      elementA.click();
    }
  }, [error, isError, link]);

  const [isShowAllProducts, setIsShowAllProducts] = useState(isAuth ? false : true);
  const [isCheckedPrivacyCheckbox, setIsCheckedPrivacyCheckbox] = useState(false);
  const [isCheckedOfertaCheckbox, setIsCheckedOfertaCheckbox] = useState(false);
  const [activePayMethodType, setActivePayMethodType] = useState<ActivePayMethodType>(null);
  const [isShowErrors, setIsShowErrors] = useState(false);

  const email = useSelector(getAuthorizationFormEmail);
  const phone = useSelector(getAuthorizationFormPhone);
  const fullName = useSelector(getAuthorizationFormFullName);

  const userData = useSelector(getUserAuthData);

  const currentPhone = `+7${userData?.phone}` || phone;
  const currentEmail = userData?.email || email;
  const currentFullName = userData?.full_name || fullName;

  const [validateErrors, setValidateErrors] = useState<ValidateErrors>({
    payMethod: 'Необходимо выбрать платежный метод',
    isCheckPrivacy: 'Необходимо выбрать платежный метод',
    isCheckOferta: 'Необходимо выбрать платежный метод',
  });

  useEffect(() => {
    if (dataForm) {
      const payMethodTypes = dataForm.result.payment_methods.map(({ info: { pay_method_type } }) => pay_method_type);
      const hasSbpTinkoff = payMethodTypes.includes('sbp_tinkoff');

      setActivePayMethodType(hasSbpTinkoff ? 'tinkoff' : payMethodTypes[0]);
      setValidateErrors((prev) => ({ ...prev, payMethod: '' }));
    }
  }, [dataForm]);

  useEffect(() => {
    if (formId) {
      reqFormById({ formId });
    }
  }, [formId, reqFormById]);

  useEffect(() => {
    if (isCheckedPrivacyCheckbox) {
      setValidateErrors((prev) => ({ ...prev, isCheckPrivacy: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, isCheckPrivacy: 'Необходимо выбрать платежный метод' }));
    }
  }, [isCheckedPrivacyCheckbox]);

  useEffect(() => {
    if (isCheckedOfertaCheckbox) {
      setValidateErrors((prev) => ({ ...prev, isCheckOferta: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, isCheckOferta: 'Необходимо выбрать платежный метод' }));
    }
  }, [isCheckedOfertaCheckbox]);

  if (!dataForm) {
    return null;
  }

  const {
    payment_methods,
  } = dataForm.result;

  const payMethodTypes = payment_methods.map(({ info: { pay_method_type } }) => pay_method_type);
  const hasSbpTinkoff = payMethodTypes.includes('sbp_tinkoff');
  const hasTinkoff = payMethodTypes.includes('tinkoff');

  const currentPayMethod = payment_methods.find(({ info: { pay_method_type } }) => pay_method_type === activePayMethodType);

  const urlPrivacy = currentPayMethod?.url_privacy || currentPayMethod?.info.url_privacy || '';
  const urlOferta = currentPayMethod?.url_oferta || currentPayMethod?.info.url_oferta || '';
  const currency = currentPayMethod?.info.currency || '';
  const paymentMethodId = currentPayMethod?.info.id || currentPayMethod?.id || '';

  const onClickShowAllProducts = () => {
    setIsShowAllProducts((prev) => !prev);
  };

  const onClickPayMethodType = (newType: PaymentRequestResultType['payment_methods'][0]['info']['pay_method_type']) => {
    setValidateErrors((prev) => ({ ...prev, payMethod: '' }));
    setActivePayMethodType(newType);
  }

  const onChangeFullName = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    dispatch(authorizationFormActions.setAuthorizationFormFullName(value));
  }

  const onChangeEmail = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    dispatch(authorizationFormActions.setAuthorizationFormEmail(value));
  }

  const onChangePhone = (e: string) => {
    dispatch(authorizationFormActions.setAuthorizationFormPhone(e));
  };

  const onChangePrivacyCheckbox = () => {
    if (!activePayMethodType) {
      setIsShowErrors(true);
      setValidateErrors((prev) => ({ ...prev, payMethod: 'Необходимо выбрать платежный метод' }));
      return;
    }

    setIsCheckedPrivacyCheckbox((prev) => !prev);
  }

  const onChangeOfertaCheckbox = () => {
    if (!activePayMethodType) {
      setIsShowErrors(true);
      setValidateErrors((prev) => ({ ...prev, payMethod: 'Необходимо выбрать платежный метод' }));
      return;
    }

    setIsCheckedOfertaCheckbox((prev) => !prev);
  }

  const {
    products,
  } = dataForm.result;

  const getTotalPrice = () => products.reduce((acc, { quantity, price, info: { price: infoPrice } }) => (
    acc + ((price || infoPrice) * quantity)
  ), 0);

  const countProducts = products.length;
  const totalPrice = getTotalPrice();

  const onClickPay = () => {
    setIsShowErrors(true);

    if (!Object.values(validateErrors).some((error) => error)) {
      const description = countProducts === 1 ? `Покупка 1 продукта` : `Покупка ${countProducts} продуктов`;

      reqPostOrder({
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_term: utmTerm,
        payment_method_id: Number(paymentMethodId),
        url: window.location.href,
        price: totalPrice,
        phone: getPhoneForServer(currentPhone),
        name: currentFullName,
        form_id: Number(formId as string),
        email: currentEmail,
        description,
        currency,
        data: paramsObj,
      });
    }
  }

  return (
    <VStack gap="32" className={s.form} max align="stretch">
      {/* Иконка и крстик */}
      <HStack gap="8" max justify="between">
        <Link to={RoutePath.main}>
          <img src={LogoImage} alt="*" />
        </Link>
        
        {/* <Link to={RoutePath.main}>
          <img className={s.cross} src={CrossImage} alt="*" />
        </Link> */}
      </HStack>

      {/* Заголовок */}
      <VStack gap="24" max>
        <VStack gap="16" max>
          <TextConstructor fz="24" lh="20" fw="500" color="#0E0E06">
            Оформление заказа
          </TextConstructor>
          <TextConstructor fz="14" lh="20" fw="400" color="#5B5B5B">
            {isAuth ? (
              <>
                Важно заполнить все данные верно
              </>
            ) : (
              <>
                <Link
                  className={s.link}
                  to={`${RoutePath.authorization}?redirect=/payment/${formId}&step=register`}
                >
                  → Зарегистрируйтесь
                </Link>
                , чтобы оформить заказ
              </>
            )}
          </TextConstructor>
        </VStack>

        {isAuth && (
          <>
            <HStack gap="12">
              {hasTinkoff && (
                <PaymentMethod
                  isActive={activePayMethodType === 'tinkoff'}
                  onClick={() => onClickPayMethodType('tinkoff')}
                  type="Tinkoff"
                />
              )}
              {hasSbpTinkoff && (
                <PaymentMethod
                  isActive={activePayMethodType === 'sbp_tinkoff'}
                  onClick={() => onClickPayMethodType('sbp_tinkoff')}
                  type="SBP"
                />
              )}
            </HStack>

            {isShowErrors && validateErrors.payMethod && (
              <TextConstructor color="#FF6161" fz="16" lh="24" fw="500">
                {validateErrors.payMethod}
              </TextConstructor>
            )}
          </>
        )}
      </VStack>

      {/* Поля */}
      {isAuth && (
        <VStack gap="20" max align="stretch">  
          <Input readOnly={isAuth} value={currentFullName} onChange={onChangeFullName} label="ФИО" />
          <Input readOnly={isAuth} value={currentEmail} onChange={onChangeEmail} label="E-mail" />
          <PhoneInput readOnly={isAuth} onChange={onChangePhone} value={currentPhone} />
        </VStack>
      )}

      {/* итого, выбрано, все продукты */}
      <VStack gap="16" max>
        <HStack justify="between" max>
          <TextConstructor color="#0E0E06" fw="500" fz="15" lh="20">
            Итого:
          </TextConstructor>
          <Price
            price={Number(totalPrice)}
          >
            {(currentPrice) => (
              <TextConstructor color="#0E0E06" fw="600" fz="28" lh="20">
                {currentPrice}
              </TextConstructor>
            )}
          </Price>
        </HStack>

        <div className={s.line} />

        <HStack justify="between" max>
          <HStack gap="8" max>
            <TextConstructor fz="20" color="#0E0E06" fw="600" lh="20">
              Выбрано:
            </TextConstructor>
            <TextConstructor fz="20" color="#5B5B5B" fw="600" lh="20">
              {`${countProducts} ${declOfNum(countProducts, ['продукт', 'продукта', 'продуктов'])}`}
            </TextConstructor>
          </HStack>

          <button className="resetButtonTagStyles" onClick={onClickShowAllProducts}>
            <TextConstructor fz="16" color="#0D8E74" fw="400" lh="20">
              {isShowAllProducts ? 'Скрыть продукты' : 'Показать продукты'}
            </TextConstructor>
          </button>
        </HStack>

        {isShowAllProducts && (
          <VStack gap="8" max>
            {products.map(({
              name, description, price, quantity, info: { name: infoName, description: infoDescription, price: infoPrice }, 
            }, i) => (
              <HStack gap="8" className={s.product} max align="start" key={i}>
                <HStack align="center" justify="center" className={s.number}>
                  <TextConstructor lh="20" fz="16" fw="500">
                    {quantity}
                  </TextConstructor>
                </HStack>

                <VStack max>
                  <TextConstructor fw="500" fz="16" lh="24" color="#242424">
                    {name || infoName}
                  </TextConstructor>
                  <TextConstructor fw="400" fz="14" lh="20" color="#5B5B5B">
                    {description || infoDescription}
                  </TextConstructor>

                  <HStack justify="end" max>
                    <Price price={price || infoPrice} compactDisplay='руб.'>
                      {(currentPrice) => (
                        <TextConstructor className={s.productPrice} color="#0E0E06" fw="500" fz="13" lh="20">
                          {currentPrice}
                        </TextConstructor>
                      )}
                    </Price>
                  </HStack>
                </VStack>
              </HStack>
            ))}
          </VStack>
        )}
      </VStack>

      {/* Чекбоксы */}
      {isAuth && (
        <VStack gap="8">
          <HStack gap="16">
            <Checkbox isChecked={isCheckedPrivacyCheckbox} onChange={onChangePrivacyCheckbox} isError={isShowErrors && !!validateErrors.isCheckPrivacy} />

            <HStack gap="4">
              <TextConstructor fz='14' lh='20' fw='400' color='#0E0E06'>
                Я согласен(-на) на обработку
              </TextConstructor>
              <a target="_blank" rel="noreferrer" className="resetATagStyles" {...urlPrivacy ? { href: urlPrivacy} : {}}>
                <TextConstructor fz='14' lh='20' fw='400' color='#5B5B5B'>
                  персональных данных
                </TextConstructor>
              </a>
            </HStack>
          </HStack>

          <HStack gap="16">
            <Checkbox isChecked={isCheckedOfertaCheckbox} onChange={onChangeOfertaCheckbox} isError={isShowErrors && !!validateErrors.isCheckOferta} />

            <HStack gap="4">
              <TextConstructor fz='14' lh='20' fw='400' color='#0E0E06'>
                Я ознакомлен(-на) с условиями
              </TextConstructor>
              <a target="_blank" rel="noreferrer" className="resetATagStyles" {...urlPrivacy ? { href: urlOferta} : {}}>
                <TextConstructor fz='14' lh='20' fw='400' color='#5B5B5B'>
                  лицензионного договора
                </TextConstructor>
              </a>
            </HStack>
          </HStack>
        </VStack>
      )}

      {/* Кнопка формы */}
      {isAuth ? (
        <Button
          typeButton="ButtonSquare"
          onClick={onClickPay}
          isLoading={isLoading}
        >
          Перейти к оплате
        </Button>
        ) : (
          <Button
          typeButton="LinkSquare"
          to={`${RoutePath.authorization}?redirect=/payment/${formId}&step=register`}
        >
          <Text name="Headline-Regular">
            Зарегистрируйтесь
          </Text>
        </Button>
      )}

      {/* Предупреждение */}
      <HStack gap="12">
        <img src={InfoCircleImage} alt="*" />
        <TextConstructor color="#5B5B5B" fz="14" lh="20" fw="500">
          Письмо с подтверждением оплаты придет в течение 48 часов на указанную электронную почту. Обязательно проверьте папку спам.
        </TextConstructor>
      </HStack>

      {/* <div dangerouslySetInnerHTML={{ __html: html }}></div> */}

      {html && (
        <iframe className={s.iframe} title="Tile" srcDoc={html} />
      )}
    </VStack>
  );
};
