import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { REFRESH_TOKEN_LOCALSTORAGE_KEY, TOKEN_LOCALSTORAGE_KEY } from "../../../shared/const/localstorage";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { LoginRequestQueryArg, LoginRequestResultType } from "../types/loginTypes";

const loginApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ApiResponseWrapper<LoginRequestResultType>, LoginRequestQueryArg>({
      invalidatesTags: ['User'],
      query: (body) => {
        return {
          url: 'user/auth/',
          method: 'POST',
          body,
        };
      },
      // @ts-ignore
      transformResponse: (response) => {
        // @ts-ignore
        localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, response?.result.tk || '');
        // @ts-ignore
        localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, response?.result.tk_refresh || '');

        return response;
      }
    }),
  }),
});

export const { useLoginMutation } = loginApi;
