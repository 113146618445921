import { ButtonWhyChooseUs } from "../ButtonWhyChooseUs/ButtonWhyChooseUs";
import { ImageWhyChooseUs } from "../ImageWhyChooseUs/ImageWhyChooseUs";
import { TextWhyChooseUs } from "../TextWhyChooseUs/TextWhyChooseUs";
import { TitleWhyChooseUs } from "../TitleWhyChooseUs/TitleWhyChooseUs";

export const DesktopWhyChooseUs = () => {
  return (
    <div style={{ height: '900px' }}>
      <TitleWhyChooseUs device="desktop" />
      <ImageWhyChooseUs device="desktop" />
      <TextWhyChooseUs device="desktop" />
      <ButtonWhyChooseUs />
    </div>
  );
};
