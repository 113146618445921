import { FC } from "react";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import { NewButton } from "../../../../shared/ui/NewButton";
import s from './MobileTextBlock.module.scss';
import Exclude from '../../../../shared/assets/images/Exclude.svg'
import classNames from "classnames";
import { toCourses } from "../../../../shared/config/routeConfig/routeConfig";

interface MobileTextBlockProps {
  className?: string;
}

export const MobileTextBlock: FC<MobileTextBlockProps> = (props) => {
  const {
    className,
  } = props;

  return (
    <VStack className={classNames(className, s.mobileTextBlock)} align="stretch">
      <TextConstructor color="#202020" fw="500" lh="52" fz="56" font="Akzidenz-Grotesk" className={s.blackText}>
        Учись
      </TextConstructor>
      <TextConstructor color="#202020" fw="500" lh="52" fz="56" font="Akzidenz-Grotesk" className={s.blackText}>
        на чужом
      </TextConstructor>
      <TextConstructor color="#74B500" fw="500" lh="52" fz="56" font="Akzidenz-Grotesk" className={s.text}>
        опыте
      </TextConstructor>

      <NewText name="Text" className={s.blackLittleText}>
        Начни применять знания сразу <br /> после прохождения курса
      </NewText>

      <div className={s.buttonWrapper}>
        <NewButton
          typeButton="LinkSquare"
          to={toCourses()}
          color="black"
          size="m"
          classNameButton={s.btn}
        >
          <NewText name="Bt" color="white" className={s.buttonText}>
            Выбрать курс
          </NewText>
        </NewButton>

        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgFirst)} />
        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgSecond)} />
      </div>
    </VStack>
  );
};