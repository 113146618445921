import React, { ReactNode } from 'react';
import classNames from 'classnames';
import s from './NewChip.module.scss';
import { NewText } from '../NewTypography/NewTypography';

interface NewChipProps {
  color?: 'black' | 'white',
  variant?: 'filled' | 'outline',
  className?: string,
  children: ReactNode,
}

export const NewChip: React.FC<NewChipProps> = ({
  color = 'black', variant = 'filled', className, children,
}) => {
  const getColor = () => {
    if (color === 'black' && variant === 'filled') {
      return 'white';
    }
    
    if (color === 'black' && variant === 'outline') {
      return 'black';
    }

    if (color === 'white' && variant === 'filled') {
      return 'black';
    }

    if (color === 'white' && variant === 'outline') {
      return 'white';
    }
  }

  return (
    <div
      className={classNames(
        s.newChip,
        s[variant],
        s[color],
        className,
      )}
    >
      <NewText name="tag" color={getColor()}>
        {children}
      </NewText>
    </div>
  );
};
