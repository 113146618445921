import { Header } from "antd/es/layout/layout";
import { Container } from "../../../shared/ui/Container";
import { VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { Certificate } from "../../../widgets/Certificate";
import s from './CertificatePage.module.scss';

export const CertificatePage = () => {
  return (
    <>
      <Header />
      <Container>
        <VStack max align="center" className={s.wrapper} gap="12">
          <TextConstructor>
            Перезагрузите страницу чтобы скачать сертификат снова
          </TextConstructor>
          <Certificate />
        </VStack>
      </Container>
    </>
  );
};
