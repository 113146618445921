import { useMediaQuery } from "../../../../shared/hooks";
import { DesktopBlockWithInformationAboutPlatform } from "../DesktopBlockWithInformationAboutPlatform/DesktopBlockWithInformationAboutPlatform";
import { MobileBlockWithInformationAboutPlatform } from "../MobileBlockWithInformationAboutPlatform/MobileBlockWithInformationAboutPlatform";
import { TabletBlockWithInformationAboutPlatform } from "../TabletBlockWithInformationAboutPlatform/TabletBlockWithInformationAboutPlatform";

export const BlockWithInformationAboutPlatform = () => {
  const showDesktop = useMediaQuery('(min-width: 1670px)');
  const showTablet = useMediaQuery('(min-width: 800px)');

  if (showDesktop) {
    return (
      <DesktopBlockWithInformationAboutPlatform />
    );
  }

  if (showTablet) {
    return (
      <TabletBlockWithInformationAboutPlatform />
    );
  }

  return (
    <MobileBlockWithInformationAboutPlatform />
  );
};
