import { Container } from "../../../shared/ui/Container";
import { ChangePassword } from "../../../widgets/ChangePassword";
import { PersonalInformation } from "../../../widgets/PersonalInformation";
import { Tabs } from '../../../shared/ui/Tabs/Tabs';
import s from './ProfilePage.module.scss';
import { useState } from "react";
import { HStack } from "../../../shared/ui/Stack";
import { Footer } from "../../../widgets/Footer";
import { Header } from "../../../widgets/Header";

const options = [
  { value: '1', label: 'Профиль' },
  { value: '2', label: 'Смена пароля' },
];

export const ProfilePage = () => {
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  return (
    <>
      <Header />
      <Container className={s.profilePage}>
        <Tabs onChange={setSelectedValue} options={options} selectedValue={selectedValue} className={s.tabs} />
        {selectedValue === '1' ? (
          <PersonalInformation />
        ): (
          <ChangePassword />
        )}
      </Container>
      <Footer/>
    </>
  );
};
