import { FC } from "react"
import { LessonItem } from "../LessonItem/LessonItem"
import classes from './LessonsList.module.scss'
import { LessonRequestResultType } from "../../../../../entities/Lesson/types/lessonTypes"

interface IProps {
  lessonInfo?: LessonRequestResultType[];
  currentLessonId: number;
}

export const LessonsList:FC<IProps> = ({lessonInfo, currentLessonId}):JSX.Element => {
  return (
    <div className={classes.root}>
      {lessonInfo?.map((el, idx) => {
        const isCompleted = el.user_complete;
        const isCurrent = currentLessonId === el.id;
        const isOpen = !!el.is_available;

        return (
          <LessonItem lesson={el} idx={idx} key={idx} isCompleted={isCompleted} isCurrent={isCurrent} isOpen={isOpen} />
        )
      })}
    </div>
  )
}
