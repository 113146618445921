import { NewText } from "../../../shared/ui/NewTypography/NewTypography";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TitleReviews } from "../../../shared/ui/TitleReview/TitleReviews";
import image from '../../../shared/assets/images/BG.jpg';
import { useMediaQuery } from "../../../shared/hooks";
import s from './ReviewsPageIntro.module.scss';

export const ReviewsPageIntro = () => {
  const showDesktop = useMediaQuery('(min-width: 1300px)');

  if (showDesktop) {
    return (
      <HStack justify="between">
        <VStack gap="32">
          <TitleReviews devices="desktop" />
          <NewText name="Title_2_Regular">
            Нам важно, чтобы вы приняли взвешенное решение об обучении на платформе. Поэтому мы собрали для вас честные мнения пользователей Цифрового поколения— так вы сможете оценить все плюсы и минусы и найдёте ответы на волнующие вопросы.
          </NewText>
        </VStack>
        
        <img src={image} alt="*" width={800} />
      </HStack>
    ); 
  }

  return (
    <VStack gap="70" align="center">
      <TitleReviews devices="tablet" />
      
      <img src={image} alt="*" className={s.image} />

      <NewText name="Title_2_Regular" textAlign="center">
          Нам важно, чтобы вы приняли взвешенное решение об обучении на платформе. Поэтому мы собрали для вас честные мнения пользователей Цифрового поколения— так вы сможете оценить все плюсы и минусы и найдёте ответы на волнующие вопросы.
        </NewText>
    </VStack>
  );
};
