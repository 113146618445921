import { Authorization } from "../../../widgets/Authorization";
import AuthImg from '../../../shared/assets/images/auth.png'
import backgroundAuthImg from '../../../shared/assets/images/backgroundAuth.png'
import logo from '../../../shared/assets/images/logo.png'
import s from './AuthorizationPage.module.scss';
import { HStack, VStack } from "../../../shared/ui/Stack";
import { RoutePath } from "../../../shared/config/routeConfig/routeConfig";
import { Link } from "react-router-dom";
import { useMediaQuery, useQuery } from "../../../shared/hooks";
import { useFormQuery } from "../../../widgets/Authorization/api/authorizationApi";

export const AuthorizationPage = () => {
  const query = useQuery();
  const formId = query.get('fid');
  const { data } = useFormQuery({ form_id: Number(formId) });
  const isMobile = useMediaQuery('(max-width: 1100px)');

  const title = data?.result.title || '';
  const description = data?.result.description || '';

  const Content = () => {
    if (data?.result.title && isMobile) {
      return (
        <VStack align="center">
          <div className={s.mobileForm}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <img src={AuthImg} className={s.littleImage} alt="*" />
          </div>

          <VStack gap="24" className={s.mobileForm}>
            <Link to={RoutePath.main}>
              <img src={logo} alt="*" />
            </Link>

            <Authorization className={s.formCustom} />
          </VStack>
        </VStack>
      );
    };

    if (data?.result.title && !isMobile) {
      return (
        <HStack className={s.form} align="stretch" justify="stretch">
          <VStack gap="24" className={s.p32}>
            <Link to={RoutePath.main}>
              <img src={logo} alt="*" />
            </Link>

            <Authorization className={s.formCustom} />
          </VStack>

          <div className={s.line} />
  
          <div className={s.html}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <img src={AuthImg} className={s.littleImage} alt="*" />
          </div>
        </HStack>
      );
    };

    return (
      <HStack>
        <VStack gap="24" align="center">
          <Link to={RoutePath.main}>
            <img src={logo} alt="*" />
          </Link>

          <Authorization />
        </VStack>

        <img src={AuthImg} className={s.hide1300} alt="*" />
      </HStack>
    );
  };

  return (
    <HStack gap="24" className={s.authorizationPage} align="center" justify="center">
      <img className={s.backgroundAuthImg} src={backgroundAuthImg} alt="*" />

      {Content()}
    </HStack>
  );
};
