import { rtkApiLMS } from "../../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../../shared/types/api";

const commentApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    publishComment: build.mutation<ApiResponseWrapper<any>, { rating: number, text: string }>({
      query: (body) => {
        return {
          url: `comments/platform`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { usePublishCommentMutation } = commentApi;
