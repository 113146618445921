import { FC } from "react";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { ProgressBar } from "../../../shared/ui/ProgressBar";
import s from './DetailedRating.module.scss';

interface DetailedRatingProps {
  averageRating: number,
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
}

export const DetailedRating: FC<DetailedRatingProps> = (props) => {
  const {
    averageRating,
    five,
    four,
    one,
    three,
    two,
  } = props;

  const ratings = [five, four, three, two, one];
  const totalCommets = ratings.reduce((acc, count) => acc + count, 0);

  return (
    <HStack gap="16" className={s.detailedRating} max>
      <VStack gap="8" className={s.averageRating} align="center">
        <TextConstructor color="#9F9FA0" fz="15" lh="20" fw="400" width="max-content">
          Средняя оценка
        </TextConstructor>

        <HStack>
          <TextConstructor color="#0E0E06" fz="24" lh="32" fw="600">
            {averageRating}
          </TextConstructor>
          <TextConstructor color="#9F9FA0" fz="24" lh="32" fw="600">
            /5
          </TextConstructor>
        </HStack>
      </VStack>

      <VStack gap="4" max>
        {ratings.map((count, index) => (
          <HStack gap="12" max key={index}>
            <TextConstructor color="#9F9FA0" lh="14" fz="12" fw="400">
              {5 - index}
            </TextConstructor>
            <ProgressBar color="yellow" progress={(count / totalCommets) * 100} />
            <TextConstructor color="#9F9FA0" lh="14" fz="12" fw="400" className={s.countRatings}>
              {ratings[index]}
            </TextConstructor>
          </HStack>
        ))}
      </VStack>
    </HStack>
  );
};
