import { rtkApiPayment } from "../../../shared/api/rtkApiPayment";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { PaymentRequestQueryArg, PaymentRequestResultType, PostPaymentRequestQueryArg, PostPaymentRequestResultType } from "../types/paymentTypes";

const paymentApi = rtkApiPayment.injectEndpoints({
  endpoints: (build) => ({
    formById: build.query<ApiResponseWrapper<PaymentRequestResultType>, PaymentRequestQueryArg>({
      query: (body) => {
        return {
          url: `forms/${body.formId}`,
          method: 'GET',
        };
      },
    }),
    postFormById: build.mutation<ApiResponseWrapper<PostPaymentRequestResultType>, PostPaymentRequestQueryArg>({
      query: (body) => {
        return {
          url: `orders/`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useFormByIdQuery, useLazyFormByIdQuery, usePostFormByIdMutation } = paymentApi;
