import { Link } from "react-router-dom"
import { Card } from "../../Card"
import classes from './FreeCourseCard.module.scss'
import { HStack, VStack } from "../../Stack"
import { ProgressBar } from "../../ProgressBar"
import { TextConstructor } from "../../TextConstructor"
import classNames from "classnames"
import starFillImage from '../../../assets/images/star-fill.svg'
import { FC } from "react"
import { Button } from "../../Button"
import { toCoursePage } from "../../../lib/links"

interface IProps {
  progress: number
  rating: any
  commentCount: any
  onClickRating: any
  courseId: any
  image: string
  name: string
  descr: string
  isProgressBar?: boolean;
  payLink: string;
  isAvailable: boolean;
}

export const FreeCourseCard:FC<IProps> = ({isProgressBar, progress, rating, commentCount, courseId, onClickRating, image, descr, name, isAvailable, payLink}):JSX.Element => {
  return (
    <Card className={classes.card}>
      <HStack className={classes.upWrapper}>
        <div className={classes.imgWrapper}>
          <img src={image} alt="course" className={classes.img}/>
        </div>
        <div>
          <div className={classes.title}>{name}</div>
          <div className={classes.descr}>{descr?.length > 100 ? descr?.slice(0, 100) + '...' : descr}</div>
        </div>
      </HStack>

      <VStack gap="12" max>
        {isProgressBar && (
          <ProgressBar
            progress={progress}
            leftNode={(
              <TextConstructor color="#9F9FA0" fz="15" lh="20" fw="400">
                Пройдено
              </TextConstructor>
            )}
            rightNode={(
              <TextConstructor color="#9F9FA0" fz="15" lh="20" fw="400">
                {progress}%
              </TextConstructor>
            )}
            />
            )}
        </VStack>

        <HStack max justify="stretch" gap="12">
            <Button typeButton="AnchorSquare" stretch href={payLink} target="_blank">
              Подробнее
            </Button>
            {isAvailable && (
              <Button typeButton="LinkSquare" stretch to={toCoursePage(String(courseId))}>
                Продолжить учиться
              </Button>
            )}
          </HStack>

      <button className={classNames('resetButtonTagStyles', classes.rating)} onClick={() => onClickRating(courseId)}>
          <HStack gap="24">
            <HStack gap="8">
              <img src={starFillImage} alt="*" />
              <TextConstructor color="#0E0E06" fz="20" lh="24" fw="500">
                {rating}
              </TextConstructor>
            </HStack>

            <TextConstructor>
              {commentCount} отзыв
            </TextConstructor>
          </HStack>
        </button>
    </Card>
  )
}
