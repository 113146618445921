import { FC, ReactNode } from 'react';
import s from './Container.module.scss';
import classNames from 'classnames';

interface ContainerProps {
  className?: string
  children: ReactNode,
}

export const Container: FC<ContainerProps> = ({ className, children }) => (
  <div className={classNames(s.container, className)}>
    {children}
  </div>
)