import { FC } from 'react';
import { LinkProps } from 'react-router-dom';
import { Wrapper } from '../Wrapper/Wrapper';
import classNames from 'classnames';
import s from './LinkWrapper.module.scss';

export interface LinkWrapperProps extends Omit<LinkProps, 'className'> {
  classNameWrapper?: string;
  stretch?: boolean;
}

export const LinkWrapper: FC<LinkWrapperProps> = ({ children, classNameWrapper, stretch, ...restProps }) => (
  <Wrapper
    tag="Link"
    classNameWrapper={classNames(classNameWrapper, stretch && s.stretch)}
    {...restProps}
  >
    {children}
  </Wrapper>
);
