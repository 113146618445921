import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { MyCoursesRequestQueryArg, MyCoursesRequestResultType } from "../types/myCourseTypes";

const myCourseApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    myCourses: build.query<ApiResponseWrapper<MyCoursesRequestResultType>, MyCoursesRequestQueryArg>({
      query: () => {
        return {
          url: `courses/purchase/`,
          method: 'GET',
          params: {
            page: 1,
            items_on_page: 20,
          }
        };
      },
    }),
  }),
});

export const { useMyCoursesQuery } = myCourseApi;
