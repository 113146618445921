import { useMediaQuery } from "../../../../shared/hooks";
import { DesktopWhyChooseUs } from "../DesktopWhyChooseUs/DesktopWhyChooseUs";
import { MobileWhyChooseUs } from "../MobileWhyChooseUs/MobileWhyChooseUs";
import { TabletWhyChooseUs } from "../TabletWhyChooseUs/TabletWhyChooseUs";
import s from './WhyChooseUs.module.scss';

export const WhyChooseUs = () => {
  const showDesktop = useMediaQuery('(min-width: 1550px)');
  const showTablet = useMediaQuery('(min-width: 960px)');

  const Content = () => {
    if (showDesktop) {
      return (
        <DesktopWhyChooseUs />
      );
    }
  
    if (showTablet) {
      return (
        <TabletWhyChooseUs />
      );
    }
  
    return (
      <MobileWhyChooseUs />
    );
  }

  return (
    <div className={s.whyChooseUs}>
      {Content()}
    </div>
  );
};
