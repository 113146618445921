import { useEffect, useState } from "react"

export const useIsHasScrollbar = (target: HTMLDivElement | null) => {
  const [isHasScrollbar, setIsHasScrollbar] = useState(false);

  useEffect(() => {
    if (!target) {
      return;
    }

    setIsHasScrollbar(target.scrollHeight > target.clientHeight);
  }, [target]);

  return isHasScrollbar;
}
