import { FC } from "react";
import { useMediaQuery } from "../../../../shared/hooks";
import { DesktopHeader } from "../DesktopHeader/DesktopHeader";
import { MobileHeader } from "../MobileHeader/MobileHeader";
import classNames from "classnames";
import s from './Header.module.scss';
import { NewContainer } from "../../../../shared/ui/NewContainer/NewContainer";

interface HeaderProps {
  className?: string;
}

export const Header: FC<HeaderProps> = ({ className }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <div className={classNames(className, s.header)}>
      <NewContainer>
        {isMobile ? (
          <MobileHeader />
        ) : (
          <DesktopHeader />
        )}
      </NewContainer>
    </div>
  );
};
