import { CourseBlockWithProgressBar } from "../../../widgets/CourseBlockWithProgressBar";
import { ModulesList } from "../../../widgets/ModulesList";
import { Container } from "../../../shared/ui/Container";
import s from './CoursePage.module.scss';
import { BackButton } from "../../../shared/ui/BackButton";
import { HStack } from "../../../shared/ui/Stack";
import { Banners } from "../../../widgets/Banners";
import { useMediaQuery } from "../../../shared/hooks";
import { Header } from "../../../widgets/Header";

export const CoursePage = () => {
  const match = useMediaQuery('(min-width: 1000px)');

  return (
    <>
      <Header />

      <div style={{ marginTop: 70 }}>
        <Container className={s.coursePage}>
          <HStack max gap="20">
            <div className={s.content}>
              <BackButton link="/my-study" text="Все курсы" />
              <CourseBlockWithProgressBar />
              <ModulesList />
            </div>

            {match && (
              <Banners place_name="course_1" direction="column" />
            )}
          </HStack>
        </Container>
      </div>
    </>
  );
};
