import picture from '../../../../../shared/assets/images/young-blonde-woman-blue-sweater 1 (3).jpg';
import { FC } from "react";
import figureOne from '../../../../../shared/assets/images/money png (11).png';
import { CourseSlideTemplate } from "../CourseSlideTemplate/CourseSlideTemplate";

interface SlideFourthProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SlideFourth: FC<SlideFourthProps> = ({ device }) => {
  return (
    <CourseSlideTemplate
      device={device}
      figureSrc={figureOne}
      href="https://info.digitalgeneration.ru/invest"
      picture={picture}
      subTitle="Курс инвестиций"
      title="После курса - сразу в бой. Уже вывела первые дивиденды!"
      backgroundColor="#FFD0D0"
      labelTitle="Ирина"
      labelSubtitle="Начинающий инвестор"
      labelBackgroundColor="#F02929"
    />
  )
};
