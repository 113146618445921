import { FC, ReactNode } from "react";
import { ModalLayout } from "../ModalLayout/ModalLayout";
import s from './PopUp.module.scss';
import { HStack, VStack } from "../../../Stack";
import CrossImage from '../../../../assets/images/cross2.svg';

export interface PopUpProps {
  children: ReactNode;
  onClose?: () => void;
};

export const PopUp: FC<PopUpProps> = (props) => {
  const {
    children,
    onClose,
  } = props;

  const isHasOnClose = !!onClose;

  const onClick = isHasOnClose ? onClose : () => {};

  return (
    <>
      <ModalLayout onClick={onClick} />
      <VStack gap="24" className={s.popUp}>
        {isHasOnClose && (
          <HStack justify="end" max className={s.popUpHeader}>
            <button className="resetButtonTagStyles" onClick={onClose}>
              <img alt="*" src={CrossImage} />
            </button>
          </HStack>
        )}
        <div className={s.popUpContent}>
          {children}
        </div>
      </VStack>
    </>
  );
};