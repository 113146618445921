import { FC } from "react"
import { LessonItem } from "../LessonItem/LessonItem"
import classes from './LessonsList.module.scss'
import { LessonRequestResultType } from "../../../../../entities/Lesson/types/lessonTypes"

interface IProps {
  lessonInfo?: LessonRequestResultType[]
}

export const LessonsList:FC<IProps> = ({lessonInfo}):JSX.Element => {
  return (
    <div className={classes.root}>
      {lessonInfo?.map((el, idx) => (
        <LessonItem lesson={el} idx={idx} key={idx}  />
      ))}
    </div>
  )
}
