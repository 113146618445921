import { FC } from 'react';
import s from './Checkbox.module.scss';
import classNames from 'classnames';

interface CheckboxProps {
  isChecked: boolean;
  onChange?: () => void;
  isError?: boolean;
}

export const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    isChecked,
    onChange,
    isError,
  } = props;

  return (
    <input type="checkbox" checked={isChecked} className={classNames(s.checkbox, isError && s.error)} onChange={onChange} />
  );
};
