import picture from '../../../../../shared/assets/images/young-blonde-woman-blue-sweater 1 (1).jpg';
import { FC } from "react";
import figureOne from '../../../../../shared/assets/images/money png (9).png';
import { CourseSlideTemplate } from "../CourseSlideTemplate/CourseSlideTemplate";

interface SlideSecondProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SlideSecond: FC<SlideSecondProps> = ({ device }) => {
  return (
    <CourseSlideTemplate
      device={device}
      figureSrc={figureOne}
      href="https://цифровоепоколение.рф/5"
      picture={picture}
      subTitle="Курс по брокеру"
      title="Портфель объектов вырос в разы. Как же я долго к этому шел!"
      backgroundColor="#DEFF5B"
      labelTitle="Виталий"
      labelSubtitle="Риелтор"
      labelBackgroundColor="#5D8E05"
    />
  )
};
