import { ButtonWhyChooseUs } from "../ButtonWhyChooseUs/ButtonWhyChooseUs";
import { ImageWhyChooseUs } from "../ImageWhyChooseUs/ImageWhyChooseUs";
import { TextWhyChooseUs } from "../TextWhyChooseUs/TextWhyChooseUs";
import { TitleWhyChooseUs } from "../TitleWhyChooseUs/TitleWhyChooseUs";

export const MobileWhyChooseUs = () => {
  return (
    <div>
      <TitleWhyChooseUs device="mobile" />
      <TextWhyChooseUs device="mobile" />
      <ImageWhyChooseUs device="mobile" />
      <ButtonWhyChooseUs />
    </div>
  );
};
