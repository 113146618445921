import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { ApiResponseWrapper } from "../../../shared/types/api";
import {
  SmsCodeRequestQueryArg,
  SmsCodeRequestResultType,
  VerifySmsCodeRequestQueryArg,
  VerifySmsCodeRequestResultType,
} from "../types/smsCodeTypes";

const smsCodeApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    sendSmsCode: build.mutation<ApiResponseWrapper<SmsCodeRequestResultType>, SmsCodeRequestQueryArg>({
      query: (body) => {
        return {
          url: 'verify/phone_send_code/',
          method: 'POST',
          params: {
            t: body.type,
          },
          body,
        };
      },
    }),
    verifySmsCode: build.mutation<ApiResponseWrapper<VerifySmsCodeRequestResultType>, VerifySmsCodeRequestQueryArg>({
      query: (body) => {
        return {
          url: 'verify/verify_phone_code/',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useSendSmsCodeMutation, useVerifySmsCodeMutation } = smsCodeApi;
