import { Container } from "../../../shared/ui/Container"
import classes from './AboutPage.module.scss'
import BannerIcon1 from '../../../shared/assets/images/about/banner_1.png'
import BannerIcon2 from '../../../shared/assets/images/about/banner_2 2.png'
import BannerIcon3 from '../../../shared/assets/images/about/banner_3.png'
import BannerIcon4 from '../../../shared/assets/images/about/banner_4.png'
import { Footer } from "../../../widgets/Footer"
import { Header } from "../../../widgets/Header"


export const AboutPage = () => {
  return (
    <>
      <Header />

      <Container className={classes.AboutPage}>
        <div className={classes.root}>
          <img src={BannerIcon1} alt="banner_1" className={classes.banner1}/>
          <img src={BannerIcon2} alt="banner_2" className={classes.banner2}/>
          <img src={BannerIcon3} alt="banner_3" className={classes.banner3}/>
          <img src={BannerIcon4} alt="banner_4" className={classes.banner4}/>
        </div>
      </Container>
      <Footer/>
    </>
  )
}
