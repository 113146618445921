import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { FormRequestResultType, FormRequestQueryArg, FormRedirectRequestResultType, FormRedirectRequestQueryArg } from "../types/authorizationTypes";

const authorizationApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    form: build.query<ApiResponseWrapper<FormRequestResultType>, FormRequestQueryArg>({
      providesTags: ['User'],
      query: (data) => {
        return {
          url: `reg_forms/${data.form_id}`,
          method: 'GET',
        };
      },
    }),
    formRedirect: build.query<ApiResponseWrapper<FormRedirectRequestResultType>, FormRedirectRequestQueryArg>({
      providesTags: ['User'],
      query: (data) => {
        return {
          url: `reg_forms/${data.form_id}/redirect_url/`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLazyFormQuery, useLazyFormRedirectQuery, useFormQuery } = authorizationApi;
