import picture from '../../../../../shared/assets/images/young-blonde-woman-blue-sweater 1.jpg';
import { FC } from "react";
import figureOne from '../../../../../shared/assets/images/money png (8).png';
import { CourseSlideTemplate } from "../CourseSlideTemplate/CourseSlideTemplate";

interface SlideOneProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SlideOne: FC<SlideOneProps> = ({ device }) => {
  return (
    <CourseSlideTemplate
      device={device}
      figureSrc={figureOne}
      href="https://digitalgeneration.ru/payment/4"
      picture={picture}
      subTitle="Курс по маркетингу лояльности"
      title="Портфель объектов вырос в разы. Как же я долго к этому шел!"
      backgroundColor="#FEE559"
      labelTitle="Мария"
      labelSubtitle="Директор по развитию"
      labelBackgroundColor="#F04B17"
    />
  )
};
