import { FC, useState } from "react";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import s from './CourseCard.module.scss';
import { NewChip } from "../../../../shared/ui/NewChip/NewChip";
import { useHover } from "../../../../shared/hooks/useHover/useHover";
import { Author, VisualElements } from "../../types/courseTypes";
import classNames from "classnames";
import { Modal } from "../../../../shared/ui/Modal";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import { NewButton } from "../../../../shared/ui/NewButton";
import { toCourse } from "../../../../shared/config/routeConfig/routeConfig";
import figure1 from '../../../../shared/assets/images/Vector.svg';
import figure2 from '../../../../shared/assets/images/Vector (1).svg';
import figure3 from '../../../../shared/assets/images/Vector (2).svg';
import figure4 from '../../../../shared/assets/images/Figure 2.svg';

interface CourseCardProps {
  id: string;
  name: string,
  description: string,
  buttonText: string,
  author?: Author;
  visualElements?: VisualElements;
  href: string;
  tags?: string[];
  isAvailable: boolean;
  backgroundColor?: string;
}

export const CourseCard: FC<CourseCardProps> = (props) => {
  const {
    id,
    name,
    buttonText,
    description,
    author,
    visualElements,
    href,
    tags,
    isAvailable,
    backgroundColor,
  } = props;

  const [hoverRef, isHovering] = useHover();
  const [isOpen, setIsOpen] = useState(false);

  const isOpenToggle = () => {
    setIsOpen((prev) => !prev);
  }

  const getFigure = () => {
    if (visualElements?.variantFigureBackground === 1) {
      return figure1;
    }

    if (visualElements?.variantFigureBackground === 2) {
      return figure2;
    }

    if (visualElements?.variantFigureBackground === 3) {
      return figure3;
    }

    if (visualElements?.variantFigureBackground === 4) {
      return figure4;
    }

    return '';
  }

  const CardContent = () => (
    <VStack className={s.courseCard} justify="between" style={{ backgroundColor }}>
      <VStack gap="30">
        <HStack gap="5">
          {tags?.map((tagText, i) => (
            i ? (
              <NewChip children={tagText} color={isHovering ? 'white' : 'black'} variant="outline" />
            ) : (
              <NewChip children={tagText} color={isHovering ? 'white' : 'black'} variant="filled" />
            )
          ))}
        </HStack>

        <VStack gap="10">
          <NewText
            name="H5"
            color={isHovering ? 'neonGreen' : 'black'}
          >
            {name}
          </NewText>

          <NewText
            name="Text"
            color={isHovering ? 'white' : 'grey1'}
          >
            {description}
          </NewText>
        </VStack>
      </VStack>

      <VStack gap="30">
        {author && (
          <HStack gap="15">
            <img className={s.authorPhoto} src={author.photo} />

            <VStack gap="5">
              <NewText
                name="H7"
                color={isHovering ? 'white' : 'black'}
              >
                {author.name}
              </NewText>

              <NewText name="Text" color="grey2">
                {author.description}
              </NewText>
            </VStack>
          </HStack>
        )}

        <NewText
          name="Bt"
          color={isHovering ? 'neonGreen' : 'greenMain'}
        >
          {buttonText}
        </NewText>
      </VStack>
    </VStack>
  );

  return (
    <>
      <div className={s.courseCardWrapper}>
        {isAvailable ? (
          <button
            className="resetButtonTagStyles"
            onClick={isOpenToggle}
            type="button"
            // @ts-ignore
            ref={hoverRef}
          >
            {CardContent()}
          </button>
        ) : (
          // @ts-ignore
          <a ref={hoverRef} href={href} target="_blank">
            {CardContent()}
          </a>
        )}

        {(!!visualElements?.variantFigureBackground && visualElements?.speakerPhoto) && (
          <img src={getFigure()} alt="*" className={classNames(s.figureBackground, !isHovering && s.hide)} />
        )}
        {visualElements?.speakerPhoto && (
          <img src={visualElements.speakerPhoto} alt="*" className={classNames(s.speakerPhoto, !isHovering && s.hide)} />
        )}
        {visualElements?.leftImage && (
          <img src={visualElements.leftImage} alt="*" className={classNames(s.leftImage, !isHovering && s.hide)} />
        )}
        {visualElements?.rightImage && (
          <img src={visualElements.rightImage} alt="*" className={classNames(s.rightImage, !isHovering && s.hide)} />
        )}
      </div>

      <Modal
        isShow={isOpen}
        type="newPopUp"
        onClose={isOpenToggle}
      >
        <VStack gap="30">
          <VStack gap="5">
            <TextConstructor font="Akzidenz-Grotesk" fw="500" fz="14" lh="18">
              Курс
            </TextConstructor>
            <NewText name="H5">
              {name}
            </NewText>
          </VStack>

          <HStack gap="10" justify="end">
            <NewButton
              href={href}
              typeButton="AnchorSquare"
              color="black"
              variant="outline"
            >
              Подробнее
            </NewButton>
            <NewButton
              to={toCourse(id)}
              typeButton="LinkSquare"
              color="black"
              variant="filled"
            >
              Перейти к обучению
            </NewButton>
          </HStack>
        </VStack>
      </Modal>
    </>
  );
};
