import { VStack } from "../../../../shared/ui/Stack";
import { TitleReviews } from "../../../../shared/ui/TitleReview/TitleReviews";
import { ButtonsReviews } from "../ButtonsReviews/ButtonsReviews";
import { ReviewBlocks } from "../ReviewBlocks/ReviewBlocks";

export const MobileReviews = () => {
  return (
    <VStack gap="30" max>
      <TitleReviews devices="mobile" />
      <ReviewBlocks device="mobile" />
      <VStack max align="stretch">
        <ButtonsReviews />
      </VStack>
    </VStack>
  );
};
