import { FC } from "react";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { VStack } from "../../../../shared/ui/Stack";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";

interface TextHaveQuestionsProps {
  device?: 'desktop' | 'tablet' | 'mobile';
  className?: string;
}

export const TextHaveQuestions: FC<TextHaveQuestionsProps> = ({ device, className }) => {
  if (device === 'mobile') {
    return (
      <VStack gap="20" className={className}>
        <TextConstructor font="Akzidenz-Grotesk" color="#FFFFFF" fw="700" fz="40" lh="42">
          Есть <br /> вопросы? 
        </TextConstructor>
        <TextConstructor color="#0053B5" fw="500" fz="16" lh="20">
          Качественная поддержка и консультация по любому вопросу
        </TextConstructor>
      </VStack>
    );
  };

  return (
    <VStack gap="25" className={className}>
      <NewText name="H2" color="white">
        Есть <br /> вопросы? 
      </NewText>
      <NewText name="Text" color="blue">
        Качественная поддержка и консультация {device === 'tablet' && ( <br /> )} по любому вопросу
      </NewText>
    </VStack>
  );
};
