import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { CoursesRequestQueryArg, CoursesRequestResultType } from "../types/couresTypes";

const ourCoursesApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    courses: build.query<ApiResponseWrapper<CoursesRequestResultType>, CoursesRequestQueryArg>({
      query: (body) => {
        return {
          url: 'courses/',
          method: 'GET',
          body,
          params: {
            page: 1,
            items_on_page: 20,
          }
        };
      },
    }),
  }),
});

export const { useCoursesQuery } = ourCoursesApi;
