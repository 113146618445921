import { Link } from 'react-router-dom'
import classes from './BackButton.module.scss'
import { FC } from 'react'
import ArrowIcon from '../../../../shared/assets/images/arrowBackBtn.svg'

interface IProps {
  text: string
  link: string
}

export const BackButton:FC<IProps> = ({link, text}):JSX.Element => {
  return (
    <Link to={link} className={classes.backBtn}>
      <div className={classes.btn}><img src={ArrowIcon} alt="arrow" /></div>
      {text}
      </Link>
  )
}

