// @ts-nocheck
import { useParams } from "react-router-dom";
import { useCouseByIdQuery } from "../../../entities/Course";

export const useNearbyLessons = ({ courseId }: { courseId: string }) => {
  const { lessonId } = useParams();
  const query = useCouseByIdQuery({ courseId });

  const lessons = query.data?.result.modules.reduce((acc, { lessons }) => [...acc, ...lessons], []);
  const currentLessonIndex  = lessons?.findIndex((el) => el?.id === Number(lessonId));
  const prevLesson = lessons?.[currentLessonIndex - 1] ? {
    isAvaible: lessons[currentLessonIndex - 1].is_available,
    id: lessons[currentLessonIndex - 1].id,
  } : null;

  const nextLesson = lessons?.[currentLessonIndex + 1] ? {
    isAvaible: lessons[currentLessonIndex + 1].is_available,
    id: lessons[currentLessonIndex + 1].id,
  } : null;

  return {
    ...query,
    data: [prevLesson, nextLesson],
  };
};
