import { configureStore } from '@reduxjs/toolkit'
import { rtkApiLMS } from '../../../../shared/api/rtkApiLMS'
import { rtkApiAuth } from '../../../../shared/api/rtkApiAuth'
import { userReducer } from '../../../../entities/User'
import { authorizationFormReducer } from '../../../../shared/model/authorizationForm/slices/authorizationForm'
import { rtkApiPayment } from '../../../../shared/api/rtkApiPayment'
import { testReducer } from '../../../../entities/Test'

export const store = configureStore({
  reducer: {
    [rtkApiLMS.reducerPath]: rtkApiLMS.reducer,
    [rtkApiAuth.reducerPath]: rtkApiAuth.reducer,
    [rtkApiPayment.reducerPath]: rtkApiPayment.reducer,
    user: userReducer,
    authorizationForm: authorizationFormReducer,
    test: testReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkApiLMS.middleware,
      rtkApiAuth.middleware,
      rtkApiPayment.middleware,
  ]),
})