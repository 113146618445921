import { useParams } from "react-router-dom"
import { useLessonBlocksQuery } from "../../../entities/Lesson"
import { LessonVideo } from "./components/LessonVideo/LessonVideo"
import React from "react"
import { BlockDataFile, BlockDataVideo } from "../../../entities/Lesson/types/lessonTypes"
import classes from './LessonList.module.scss'
import { Testing } from "./components/Testing/Testing"
import { Material } from "./components/Material/Material"
import { TextItem } from "./components/TextItem/TextItem"

enum BLOCK_TYPES {
  FILE = 'file',
  VIDEO = 'video',
  TEST = 'test',
  EMPTY = '',
}

export const LessonList = () => {
  const {lessonId} = useParams()
  const {data} = useLessonBlocksQuery({lessonId: lessonId || '1'})
  const lessonInfo = data?.result?.slice()?.sort((a, b) => a.weight - b.weight);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
      {lessonInfo?.map((el, idx) => {
        return (
          <React.Fragment key={idx}>
            {el?.block_type === BLOCK_TYPES.VIDEO && (
              <LessonVideo title={el?.name} data={el?.block_data as BlockDataVideo}/>
            )}
            {el?.block_type === BLOCK_TYPES.FILE && (
              <Material data={el?.block_data as BlockDataFile}/>
            )}
            {el?.block_type === BLOCK_TYPES.TEST && (
              <Testing id={el?.block_data?.id}/>
            )}
            {el?.block_type === BLOCK_TYPES.EMPTY && (
              <TextItem text={el?.description as string} />
            )}
          </React.Fragment>
        )
      })}
      </div>
    </div>
  )
}
