import { FC, useEffect, useState } from "react";
import { RegisterForm } from "../../../entities/RegisterForm";
import { useRegisterMutation } from "../api/registrationApi";
import { useDispatch, useSelector } from "react-redux";
import { authorizationFormActions } from "../../../shared/model/authorizationForm/slices/authorizationForm";
import { getAuthorizationFormEmail, getAuthorizationFormFullName, getAuthorizationFormPassword, getAuthorizationFormPhone } from "../../../shared/model/authorizationForm";
import { getPhoneForServer, isCorrectPhone } from "../../../shared/lib/phone";
import { useSearchParams } from "react-router-dom";

export interface RegistrationStepProps {
  onStepComplited: (obj: { isPhoneConfirmed: boolean }) => void;
  toLogin: () => void,
};

interface ValidateErrors {
  name: 'Это обязательное поле' | 'Неккоректное имя' | '',
  phone: 'Это обязательное поле' | 'Неккоректный номер телефона' | 'Телефон занят' | '',
  email: 'Это обязательное поле' | 'Неккоректная почта' | '',
  password: 'Это обязательное поле' | '',
  passwordAgain: 'Это обязательное поле' | 'Пароли не совпадают' | '',
  personalDadaCheckbox: 'Это обязательное поле' | '',
}

export const Registration: FC<RegistrationStepProps> = (props) => {
  const {
    onStepComplited,
    toLogin,
  } = props;

  const dispatch = useDispatch();

  const [passwordAgain, setPasswordAgain] = useState<string>('');
  const [isChecked, setIsChecked] = useState(false);
  const [isShowErrors, setIsShowErrors] = useState(false);
  const [validateErrors, setValidateErrors] = useState<ValidateErrors>({
    email: 'Это обязательное поле',
    name: 'Это обязательное поле',
    password: 'Это обязательное поле',
    passwordAgain: 'Это обязательное поле',
    phone:'Это обязательное поле',
    personalDadaCheckbox:'Это обязательное поле',
  });

  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source') || 'null';
  const utmMedium = searchParams.get('utm_medium') || 'null';
  const utmCampaign = searchParams.get('utm_campaign') || 'null';
  const utmContent = searchParams.get('utm_content') || 'null';
  const utmTerm = searchParams.get('utm_term') || 'null';

  const phone = useSelector(getAuthorizationFormPhone);
  const email = useSelector(getAuthorizationFormEmail);
  const fullName = useSelector(getAuthorizationFormFullName);
  const password = useSelector(getAuthorizationFormPassword)

  const [reqRegistration, { isLoading, isSuccess, data, isError, error }] = useRegisterMutation();

  const onClickEnter = () => {
    toLogin();
  }

  const onChangeNameHandler = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    dispatch(authorizationFormActions.setAuthorizationFormFullName(value));

    const re = /[0-9]/;
    
    if (re.test(value) || value.length < 2) {
      setValidateErrors((prev) => ({ ...prev, name: 'Неккоректное имя' }));
    } else if (value) {
      setValidateErrors((prev) => ({ ...prev, name: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, name: 'Это обязательное поле' }));
    };
  }

  const onChangeEmailHandler = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    const newValue = value.split(' ').join('');

    dispatch(authorizationFormActions.setAuthorizationFormEmail(newValue));

    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!re.test(newValue.toLocaleLowerCase())) {
      setValidateErrors((prev) => ({ ...prev, email: 'Неккоректная почта' }));
    } else if (newValue) {
      setValidateErrors((prev) => ({ ...prev, email: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, email: 'Это обязательное поле' }));
    };
  }
  
  const onChangePhoneNumber = (phoneNumber: string) => {
    dispatch(authorizationFormActions.setAuthorizationFormPhone(phoneNumber));

    if (!isCorrectPhone(phoneNumber)) {
      setValidateErrors((prev) => ({ ...prev, phone: 'Неккоректный номер телефона' }));
    } else if (phoneNumber) {
      setValidateErrors((prev) => ({ ...prev, phone: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, phone: 'Это обязательное поле' }));
    };
  }

  const onChangePasswordHandler = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    dispatch(authorizationFormActions.setAuthorizationFormPassword(value)); 

    if (value) {
      setValidateErrors((prev) => ({ ...prev, password: '' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, password: 'Это обязательное поле' }));
    };

    if (passwordAgain && value === passwordAgain) {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: '' }));
    } else if (passwordAgain && value !== passwordAgain) {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: 'Пароли не совпадают' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: 'Это обязательное поле' }));
    };
  }

  useEffect(() => {
    if (phone) {
      onChangePhoneNumber(phone);
    }

    if (email) {
      onChangeEmailHandler({ currentTarget: { value: email } } as React.FormEvent<HTMLInputElement>);
    }

    if (fullName) {
      onChangeNameHandler({ currentTarget: { value: fullName } } as React.FormEvent<HTMLInputElement>);
    }

    if (password) {
      onChangePasswordHandler({ currentTarget: { value: password } } as React.FormEvent<HTMLInputElement>);
    }
  }, []);

  const onChangePasswordAgainHandler = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setPasswordAgain(value);

    if (value && password === value) {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: '' }));
    } else if (value && password !== value) {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: 'Пароли не совпадают' }));
    } else {
      setValidateErrors((prev) => ({ ...prev, passwordAgain: 'Это обязательное поле' }));
    };
  }

  const onChangeCheckboxHandler = () => {
    setIsChecked((prev) => {
      if (!prev) {
        setValidateErrors((prev) => ({ ...prev, personalDadaCheckbox: '' }));
      } else {
        setValidateErrors((prev) => ({ ...prev, personalDadaCheckbox: 'Это обязательное поле' }));
      }
      
      return !prev;
    });
  };

  const onClickSendSMSHandler = () => {
    setIsShowErrors(true);

    if (!Object.values(validateErrors).some((error) => error)) {
      const newPhone = getPhoneForServer(phone);

      reqRegistration({
        email,
        full_name: fullName,
        password,
        phone: newPhone,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_term: utmTerm,
      })
    }
  }

  useEffect(() => {
    if (isError && error && 'data' in error && typeof error.data === 'object' && error.data && 'message' in error.data) {
      if (error?.data?.message === 'User with this phone exists') {
        setValidateErrors((prev) => ({ ...prev, phone: 'Телефон занят' }));
      }
    }
  }, [error, isError]);

  useEffect(() => {
    if (isSuccess) {
      const isPhoneConfirmed = !!data?.result.phone_confirmed;

      onStepComplited({ isPhoneConfirmed });
    }
  }, [data?.result.phone_confirmed, isSuccess, onStepComplited]);

  return (
    <RegisterForm
      email={email}
      phone={phone}
      name={fullName}
      password={password}
      passwordAgain={passwordAgain}
      isCheckPersonalDadaCheckbox={isChecked}
      onChangeEmailHandler={onChangeEmailHandler}
      onChangePhoneNumber={onChangePhoneNumber}
      onChangeNameHandler={onChangeNameHandler}
      onChangePasswordHandler={onChangePasswordHandler}
      onChangePasswordAgainHandler={onChangePasswordAgainHandler}
      onChangeCheckboxHandler={onChangeCheckboxHandler}
      onClickSendSMSHandler={onClickSendSMSHandler}
      onClickEnter={onClickEnter}
      errorEmail={validateErrors.email}
      errorPhone={validateErrors.phone}
      errorName={validateErrors.name}
      errorPassword={validateErrors.password}
      errorPasswordAgain={validateErrors.passwordAgain}
      errorPersonalDadaCheckbox={validateErrors.personalDadaCheckbox}
      isShowErrors={isShowErrors}
      isLoading={isLoading}
    />
  );
};
