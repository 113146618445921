import { FC } from "react";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { Link } from "react-router-dom";

interface NavItemProps {
  label: string;
  href?: string,
}

export const NavItem: FC<NavItemProps> = (props) => {
  const {
    label,
    href,
  } = props;

  const getNavItem = () => {
    return (
      <NewText name="Bt">
        {label}
      </NewText>
    );
  };

  return href ? (
    <Link to={href}>
      {getNavItem()}
    </Link>
  ) : (
    <button className="resetButtonTagStyles">
      {getNavItem()}
    </button>
  );
};
