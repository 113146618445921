import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import traced from '../../../../shared/assets/images/traced.png';
import s from './TabletStartTrainingForFree.module.scss';

export const TabletStartTrainingForFree = () => (
  <div className={s.tabletStartTrainingForFree}>
    <NewText name="H2" color="black">
      Начни обучение <br />
    </NewText>
    <NewText name="H2" color="greenMain">
      бесплатно
    </NewText>
    <img src={traced} alt="*" className={s.tabletStartTrainingForFreeImage} />
  </div>
);
