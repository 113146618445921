import { useMediaQuery } from '../../../../shared/hooks';
import { DesktopStartTrainingForFree } from '../DesktopStartTrainingForFree/DesktopStartTrainingForFree';
import { MobileStartTrainingForFree } from '../MobileStartTrainingForFree/MobileStartTrainingForFree';
import { TabletStartTrainingForFree } from '../TabletStartTrainingForFree/TabletStartTrainingForFree';

export const StartTrainingForFree = () => {
  const showDesktop = useMediaQuery('(min-width: 1690px)');
  const showTablet = useMediaQuery('(min-width: 960px)');

  if (showDesktop) {
    return (
      <DesktopStartTrainingForFree />
    );
  }

  if (showTablet) {
    return (
      <TabletStartTrainingForFree />
    );
  }

  return (
    <MobileStartTrainingForFree />
  );
};