import { useEffect } from 'react';
import { AppRouter } from './providers/router';
import './styles/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../entities/User/model/slices/userSlice';
import { useFetchProfileQuery } from '../entities/User/api/userApi';
import { getInitedUser } from '../entities/User/model/selectors/getInitedUser/getInitedUser';
import { jwtDecode } from "jwt-decode";
import { REFRESH_TOKEN_LOCALSTORAGE_KEY, TOKEN_LOCALSTORAGE_KEY } from '../shared/const/localstorage';
import { getNewToken } from '../shared/api/getNewToken/getNewToken';

function App() {
  const dispatch = useDispatch();
  const { data, isFetching, isSuccess } = useFetchProfileQuery();
  const isInited = useSelector(getInitedUser);

  useEffect(() => {
    if (data && !isFetching) {
      dispatch(userActions.setAuthData(data.result));
    }
  }, [data, dispatch, isFetching]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
    
      if (isSuccess && token) {
        const jwtData = jwtDecode(token);
        let productsCount;

        if (jwtData && 'p' in jwtData && Array.isArray(jwtData.p)) {
          productsCount = jwtData.p.length;
        }
        
        if (typeof productsCount !== 'undefined' && productsCount !== data.result.products) {
          const refreshResult = await getNewToken();

          if (refreshResult?.result) {
            localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, refreshResult?.result.tk);
            localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refreshResult?.result.tk_refresh);
          }
        }
      }
    })()
  }, [isSuccess]);

  useEffect(() => {
      dispatch(userActions.initAuthData());
  }, [dispatch]);

  return isInited ? (
    <AppRouter />
  ) : null;
}

export default App;
