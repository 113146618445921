import { ButtonWhyChooseUs } from "../ButtonWhyChooseUs/ButtonWhyChooseUs";
import { ImageWhyChooseUs } from "../ImageWhyChooseUs/ImageWhyChooseUs";
import { TextWhyChooseUs } from "../TextWhyChooseUs/TextWhyChooseUs";
import { TitleWhyChooseUs } from "../TitleWhyChooseUs/TitleWhyChooseUs";

export const TabletWhyChooseUs = () => {
  return (
    <div>
      <TitleWhyChooseUs device="tablet" />
      <ImageWhyChooseUs device="tablet" />
      <TextWhyChooseUs device="tablet" />
      <ButtonWhyChooseUs />
    </div>
  );
};
