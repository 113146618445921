import { FC } from "react";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import { Avatar } from "../../../../shared/ui/Avatar";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import s from './Comment.module.scss';
import { Rate } from "antd";
import { AvatarWithLetters } from "../../../../shared/ui/AvatarWithLetters/AvatarWithLetters";

interface CommentProps {
  fullName: string;
  commentText: string;
  rating: number;
} 

export const Comment: FC<CommentProps> = (props) => {
  const {
    commentText,
    fullName,
    rating,
  } = props;

  return (
    <VStack gap="8">
      <HStack gap="12">
        <AvatarWithLetters firstLetter={fullName.charAt(0)} secondLetter={fullName.split(' ')[1]?.charAt(0)} />

        <NewText name="Text_1_BoldExtended">
          {fullName}
        </NewText>
      </HStack>

      <NewText name="Headline_LightExtended" className={s.color}>
        {commentText}
      </NewText>

      <Rate disabled defaultValue={rating} />
    </VStack>
  );
};
