import { useMediaQuery } from '../../../shared/hooks'
import { Grid } from '../../../shared/ui/Grid'
import { reasons } from './WhyWeAre.const'
import classes from './WhyWeAre.module.scss'
import { ReasonItem } from './components/ReasonItem'

export const WhyWeAre = () => {
  const isMobile = useMediaQuery('(max-width: 1000px)');

  return (
    <div className={classes.root}>
        <div className={classes.title}>Почему мы?</div>
        <Grid colums={isMobile ? '1' : '2'} horizontalGap='32' verticalGap='32'>
            {reasons.map((el, i) => (
              <ReasonItem img={el.img} subtitle={el?.subtitle} title={el?.title} key={i} />
            ))}
        </Grid>
    </div>
  )
}
