import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import {
  RatingsCourseIdRequestQueryArg,
  RatingsCourseIdRequestResultType,
  CommentsCourseIdRequestQueryArg,
  CommentsCourseIdRequestResultType,
  AddCommentCourseIdRequestResultType,
  AddCommentCourseIdRequestQueryArg,
} from "../types/reviewTypes";

const reviewApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    ratingsByCourseId: build.query<ApiResponseWrapper<RatingsCourseIdRequestResultType>, RatingsCourseIdRequestQueryArg>({
      query: (body) => {
        return {
          url: `ratings/${body.course_id}`,
          method: 'GET',
        };
      },
    }),
    commentsByCourseId: build.query<ApiResponseWrapper<CommentsCourseIdRequestResultType>, CommentsCourseIdRequestQueryArg>({
      query: (body) => {
        return {
          url: `comments/${body.course_id}`,
          method: 'GET',
        };
      },
    }),
    addCommentByCourseId: build.mutation<ApiResponseWrapper<AddCommentCourseIdRequestResultType>, AddCommentCourseIdRequestQueryArg>({
      query: (body) => {
        return {
          url: `comments/`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useRatingsByCourseIdQuery,
  useLazyRatingsByCourseIdQuery,
  useCommentsByCourseIdQuery,
  useAddCommentByCourseIdMutation,
  useLazyCommentsByCourseIdQuery,
} = reviewApi;
