import { text } from './FAQ.const'
import classes from './FAQ.module.scss'
import { FC } from 'react'
import { Item } from './component/Item'
import CJIcon from '../../../shared/assets/images/FAQ.png'
import { useMediaQuery } from '../../../shared/hooks'

export const FAQ:FC = ():JSX.Element => {
  const isMobile = useMediaQuery('(max-width: 1400px)');

  return (
    <div className={classes.root}>
      {!isMobile && (
        <img src={CJIcon} alt="CJ"  className={classes.img}/>
      )}
      {text.map((el, idx) => (
        <Item el={el}  key={idx}/>
      ))}
    </div>
  )
}
