import { FC, useEffect, useState } from "react";
import { Modal, ModalProps } from "../../../shared/ui/Modal";
import { DetailedRating } from "../../../entities/Rating";
import { CommentWithRating } from "../../../entities/Comment";
import { useAddCommentByCourseIdMutation, useLazyCommentsByCourseIdQuery, useLazyRatingsByCourseIdQuery } from "../api/reviewApi";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { useLazyCouseByIdQuery } from "../../../entities/Course";
import { Button } from "../../../shared/ui/Button";
import { Rate } from "antd";
import { Avatar } from "../../../shared/ui/Avatar";
import { Input } from "../../../shared/ui/Input";
import s from './ReviewModal.module.scss';

type ReviewModalProps = Pick<ModalProps, 'isShow' | 'onClose'> & {
  name: string;
  courseId: string | null;
};

export const ReviewModal: FC<ReviewModalProps> = ({
  isShow, onClose, courseId, name,
}) => {
  const [reqRating, { data: dataRating }] = useLazyRatingsByCourseIdQuery();
  const [reqComments, { data: dataComments }] = useLazyCommentsByCourseIdQuery();
  const [reqCourse, { data: dataCourse }] = useLazyCouseByIdQuery();
  const [addReview, { isSuccess }] = useAddCommentByCourseIdMutation();

  const [step, setStep] = useState<'readReview' | 'addReview'>('readReview');
  const [reviewText, setReviewText] = useState('');
  const [rate, setRate] = useState(0);

  const onChangeInput = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setReviewText(value);
  };

  const onClick = () => {
    setStep('addReview');
  };

  const onChangeRate = (newRate: number) => {
    setRate(newRate);
  };

  const onClickSend = () => {
    addReview({ course_id: Number(courseId), rating: rate, text: reviewText });
  }

  useEffect(() => {
    if (isSuccess) {
      setStep('readReview');
      setRate(0);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (courseId && isShow) {
      reqRating({ course_id: courseId })
    }
  }, [courseId, isShow, reqRating]);

  useEffect(() => {
    if (courseId && isShow) {
      reqComments({ course_id: courseId })
    }
  }, [courseId, isShow, reqComments]);

  useEffect(() => {
    if (courseId && isShow) {
      reqCourse({ courseId })
    }
  }, [courseId, isShow, reqCourse]);

  if (!dataRating || !dataComments) {
    return null;
  }

  const averageRating = dataRating.result.average_rating;
  const ratings = Object.values(dataRating.result.every_rate);
  const isComplete = dataCourse?.result.percent_complete === 100;

  const ReadReviewComponent = () => (
    <VStack gap="24" align="stretch" max>
      <TextConstructor color="#0E0E06" fz="24" lh="32" fw="600">
        {name}
      </TextConstructor>

      {isComplete ? (
        <Button
          typeButton="ButtonSquare"
          onClick={onClick}
        >
          <TextConstructor color="#FFFFFF" fz="16" lh="24" fw="500">
            Оставить отзыв
          </TextConstructor>
        </Button>
      ) : (
        <TextConstructor color="#9F9FA0" fz="15" fw="400">
          Оставить отзыв можно после прохождения курса
        </TextConstructor>
      )}

      <DetailedRating averageRating={averageRating} one={ratings[0]} two={ratings[1]} three={ratings[2]} four={ratings[3]} five={ratings[4]} />

      {dataComments?.result.map(({ full_name, rating, date_create, text }, i) => (
        <CommentWithRating fullName={full_name} rating={rating} text={text} timeAgo={date_create} key={i} />
      ))}
    </VStack>
  );

  const AddReviewComponent = () => (
    <VStack gap="32" max align="stretch">
      <VStack gap="16" align="center" max>
        <HStack justify="center">
          <TextConstructor color="#1F1F1F" fz="16" lh="24" fw="600">
            Нажмите, чтобы оценить
          </TextConstructor>
        </HStack>

        <HStack justify="center">
          <Rate value={rate} onChange={onChangeRate} />
        </HStack>
      </VStack>

      <HStack gap="16" max align="stretch">
        <Avatar />

        <Input className={s.input} value={reviewText} onChange={onChangeInput} />
      </HStack>

      <Button
        typeButton="ButtonSquare"
        onClick={onClickSend}
      >
        Отправить отзыв
      </Button>
    </VStack>
  );

  return (
      <Modal
        type="popUp"
        isShow={isShow}
        onClose={onClose}
      >
        {step === 'readReview' ? (
          <>
            {ReadReviewComponent()}
          </>
        ) : (
          <>
            {AddReviewComponent()}
          </>
        )}
      </Modal>
  );
};
