import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import traced from '../../../../shared/assets/images/traced.png';
import s from './DesktopStartTrainingForFree.module.scss';

export const DesktopStartTrainingForFree = () => (
  <div className={s.desktopStartTrainingForFree}>
    <NewText name="H2" color="black">
      Начни обучение {' '}
    </NewText>
    <NewText name="H2" color="greenMain">
      бесплатно
    </NewText>
    <img src={traced} alt="*" className={s.desktopStartTrainingForFreeImage} />
  </div>
);