import { HStack } from "../../../../shared/ui/Stack";
import DGLogo from '../../../../shared/assets/images/logos/DGLogo.svg';
import { Navbar } from "../../../../features/Navbar";
import { NewButton } from "../../../../shared/ui/NewButton";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { useIsAuth } from "../../../../shared/hooks/useIsAuth";
import { toAuthorization, toMain, toProfile, toStudy } from "../../../../shared/config/routeConfig/routeConfig";
import person from '../../../../shared/assets/images/person.svg';
import { Link, useNavigate } from "react-router-dom";

export const DesktopHeader = () => {
  const isAuth = useIsAuth();
  const navigate = useNavigate();

  return (
    <>
      <header>
        <HStack justify="between" align="center">
          <Link to={toMain()}>
            <img src={DGLogo} alt="*" style={{ width: '192px', height: '38px' }} />
          </Link>

          <Navbar />

          {isAuth ? (
            <HStack gap="5">
              <NewButton
                typeButton="LinkSquare"
                color="black"
                to={toStudy()}
              >
                <NewText name="Bt" color="white">
                  Мое обучение
                </NewText>
              </NewButton>
              <NewButton
                typeButton="ButtonIcon"
                color="black"
                variant="filled"
                onClick={() => navigate(toProfile())}
              >
                <img src={person} alt="*" />
              </NewButton>
            </HStack>
          ) : (
            <NewButton
              typeButton="LinkSquare"
              color="black"
              to={toAuthorization()}
            >
              <NewText name="Bt" color="white">
                Войти
              </NewText>
            </NewButton>
          )}
        </HStack>
      </header>
    </>
  );
};
