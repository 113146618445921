import { useState } from "react";
import { Input } from "../../../shared/ui/Input";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { Button } from "../../../shared/ui/Button";
import { useChangePasswordMutation } from "../api/changePasswordApi";
import s from './ChangePassword.module.scss';

export const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const onChange = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setNewPassword(value);
  };

  const onClick = () => {
    changePassword({
      password: newPassword,
    });
  }

  return (
    <HStack max className={s.changePassword}>
      <VStack gap="32" max align="stretch">
        <TextConstructor color="#000000" fw="400" fz="24" lh="28">
          Новый пароль
        </TextConstructor>

        <Input value={newPassword} onChange={onChange} label="Новый пароль" />

        <Button
          typeButton="ButtonSquare"
          onClick={onClick}
          isLoading={isLoading}
        >
          Сохранить
        </Button>
      </VStack>
      <VStack max className={s.mobileHide}>
        <div />
      </VStack>
    </HStack>
  );
};
