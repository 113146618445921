import { FC, ReactNode } from "react";
import s from './Typography.module.scss';
import classNames from "classnames";

interface TypographyProps {
  name:
    'Chapter'
    | 'Heading_SemiBold'
    | 'Title1-SemiBold'
    | 'Title1-Medium'
    | 'Title1-Regular'
    | 'Title_2_Regular'
    | 'Title_2_Semibold'
    | 'Title_2_Medium'
    | 'Text-Title3-Regular'
    | 'Title_3-Medium'
    | 'Title_3-SemiBold'
    | 'Text-Title3-Bold'
    | 'Headline-Regular'
    | 'Text-Headline-Medium'
    | 'Text-Headline-SemiBold'
    | 'Text-Regular'
    | 'Text-Medium'
    | 'Text-SemiBold'
    | 'Subhead-Regular'
    | 'Subhead-Medium'
    | 'Subhead-SemiBold'
    | 'Caption1-Regular'
    | 'Caption1-Medium'
    | 'Caption1-SemiBoild'
    | 'Caption1-Caps_SemiBold'
    | 'Caption2-Regular'
    | 'Caption2-Medium'
    | 'Caption2-SemiBold'
    | 'Caption2-Caps_SemiBold';
  children: ReactNode;
  As?: 'span' | 'div';
  className?: string;
  color?:
    'iconeAccent'
    | 'ligthTextTextTitle'
    | 'textSecondary'
    | 'textAccent'
    | 'textNegative'
    | 'textWhiteFixed'
}

const Typography: FC<TypographyProps> = (props) => {
  const {
    children,
    name,
    As = 'span',
    color = 'ligthTextTextTitle',
  } = props;

  return (
    <As className={classNames(s[name], color)}>
      {children}
    </As>
  )
};

interface TextProps extends TypographyProps {};

export const Text: FC<TextProps> = (props) => {
  return (
    <Typography {...props} />
  )
}