import { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '../../../shared/ui/Button';
import { SmsCodeForm } from '../../../shared/ui/SmsCodeForm';
import { VStack } from '../../../shared/ui/Stack';
import { TextConstructor } from '../../../shared/ui/TextConstructor';
import s from './SmsCode.module.scss';
import { useSendSmsCodeMutation, useVerifySmsCodeMutation } from '../api/smsCodeApi';
import { useSelector } from 'react-redux';
import { getAuthorizationFormPhone } from '../../../shared/model/authorizationForm';
import { getPhoneForServer } from '../../../shared/lib/phone';

interface SmsCodeProps {
  onStepComplited: () => void;
}

export const SmsCode: FC<SmsCodeProps> = ({ onStepComplited }) => {
  const [smsCodeValue, setSmsCodeValue] = useState('');

  const phone = useSelector(getAuthorizationFormPhone);

  const [sendSmsCode] = useSendSmsCodeMutation();
  const [verifySmsCode, { isError, isSuccess }] = useVerifySmsCodeMutation();

  const onChangeCode = (value: string) => {
    setSmsCodeValue(value);
  };

  const sendCode = useCallback(() => {
    sendSmsCode({
      phone: getPhoneForServer(phone),
      type: 'sms',
    });
  }, [phone, sendSmsCode]);

  const verifyCodeFunc = () => {
    verifySmsCode({
      phone: getPhoneForServer(phone),
      code: Number(smsCodeValue),
    });
  }

  const onClickSendSMSCodeAgain = () => {
    sendCode();
  };

  const onClickConfirm = () => {
    verifyCodeFunc();
  };

  useEffect(() => {
    sendCode();
  }, [sendCode])

  useEffect(() => {
    if (isSuccess) {
      onStepComplited();
    }
  }, [isSuccess])

  return (
    <VStack gap='24' align='stretch'>
      <TextConstructor textAlign='center' fz='24' lh='20' color='#000000'>
        Введите номер из SMS
      </TextConstructor>
      <SmsCodeForm
        onChangeCode={onChangeCode}
        onClickSendSMSCodeAgain={onClickSendSMSCodeAgain}
        phone={phone}
        сodeValue={smsCodeValue}
        className={s.smsCodeForm}
        isError={isError}
      />
      <Button onClick={onClickConfirm} typeButton='ButtonSquare' stretch>
        Подтвердить
      </Button>
    </VStack>
  );
};
