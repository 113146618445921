import { Button } from "../../../shared/ui/Button";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor"
import errorImg from '../../../shared/assets/images/error.png'
import s from './ErrorPaymentPage.module.scss'

export const ErrorPaymentPage = () => {
  return (
    <VStack gap="24" align="center" className={s.errorPaymentPage}>
      <TextConstructor color="#0E0E06" fz="40" lh="52" textAlign="center" fw="600">
        Ошибка!
      </TextConstructor>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        К сожалению, ваш платёж отклонён
      </TextConstructor>
      <HStack max justify="center">
        <img src={errorImg} alt="*" className={s.img} />
      </HStack>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        Повторите попытку позже
      </TextConstructor>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        <div>
          <TextConstructor color="#5B5B5B" fz="16" lh="24" fw="400" textAlign="center">
            Если у вас не получилось самостоятельно решить проблему, свяжитесь с
          </TextConstructor>
          {' '}
          <a href="mailto:support@digitalgeneration.ru" className="resetATagStyles">
            <TextConstructor color="#0D8E74" fz="16" lh="24" fw="400" textAlign="center">
              support@digitalgeneration.ru
            </TextConstructor>
          </a>
        </div>
      </TextConstructor>
      <Button
        typeButton="AnchorSquare"
        href="https://digitalgeneration.ru/"
        classNameWrapper={s.btn}
        target="_blank"
      >
        Вернуться к курсам
      </Button>
    </VStack>
  );
};