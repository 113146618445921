import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import s from './MobileBlockWithInformationAboutPlatform.module.scss';
import cursor from '../../../../shared/assets/images/Cursor_perspective_matte.png';
import flash from '../../../../shared/assets/images/Flash png.png';
import comment from '../../../../shared/assets/images/comment png.png';
import rocket from '../../../../shared/assets/images/Group 153.png';
import { TextConstructor } from "../../../../shared/ui/TextConstructor";

export const MobileBlockWithInformationAboutPlatform = () => {
  return (
    <div className={s.mobileBlockWithInformationAboutPlatform}>
      <div className={s.first}>
        <TextConstructor color="#FFFFFF" font="Akzidenz-Grotesk" fw="500" fz="18" lh="22" className={s.text}>
          Опробуй <br /> функционал <br /> платформы
        </TextConstructor>
        <img src={cursor} alt="*" />
      </div>
      <div className={s.second}>
        <TextConstructor color="#FFFFFF" font="Akzidenz-Grotesk" fw="500" fz="18" lh="22" className={s.text}>
          Убедись в качестве <br /> и ценности знаний <br /> от экспертов
        </TextConstructor>
        <img src={flash} alt="*" />
      </div>
      <div className={s.third}>
        <TextConstructor color="#FFFFFF" font="Akzidenz-Grotesk" fw="500" fz="18" lh="22" className={s.text}>
          Изучай новое в <br /> комфортном <br /> для себя темпе
        </TextConstructor>
        <img src={comment} alt="*" />
      </div>
      <div className={s.fourth}>
        <TextConstructor color="#FFFFFF" font="Akzidenz-Grotesk" fw="500" fz="18" lh="22" className={s.text}>
          Начать!
        </TextConstructor>
        <img src={rocket} alt="*" />
      </div>
    </div>
  );
};
