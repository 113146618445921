import { FC } from "react";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import s from './TitleWhyChooseUs.module.scss';
import paint from '../../../../shared/assets/images/paint.svg';
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import classNames from "classnames";

interface TitleWhyChooseUsProps {
  device: 'desktop' | 'tablet' | 'mobile'
}

export const TitleWhyChooseUs: FC<TitleWhyChooseUsProps> = ({ device }) => {
  const isMobile = device === 'mobile';

  const Title = () => {
    const Text = () => (
      <>Почему<br /> выбирают<br /> именно<br /> нас?</>
    );

    if (isMobile) {
      return (
        <TextConstructor
          fw="700"
          fz="30"
          lh="32"
          font="Akzidenz-Grotesk"
        >
          {Text()}
        </TextConstructor>
      )
    }

    return (
      <NewText name="H2">
        {Text()}
      </NewText>
    );
  };

  return (
    <div className={classNames(s.titleWhyChooseUsWrapper, s[device])}>
      <div className={s.titleWhyChooseUs}>
        {Title()}

        <img src={paint} alt="*" className={classNames(s.titleWhyChooseUsImg, isMobile && s.titleWhyChooseUsImgMobile)} />
      </div>
    </div>
  );
};
