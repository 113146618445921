import { NewContainer } from "../../../shared/ui/NewContainer/NewContainer";
import { NewText } from "../../../shared/ui/NewTypography/NewTypography";
import { HStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { Comments } from "../../../widgets/Comments";
import { Footer } from "../../../widgets/Footer";
import { HaveQuestions } from "../../../widgets/HaveQuestions";
import { Header } from "../../../widgets/Header";
import { ReviewsBlocks } from "../../../widgets/ReviewsBlocks";
import { ReviewsPageIntro } from "../../../widgets/ReviewsPageIntro";
import s from './ReviewsPage.module.scss';

export const ReviewsPage = () => {
  return (
    <>
      <Header />

      <NewContainer className={s.reviewsPageIntroConteiner}>
        <ReviewsPageIntro />
      </NewContainer>

      <NewContainer className={s.reviewsBlocksConteiner}>
        <ReviewsBlocks />
      </NewContainer>

      <NewContainer className={s.titleContainer}>
        <HStack gap="10" align="start">
          <NewText name="H2">
            Отзывы
          </NewText>
          <TextConstructor
            fw="700"
            fz="30"
            lh="32"
            color="#74B500"
            font="Akzidenz-Grotesk"
          >
            2
          </TextConstructor>
        </HStack>
      </NewContainer>

      <NewContainer className={s.commentsConteiner}>
        <Comments />
      </NewContainer>

      <NewContainer className={s.haveQuestionsConteiner}>
        <HaveQuestions />
      </NewContainer>

      <Footer />
    </>
  );
};
