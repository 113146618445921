import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { EventsRequestResultType, EventsRequestQueryArg } from "../types/calendarTypes";

const calendarApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    events: build.query<ApiResponseWrapper<EventsRequestResultType>, EventsRequestQueryArg>({
      query: (body) => {
        return {
          url: 'events/',
          method: 'GET',
          params: {
            from_date_start: body.from_date_start,
            to_date_start: body.to_date_start,
          }
        };
      },
    }),
  }),
});

export const { useEventsQuery } = calendarApi;
