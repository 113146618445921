import classes from './Header.module.scss'

export const HeaderFAQ = () => {
  return (
    <div className={classes.upperBlock}>
    <div className={classes.textWrapper}>
      <div className={classes.title}>FAQ-раздел</div>
      <div className={classes.subtitle}>Эта страница поможет нам познакомиться <br/> поближе. Ниже приведены ответы на вопросы,<br/> касающиеся работы нашей платформы</div>
    </div>
  </div>
  )
}
