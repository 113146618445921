import { VStack } from "../../../shared/ui/Stack";
import { Payment } from "../../../widgets/Payment";
import s from './PaymentPage.module.scss';

export const PaymentPage = () => {
  return (
    <VStack max align="center" justify="center" className={s.paymentPage}>
      <Payment />
    </VStack>
  );
};
