import { FC, ReactNode } from "react";

interface PriceProps {
  children: (currentPrice: string) => ReactNode;
  price: number;
  compactDisplay?: 'руб.',
  maximumFractionDigits?: number, 
}

export const Price: FC<PriceProps> = (props) => {
  const {
    children,
    price,
    compactDisplay = 'руб.',
    maximumFractionDigits = 0,
  } = props;

  const formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits,
  });

  const formatPrice = formatter.format(price);

  const currentPrice = compactDisplay ? formatPrice.replace('₽', compactDisplay) : formatter.format(price); 

  return (
    <>
      {children(currentPrice)}
    </>
  );
};
