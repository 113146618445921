import classes from './Testing.module.scss'

import ClockIcon from '../../../../../shared/assets/images/clock.svg'
import { toTestPage } from '../../../../../shared/lib/links/toTestPage'
import { Link, useParams } from 'react-router-dom'
import { FC } from 'react'

interface IProps {
  id: number
}

export const Testing:FC<IProps> = ({id}):JSX.Element => {
  const {courseId, lessonId} = useParams()

  return (
    <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.text}>
            <p className={classes.title}>Тестирование</p>
            <p className={classes.time}><img src={ClockIcon} alt="clock" /> от 5-10 мин</p>
          </div>
          <Link to={toTestPage(String(courseId), String(lessonId), String(id))} className={classes.link} >{`Пройти тест  >`}</Link>
        </div>
    </div>
  )
}
