import { useMyCoursesQuery } from "../api/myCoursesListApi";
import { useMediaQuery } from "../../../shared/hooks";
import Loader from "../../../shared/ui/Loader/Loader";
import { Grid } from "../../../shared/ui/Grid";
import { VStack } from "../../../shared/ui/Stack";
import { CourseCardMine, CourseCardWithMarketingProps } from "../../../entities/Course";
import { useState } from "react";
import { ReviewModal } from "../../ReviewModal";

export const MyCoursesList = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [courseId, setCourseId] = useState<number | null>(null);
  const { data, isLoading } = useMyCoursesQuery();
  const match = useMediaQuery('(min-width: 1000px)');

  const onClickRating: CourseCardWithMarketingProps['onClickRating'] = (_courseId) => {
    setIsShowModal(true);
    setCourseId(_courseId);
  }

  const onClose = () => {
    setIsShowModal(false);
  }


  if (isLoading) {
    return (
      <Loader />
    );
  };

  if (!data) {
    return (
      <div />
    );
  };

  return (
    <VStack gap="32" justify="stretch" max>
      <Grid colums={match ? '2' : '1'} horizontalGap="20" verticalGap="32">
        {data.result.map(({
          image, hit_sales, description, pay_link, id, comment_count, rating,
          duration, name, percent_complete,
        }, i) => (
          <CourseCardMine
            key={i}
            image={image}
            duration={duration}
            isShowTagHitSales={hit_sales}
            courseName={name}
            description={description}
            progress={percent_complete}
            href={pay_link}
            courseId={id}
            onClickRating={onClickRating}
            rating={rating}
            commentCount={comment_count}
          />
        ))}
      </Grid>
      <ReviewModal
        isShow={isShowModal}
        onClose={onClose}
        courseId={String(courseId)}
        name={data?.result.find(({ id }) => id === courseId)?.name || ''}
      />
    </VStack>
  );
};
