import { useSelector } from "react-redux";
import { SmsCodeForm } from "../../../../shared/ui/SmsCodeForm";
import { getAuthorizationFormPhone } from "../../../../shared/model/authorizationForm";
import { FC, useCallback, useEffect, useState } from "react";
import { VStack } from "../../../../shared/ui/Stack";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import { Button } from "../../../../shared/ui/Button";
import s from './RestoreSmsCodeStep.module.scss';
import { getPhoneForServer } from "../../../../shared/lib/phone";
import { useSendSmsCodeMutation } from "../../../SmsCode/api/smsCodeApi";
import { useRestoreMutation } from "../../api/restoreApi";

interface RestoreSmsCodeStepProps {
  onStepCompleted: () => void;
}

export const RestoreSmsCodeStep: FC<RestoreSmsCodeStepProps> = ({ onStepCompleted }) => {
  const [smsCodeValue, setSmsCodeValue] = useState('');

  const phone = useSelector(getAuthorizationFormPhone);

  const [sendSmsCode] = useSendSmsCodeMutation();
  const [restore, { isSuccess, isError, data, error }] = useRestoreMutation();

  const onChangeCode = (value: string) => {
    setSmsCodeValue(value);
  };

  const sendCode = useCallback(() => {
    sendSmsCode({
      phone: getPhoneForServer(phone),
      type: 'sms',
    });
  }, [phone, sendSmsCode])

  const onClickSendCodeAgain = () => {
    sendCode();
  };

  const onClickConfirm = () => {
    restore({
      phone: getPhoneForServer(phone),
      code: smsCodeValue,
    })
  }

  useEffect(() => {
    sendCode();
  }, [phone, sendCode])

  useEffect(() => {
    if (isSuccess) {
      onStepCompleted()
    }
  }, [isSuccess, onStepCompleted])

  return (
    <VStack gap='24' align='stretch'>
      <TextConstructor textAlign='center' fz='24' lh='20' color='#000000'>
        Введите номер из SMS
      </TextConstructor>
      <SmsCodeForm
        onChangeCode={onChangeCode}
        onClickSendSMSCodeAgain={onClickSendCodeAgain}
        phone={phone}
        сodeValue={smsCodeValue}
        className={s.smsCodeForm}
        isError={isError}
      />
      <Button onClick={onClickConfirm} typeButton='ButtonSquare' stretch>
        Подтвердить
      </Button>
    </VStack>
  );
};
