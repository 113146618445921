import { FC, MouseEvent } from 'react'
import classes from './Tabs.module.scss'
import { ITabs } from './Tabs.typed'
import classNames from 'classnames'

export const Tabs: FC<ITabs> = ({
  options,
  selectedValue,
  onChange,
  className,
}): JSX.Element => {

  const handleOnClick = (value: string, e: MouseEvent<HTMLButtonElement>) => {
    if (onChange) onChange(value, e)
  }

  return (
    <div className={classNames(classes.root, className)}>
      {options.map((item) => (
        <button
          className={classNames(classes.tab, {
            [classes.active]: selectedValue === item.value
          })}
          key={item.value}
          onClick={(e) => handleOnClick(item.value, e)}
        >
          {item.label}
        </button>
      ))}
    </div>
  )
}
