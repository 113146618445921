import { FC, useState } from 'react'
import classes from './Item.module.scss'
import ArrowIcon from '../../../../shared/assets/images/Chevron.svg'

interface IProps {
  el: {
    question: string
    answer: string
  }
}

export const Item:FC<IProps> = ({el}):JSX.Element => {

  const [open, setOpen] = useState(false)

  return (
    <div className={classes.block}>
    <div className={classes.up}>
    <button className={classes.btn} onClick={() => setOpen(prev => !prev)}><img src={ArrowIcon} alt="arrow" className={classes.img}/></button>
      <div className={classes.question}>{el.question}</div>
    </div>
    {open && (
      <div className={classes.answer} dangerouslySetInnerHTML={{__html: el.answer}} />
    )}
  </div>
  )
}

