import { ComponentProps } from "react";
import { Comment } from "../../../entities/Comment/ui/Comment/Comment";
import { VStack } from "../../../shared/ui/Stack";

type CommentProps = ComponentProps<typeof Comment>;

export const Comments = () => {
  const comments: CommentProps[] = [
    {
      commentText: 'Почему Цифровое Поколение? Потому что у меня нет возможности тратить на учебу много времени и не работать. Именно поэтому мне нравится онлайн-формат обучения на платформе.',
      fullName: 'Мария',
      rating: 5,
    },
    {
      commentText: 'Пока я прошёл только первую часть курса «Инвестиции для всех». Но уже могу дать совет будущим участникам. Если вы хотите окончить курс быстрее, смотрите не меньше 2 видеоматериалов в день.',
      fullName: 'Филипп',
      rating: 5,
    },
  ];

  return (
    <VStack gap="56" style={{ maxWidth: 1150 }}>
      {comments.map(({ commentText, fullName, rating }) => (
        <Comment
          commentText={commentText}
          fullName={fullName}
          rating={rating}
        />
      ))}
    </VStack>
  );
};
