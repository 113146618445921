import { FC, ReactNode } from "react";
import { ModalLayout } from "../ModalLayout/ModalLayout";
import s from './NewPopUp.module.scss';
import { VStack } from "../../../Stack";
import CrossImage from '../../../../assets/images/icon-16.svg';
import classNames from "classnames";

export interface NewPopUpProps {
  children: ReactNode;
  onClose?: () => void;
};

export const NewPopUp: FC<NewPopUpProps> = (props) => {
  const {
    children,
    onClose,
  } = props;

  const isHasOnClose = !!onClose;

  const onClick = isHasOnClose ? onClose : () => {};

  return (
    <>
      <ModalLayout onClick={onClick} />
      <VStack gap="24" className={s.newPopUp}>
        {isHasOnClose && (
          <button className={classNames('resetButtonTagStyles', s.btn)} onClick={onClose}>
            <img alt="*" src={CrossImage} />
          </button>
        )}
        <div className={s.newPopUpContent}>
          {children}
        </div>
      </VStack>
    </>
  );
};