import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthorizationFormShema } from '../types/authorizationFormTypes';

const initialState: AuthorizationFormShema = {
    phone: '+7',
    email: '',
    fullName: '',
    password: '',
};

export const authorizationForm = createSlice({
    name: 'authorizationForm',
    initialState,
    reducers: {
        setAuthorizationFormPhone: (state, action: PayloadAction<AuthorizationFormShema['phone']>) => {
            state.phone = action.payload;
        },
        setAuthorizationFormEmail: (state, action: PayloadAction<AuthorizationFormShema['email']>) => {
            state.email = action.payload;
        },
        setAuthorizationFormFullName: (state, action: PayloadAction<AuthorizationFormShema['fullName']>) => {
            state.fullName = action.payload;
        },
        setAuthorizationFormPassword: (state, action: PayloadAction<AuthorizationFormShema['password']>) => {
            state.password = action.payload;
        },
    },
});

export const { actions: authorizationFormActions } = authorizationForm;
export const { reducer: authorizationFormReducer } = authorizationForm;
