import { Flex, FlexProps } from '../Flex/Flex';

type VStackProps = Omit<FlexProps, 'direction'> & {
  direction?: 'column' | 'column-reverse',
}

export const VStack = (props: VStackProps) => {
  const { align = 'start' } = props;

  return (
    <Flex {...props} direction={props.direction || 'column'} align={align} />
  );
};
