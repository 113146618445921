import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { BannersRequestQueryArg, BannersRequestResultType } from "../types/bannersApi";

const bannersApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    banners: build.query<ApiResponseWrapper<BannersRequestResultType>, BannersRequestQueryArg>({
      query: (body) => {
        return {
          url: `banners/`,
          method: 'GET',
          params: {
            place_name: body.place_name,
          }
        };
      },
    }),
  }),
});

export const { useBannersQuery } = bannersApi;
