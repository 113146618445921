import { useDispatch, useSelector } from "react-redux";
import { Input } from "../../../shared/ui/Input";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { getUserAuthData } from "../../../entities/User/model/selectors/getUserData/getUserData";
import { PhoneInput } from "../../../shared/ui/PhoneInput";
import { Button } from "../../../shared/ui/Button";
import { userActions } from "../../../entities/User";
import s from './PersonalInformation.module.scss';

export const PersonalInformation = () => {
  const dispatch = useDispatch();

  const userData = useSelector(getUserAuthData);

  const onClick = () => {
    dispatch(userActions.logout());
  }

  if (!userData) {
    return null;
  }

  return (
    <HStack max className={s.personalInformation}>
      <VStack gap="32" max align="stretch">
        <TextConstructor color="#000000" fw="400" fz="24" lh="28">
          Обо мне
        </TextConstructor>

        <VStack gap="20" max align="stretch">
          <Input value={userData?.full_name} readOnly label="ФИО" />
          <PhoneInput value={`+7${userData?.phone}`} />
          <Input value={userData?.email} readOnly label="E-mail" />
        </VStack>

        <Button
          typeButton="ButtonSquare"
          onClick={onClick}
          color="dark"
        >
          Выйти из аккаунта
        </Button>
      </VStack>
      <VStack max className={s.mobileHide}>
        <div />
      </VStack>
    </HStack>
  );
};
