// @ts-nocheck
import ReactPhoneNumberInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import { FC, useState } from 'react';
import s from './PhoneInput.module.scss';
import classNames from 'classnames';

interface PhoneInputProps {
  onChangeFormatPhoneNumber?: (phoneNumber: string) => void;
  onChangeFormatPhoneNumberIntl?: (phoneNumber: string) => void;
  className?: string;
  errorText?: string;
  value: string;
  onChange?: any,
  readOnly?: boolean,
}

export const PhoneInput: FC<PhoneInputProps> = (props) => {
  const {
    onChangeFormatPhoneNumber,
    onChangeFormatPhoneNumberIntl,
    className,
    errorText,
    value,
    onChange,
    readOnly,
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const onChangeHandler = (e) => {
    onChangeFormatPhoneNumber?.(formatPhoneNumber(e));
    onChangeFormatPhoneNumberIntl?.(formatPhoneNumberIntl(e));
    onChange(e)
  }
  const onFocus = () => {
    if (readOnly) {
      return;
    }

    setIsFocus(true);
  }

  return (
    <div>
      <ReactPhoneNumberInput
        defaultCountry="RU"
        countries={['RU']}
        placeholder="Ваш телефон"
        className={classNames(s.input, isFocus && s.focus, className, errorText && s.inputError)}
        value={value}
        onChange={onChangeHandler}
        onFocus={onFocus}
        onBlur={() => setIsFocus(false)}
        readOnly={readOnly}
      />
      {errorText && (
        <div className={s.errorText}>
          {errorText}
        </div>
      )}
    </div>
  )
}