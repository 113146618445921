import { FC } from "react";
import { useBannersQuery } from "../api/bannerApi"; 
import { BannersRequestQueryArg } from "../types/bannersApi";
import { Banner, BannerProps } from "./Banner/Banner";
import s from './Banners.module.scss';
import classNames from "classnames";

export interface BannersProps extends BannersRequestQueryArg {
  direction?: 'row' | 'column';
  className?: string;
};

export const Banners: FC<BannersProps> = ({ place_name, direction = 'row', className }) => {
  const { data = { result: [] } } = useBannersQuery({ place_name });

  if (!data.result.length) {
    return null;
  }

  return (
    <div className={classNames(s.banners, className)} style={{ flexDirection: direction }}>
      {data.result.map(({ link, title }, i) => {
        const getColor = (): BannerProps['color'] => {
          if ((i % 4) === 1) {
            return 'pink';
          }

          if ((i % 4) === 2) {
            return 'blue';
          }

          if ((i % 4) === 3) {
            return 'yellow';
          }

          return 'green';
        }

        return (
          <Banner key={i} link={link} name={title} color={getColor()} />
        )
      })}
    </div>
  );
};
