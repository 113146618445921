import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { LessonBlocksRequestQueryArg, LessonBlocksRequestResultType, LessonRequestQueryArg, LessonRequestResultType } from "../types/lessonTypes";

const lessonApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    lesson: build.query<ApiResponseWrapper<LessonRequestResultType>, LessonRequestQueryArg>({
      query: (body) => {
        return {
          url: `lessons/${body.lessonId}`,
          method: 'GET',
        };
      },
    }),
    lessonBlocks: build.query<ApiResponseWrapper<LessonBlocksRequestResultType[]>, LessonBlocksRequestQueryArg>({
      query: (body) => {
        return {
          url: `lessons/${body.lessonId}/blocks/`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useLessonBlocksQuery, useLessonQuery } = lessonApi;
