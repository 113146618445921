import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { UserRequestQueryArg, UserRequestResultType } from "../types/userTypes";

const userApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    fetchProfile: build.query<ApiResponseWrapper<UserRequestResultType>, UserRequestQueryArg>({
      query: (body) => {
        return {
          url: 'user/profile/',
          method: 'GET',
          body,
        };
      },
      providesTags: ['User'],
    }),
  }),
});

export const { useFetchProfileQuery } = userApi;
