import { FC } from "react";
import DesktopImage from '../../../../shared/assets/images/image.png';
import TabletImage from '../../../../shared/assets/images/image (1).png';
import MobileFirstImage from '../../../../shared/assets/images/image (2).png';
import MobileSecondImage from '../../../../shared/assets/images/image (3).png';
import s from './ImageWhyChooseUs.module.scss';

interface ImageWhyChooseUsProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const ImageWhyChooseUs: FC<ImageWhyChooseUsProps> = ({ device }) => {
  if (device === 'desktop') {
    return (
      <img src={DesktopImage} alt="*" className={s.desktopImg} />
    );
  }

  if (device === 'tablet') {
    return (
      <img src={TabletImage} alt="*" className={s.tabletImg} />
    );
  }

  if (device === 'mobile') {
    return (
      <>
        <img src={MobileFirstImage} alt="*" className={s.mobileFirstImage} />
        <img src={MobileSecondImage} alt="*" className={s.mobileSecondImage} />
      </>
    );
  }

  return null;
};
