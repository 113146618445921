import { FC, ReactNode } from "react";
import { HStack, VStack } from "../../Stack";
import s from './ProgressBar.module.scss';
import classNames from "classnames";

interface ProgressBarProps {
  leftNode?: ReactNode;
  rightNode?: ReactNode;
  color?: 'dark' | 'green' | 'yellow';
  progress: number;
}

export const ProgressBar: FC<ProgressBarProps> = (props) => {
  const {
    leftNode,
    rightNode,
    color = 'dark',
    progress,
  } = props;

  return (
    <VStack gap="4" max>
      {(leftNode || rightNode) && (
        <HStack justify="between" max>
          {leftNode && (
            <div className={s.leftNode}>{leftNode}</div>
          )}
          {rightNode && (
            <div className={s.rightNode}>{rightNode}</div>
          )}
        </HStack>
      )}

      <div className={s.progressBarWrapper}>
        <div className={classNames(s.progressBar, s[color])} style={{ width: `${progress}%` }}></div>
      </div>
    </VStack>
  );
};
