import { FC, ReactNode } from "react";
import { Portal } from "../../../Portal";
import s from './FullScreenModal.module.scss';
import { HStack, VStack } from "../../../Stack";
import IconCross from '../../../../assets/images/cross.svg';

export interface FullScreenModalProps {
  children: ReactNode;
  onClose: () => void;
}

export const FullScreenModal: FC<FullScreenModalProps> = (props) => {
  const {
    children,
    onClose,
  } = props;

  return (
    <Portal>
      <VStack gap="32" className={s.fullScreenModal}>
        <HStack justify="end" className={s.fullScreenModalHeader}>
          <button onClick={onClose} className="resetButtonTagStyles">
            <img src={IconCross} alt="*" />
          </button>
        </HStack>
        <div className={s.fullScreenModalContent}>
          {children}
        </div>
      </VStack>
    </Portal>
  );
};
