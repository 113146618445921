import { FC } from "react";
import s from './TextItem.module.scss';

interface TextItemProps {
  text: string;
}

export const TextItem: FC<TextItemProps> = ({ text }) => {
  return (
    <div className={s.textItem}>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
