import { FC } from "react";
import s from './ModalLayout.module.scss';

interface ModalLayoutProps {
  onClick: () => void;
}

export const ModalLayout: FC<ModalLayoutProps> = (props) => {
  const {
    onClick,
  } = props;

  return (
    <div className={s.modalLayout} onClick={onClick} role="button" />
  );
};
