import { FC } from "react";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import s from './ReviewBlocks.module.scss';
import classNames from "classnames";
import photo1 from '../../../../shared/assets/images/img.jpg';
import photo2 from '../../../../shared/assets/images/img (1).jpg';
import photo3 from '../../../../shared/assets/images/img (2).jpg';
import frame from '../../../../shared/assets/images/Frame 40.png';
import { TextConstructor } from "../../../../shared/ui/TextConstructor";

const blocks = [
  {
    image: photo1,
    name: 'Людмила',
    text: `Добрый день! Меня зовут Людмила, я риелтор. Всегда чувствовала, что работаю не на 100% и хотелось как-то увеличить вовлеченность и экспертизу в работе с недвижимостью.
    Прошла курс Никиты Маланьина по введению в брокерскую деятельность. Курс подходит как человеку, который хочет освоить профессию, так и специалисту с опытом. 
    Поняла, как могу увеличить поток клиентов, как их удерживать, разобралась в основных принципах работы брокера (многие даже не знала, к своему стыду), а самое главное, осознала, что быть брокером престижнее, чем риелтором и мне есть к чему стремиться! Отдельно хочу сказать спасибо команде, которая работала над видеопроизводством, очень красивая картинка!`,
  },
  {
    image: photo2,
    name: 'Ольга',
    text: `Мне нравится идея с бесплатными курсами, чтобы можно было опробовать функционал. На удивление, бесплатные курсы оказались не «воздухом», а действительно очень содержательными, с полезной информацией. После того, как прошла курсы по smm и pr, решила приступить к изучению инфлюенсмаркетинга.
    ОЧЕНЬ полезный курс, узнала для себя это направление, а точнее, как с помощью блогеров развивать свой бизнес.`,
  },
  {
    image: photo3,
    name: 'Владислав',
    text: `Здравствуйте! Меня зовут Владислав, я стартапер. Хочу высказаться по поводу курса «Маркетинг лояльности». Более полезного материала я не встречал на эту тему. В рамках моего бизнеса я разобрался с привлечением клиентов, но вопрос «как их удерживать?» - оставался актуальным. 
    В рамках курса узнал о том, как вообще формируется лояльность, какие есть методы по удержанию клиентов (привлечение также обсуждалось), разобрался в построении финансового планирования и бюджетирование программы лояльности.
    Огромное спасибо спикеру - Анастасии Валовой и всей команде Цифрового Поколения за возможность расти мне и моему бизнесу!`,
  },
];

interface ReviewBlocksProps {
  device: 'desktop' | 'tablet' | 'mobile';
  className?: string;
}

export const ReviewBlocks: FC<ReviewBlocksProps> = ({ device, className }) => {
  return (
    <HStack gap="20" align="start" className={className}>
      {(device === 'mobile' ? [blocks[0]] : blocks).map(({ name, image, text }) => (
        <div className={classNames(s.reviewBlock, device)}>
          <img src={image} alt="*" className={s.avatar} />

          <VStack gap="5" className={s.nameAndStars}>
            {device === 'mobile' ? (
              <TextConstructor font="Akzidenz-Grotesk" fw="500" fz="15" lh="20">
                {name}
              </TextConstructor>
            ) : (
              <NewText name="H5">
                {name}
              </NewText>
            )}

            <img src={frame} alt="*" />
          </VStack>

          <NewText name="Text" className={s.text}>
            {text}
          </NewText>
        </div>
      ))}
    </HStack>
  );
};
