import classNames from "classnames";
import { NewButton } from "../../../../shared/ui/NewButton";
import s from './ButtonHaveQuestions.module.scss';
import Exclude from '../../../../shared/assets/images/Exclude.svg'

export const ButtonHaveQuestions = () => {
  const onClick = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    window.widget.open();
  }

  return (
    <div className={s.buttonHaveQuestions}>
      <div className={s.buttonWrapper}>
        <NewButton
          typeButton="AnchorSquare"
          href="#"
          id="openBitrix24WidgetLink"
          onClick={onClick}
          color="blue"
        >
          Написать нам
        </NewButton>

        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgFirst)} />
        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgSecond)} />
      </div>
    </div>
  )
};
