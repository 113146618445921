import { CourseCard, CoursesList, mapCoursesWithAuthors } from "../../../entities/Course";
import { useCoursesQuery } from "../../../entities/Courses";

export const AllCourses = () => {
  const { data } = useCoursesQuery();

  const courses = [...(data?.result || [])]

  return (
    <CoursesList>
      {mapCoursesWithAuthors(courses).map(({
        // @ts-ignore
        id, name, description, pay_button_text, author, landing_link, isAvailable, hex_code, free,
      }) => (
        <CourseCard
          id={String(id)}
          name={name}
          description={description}
          buttonText={pay_button_text}
          author={author}
          href={landing_link}
          isAvailable={isAvailable}
          backgroundColor={hex_code || ''}
          tags={[(free ? 'бесплатно' : '-30%'), 'Хит']}
        />
      ))}
    </CoursesList>
  );
};
