import classNames from 'classnames';
import { NewButton } from '../../../../shared/ui/NewButton';
import Exclude from '../../../../shared/assets/images/Exclude.svg'
import s from './ButtonWhyChooseUs.module.scss';
import { toAbout } from '../../../../shared/config/routeConfig/routeConfig';

export const ButtonWhyChooseUs = () => {
  return (
    <div className={s.buttonWhyChooseUs}>
      <div className={s.buttonWrapper}>
        <NewButton
          typeButton="LinkSquare"
          color="greenMain"
          to={toAbout()}
        >
          Узнать больше о компании
        </NewButton>

        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgFirst)} />
        <img src={Exclude} alt="*" className={classNames(s.svg, s.svgSecond)} />
      </div>
    </div>
  )
}