import { HStack } from "../../../../shared/ui/Stack";
import { SliderBlock } from "../SliderBlock/SliderBlock";
import { TextBlock } from "../TextBlock/TextBlock";
import s from './DesktopIntroBlock.module.scss';

export const DesktopIntroBlock = () => {
  return (
    <HStack align="center">
      <TextBlock device="desktop" className={s.desktopIntroBlock} />
      <SliderBlock device="desktop" />
    </HStack>
  );
};
