import { useParams } from "react-router-dom";
import { useCouseByIdQuery } from "../../../entities/Course";
import { ProgressBar } from "../../../shared/ui/ProgressBar";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import s from './CourseBlockWithProgressBar.module.scss'
import { Button } from "../../../shared/ui/Button";
import { toCertificate } from "../../../shared/lib/links";
import { declOfNum } from "../../../shared/lib/numbers";
import { useMediaQuery } from "../../../shared/hooks";

export const CourseBlockWithProgressBar = () => {
  const { courseId } = useParams();
  const isMobile = useMediaQuery('(max-width: 700px)');
  const Component = isMobile ? VStack : HStack;

  const { data } = useCouseByIdQuery({ courseId: courseId || '' })

  if (!data) {
    return null;
  }

  const lessonsCount = data.result.modules.reduce((acc, { lessons }) => ([ ...acc, ...lessons ]), []).length;

  const {
    image,
    name,
    percent_complete,
    hex_code,
    lessons_complete,
    free
  } = data.result;

  const isComplete = percent_complete === 100;
  const isFree = free;

  return (
    <Component gap="32" style={{backgroundColor:  hex_code ? hex_code : '#E3FFDA'}} className={s.courseBlockWithProgressBar}>
      <HStack>
        <img className={s.img} src={image} alt="*" />
      </HStack>

      <VStack gap="16" max>
        <TextConstructor color="#5B5B5B" fw="400" fz="18" lh="24">
          Курс
        </TextConstructor>

        <TextConstructor color="#0E0E06" fw="600" fz="32" lh="32">
          {name}
        </TextConstructor>

        <ProgressBar
          progress={percent_complete}
          color="green"
          leftNode={(
            <TextConstructor color="#0D8E74" fz="14" lh="18" fw="400">
              Пройдено {lessons_complete} {declOfNum(lessons_complete, ['урок', 'урока', 'уроков'])}
            </TextConstructor>
          )}
          rightNode={(
            <TextConstructor color="#5B5B5B" fz="14" lh="18" fw="400">
              Осталось {lessonsCount - lessons_complete} {declOfNum(lessonsCount - lessons_complete, ['урок', 'урока', 'уроков'])}
            </TextConstructor>
          )}
        />
        
        {isComplete && !isFree && (
          <Button
            typeButton="LinkSquare"
            to={toCertificate(courseId as string)}
          >
            Скачать сертификат
          </Button>
        )}
      </VStack>
    </Component>
  );
};
