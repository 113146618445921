import { FC } from "react";
import { HStack } from "../Stack"
import { TextConstructor } from "../TextConstructor";
import s from './AvatarWithLetters.module.scss';

interface AvatarWithLettersProps {
  firstLetter: string;
  secondLetter?: string;
}

export const AvatarWithLetters: FC<AvatarWithLettersProps> = ({ firstLetter, secondLetter }) => {
  return (
    <HStack className={s.avatarWithLetters} align="center" justify="center">
      <TextConstructor fw="600" fz="15" lh="20" color="#FFFFFF">
        {firstLetter} {secondLetter && (secondLetter)}
      </TextConstructor>
    </HStack>
  )
}