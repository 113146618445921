import classNames from 'classnames';
import { HStack, VStack } from '../../../../shared/ui/Stack';
import { TextConstructor } from '../../../../shared/ui/TextConstructor';
import s from './Banner.module.scss';
import { FC } from 'react';
import bannerArrowImg from '../../../../shared/assets/images/bannerArrow.svg';

export interface BannerProps {
  name: string;
  link: string;
  color: 'green' | 'pink' | 'blue' | 'yellow';
}

export const Banner: FC<BannerProps> = (props) => {
  const {
    link,
    name,
    color,
  } = props;

  return (
    <a className={classNames(s.banner, 'resetATagStyles', s[color])} href={link} target="_blank" rel="noreferrer">
      <VStack className={s.wrapper} justify="between">
        <TextConstructor color="#FFFFFF" fz="18" lh="22" fw="600">
          {name}
        </TextConstructor>
        <HStack>
          <TextConstructor color="#FFAD30" fz="14" lh="20" fw="500" textAlign="center">
            Подробнее
          </TextConstructor>
          <img src={bannerArrowImg} alt="*" />
        </HStack>
      </VStack>
    </a>
  );
};
