import { CSSProperties, DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import s from './Flex.module.scss';
import classNames from 'classnames';

export type FlexJustify = 'start' | 'center' | 'end' | 'between' | 'stretch' | 'around';
export type FlexAlign = 'start' | 'center' | 'end' | 'stretch';
export type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
export type FlexGap = '4' | '5' | '8' | '10' | '15' | '12' | '16' | '20' | '24' | '25' | '30' | '32' | '40' | '50' | '56' | '70';

const justifyClasses: Record<FlexJustify, string> = {
  start: s.justifyStart,
  center: s.justifyCenter,
  end: s.justifyEnd,
  between: s.justifyBetween,
  around: s.justifyAround,
  stretch: s.justifyStretch,
};

const alignClasses: Record<FlexAlign, string> = {
  start: s.alignStart,
  center: s.alignCenter,
  end: s.alignEnd,
  stretch: s.alignStretch,
};

const directionClasses: Record<FlexDirection, string> = {
  row: s.directionRow,
  column: s.directionColumn,
  "row-reverse": s.directionRowReverse,
  "column-reverse": s.directionColumnReverse,
};

const gapClasses: Record<FlexGap, string> = {
  // Before redesign
  4: s.gap4,
  8: s.gap8,
  12: s.gap12,
  16: s.gap16,
  24: s.gap24,
  32: s.gap32,
  56: s.gap56,
  // Include before and after redesign
  10: s.gap10,
  20: s.gap20,
  // After redesign
  5: s.gap5,
  15: s.gap15,
  25: s.gap25,
  30: s.gap30,
  40: s.gap40,
  50: s.gap50,
  70: s.gap70,
};

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export interface FlexProps extends DivProps {
  className?: string;
  children: ReactNode;
  justify?: FlexJustify;
  align?: FlexAlign;
  direction: FlexDirection;
  gap?: FlexGap;
  max?: boolean;
  style?: CSSProperties;
}

export const Flex = (props: FlexProps) => {
  const {
    className,
    children,
    justify = 'start',
    align = 'center',
    direction = 'row',
    gap,
    max,
    style,
  } = props;

  const classes = [
    className,
    justifyClasses[justify],
    alignClasses[align],
    directionClasses[direction],
    gap && gapClasses[gap],
    max && s.max,
    s.flex,
  ];

  return (
    <div className={classNames(classes)} style={style}>
      {children}
    </div>
  );
};
