import { NewText } from '../../../../shared/ui/NewTypography/NewTypography';
import { VStack } from '../../../../shared/ui/Stack';
import gift from '../../../../shared/assets/images/gift png.png';
import s from './CardSoonMore.module.scss';

export const CardSoonMore = () => {
  return (
    <VStack className={s.cardSoonMore} gap="20">
      <NewText name="H3" color="greenMain" className={s.text} textTransform="uppercase">
        Скоро <br /> больше <br /> курсов
      </NewText>
      <NewText name="Text" color="greenMain">
        Следите за нашими <br /> обновлениями
      </NewText>
      <img src={gift} alt="*" className={s.image} />
    </VStack>
  );
};
