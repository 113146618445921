import { rtkApiLMS } from "../../../shared/api/rtkApiLMS";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { CouseByIdRequestQueryArg, CouseByIdRequestResultType } from "../types/courseTypes";

const courseApi = rtkApiLMS.injectEndpoints({
  endpoints: (build) => ({
    couseById: build.query<ApiResponseWrapper<CouseByIdRequestResultType>, CouseByIdRequestQueryArg>({
      query: (body) => {
        return {
          url: `courses/${body.courseId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useCouseByIdQuery, useLazyCouseByIdQuery } = courseApi;
