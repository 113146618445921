import { FC, useState } from 'react'
import { HStack, VStack } from '../../../../../shared/ui/Stack'
import classes from './ModuleItem.module.scss'
import { TextConstructor } from '../../../../../shared/ui/TextConstructor'
import ArrowDownIcon from '../../../../../shared/assets/images/arrowDown.svg'
import { LessonsList } from '../LessonsList/LessonsList'
import { ModuleByIdRequestResultType } from '../../../../../entities/Courses/types/couresTypes'

interface IProps {
  moduleInfo: ModuleByIdRequestResultType
  idx: number
}

export const ModuleItem:FC<IProps> = ({moduleInfo, idx}):JSX.Element => {
  const [openLesson, setOpenLesson] = useState(false)
  return (
    <div className={classes.root} onClick={() => setOpenLesson(prev => !prev)}>
      <HStack align='start' gap="16" >
        <HStack className={classes.imgWrapper}>
          <img className={classes.img} src={moduleInfo.image} alt="*" />
        </HStack>
        <VStack gap='8' max>
          <TextConstructor color="#5B5B5B" fw="400" fz="14" lh="18">
              Модуль {idx + 1}
          </TextConstructor>
          <TextConstructor color="#0E0E06" fw="600" fz="16" lh="24">
              {moduleInfo.name}
          </TextConstructor>

          
        <div className={classes.arrow}>
          <img style={{transform: openLesson ? 'rotate(180deg)' : 'none'}} className={classes.img} src={ArrowDownIcon} alt="arrow"  />
        </div>
        </VStack>
      </HStack>
      {openLesson && (
        <LessonsList lessonInfo={moduleInfo.lessons} />
      )}
    </div>
  )
}
