import { NewButton } from "../../../../shared/ui/NewButton";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { HStack } from "../../../../shared/ui/Stack";
import DGLogo from '../../../../shared/assets/images/logos/DGLogo.svg';
import List from '../../../../shared/assets/images/List.svg';
import SignIn from '../../../../shared/assets/images/SignIn.svg';
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { useState } from "react";
import { useMediaQuery } from "../../../../shared/hooks";
import { toAuthorization, toMain, toStudy } from "../../../../shared/config/routeConfig/routeConfig";
import { useIsAuth } from "../../../../shared/hooks/useIsAuth";
import { Link } from "react-router-dom";

export const MobileHeader = () => {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const isShowIcon = useMediaQuery('(max-width: 700px)');
  const isAuth = useIsAuth();

  const onClick = () => {
    setShowBurgerMenu((prev) => !prev)
  };

  return (
    <>
      <header>
        <HStack justify="between" align="center">
          <Link to={toMain()}>
            <img src={DGLogo} alt="*" style={{ width: '192px', height: '38px' }} />
          </Link>

          <HStack gap="30">
            {isAuth ? (
              <>
                <NewButton
                    typeButton="LinkSquare"
                    color="black"
                    to={toStudy()}
                  >
                    <NewText name="Bt" color="white">
                      Мое обучение
                    </NewText>
                  </NewButton>
              </>
            ) : (
              <>
                {isShowIcon ? (
                  <NewButton
                    typeButton="ButtonIcon"
                    color="black"
                    variant="filled"
                  >
                    <img src={SignIn} alt="*" />
                  </NewButton>
                ) : (
                  <NewButton
                    typeButton="LinkSquare"
                    color="black"
                    to={toAuthorization()}
                  >
                    <NewText name="Bt" color="white">
                      Войти
                    </NewText>
                  </NewButton>
                )}
              </>
            )}

            <NewButton
              typeButton="ButtonIcon"
              color="black"
              onClick={onClick}
            >
              <img src={List} alt="*" />
            </NewButton>
          </HStack>
        </HStack>
      </header>
      {showBurgerMenu && (
        <BurgerMenu />
      )}
    </>
  );
};
