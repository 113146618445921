import { HStack, VStack } from "../../../../shared/ui/Stack";
import { SliderBlock } from "../SliderBlock/SliderBlock";
import { TextBlock } from "../TextBlock/TextBlock";

export const MobileIntroBlock = () => {
  return (
    <VStack>
      <TextBlock device="mobile" />
      <SliderBlock device="mobile" />
    </VStack>
  );
};
