import { FC, useState } from "react";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { PhoneInput } from "../../../shared/ui/PhoneInput";
import { Input } from "../../../shared/ui/Input";
import { Button } from "../../../shared/ui/Button";
import IconCloseEye from '../../../shared/assets/images/closeEye.svg'

export interface LoginFormProps {
  isLoading: boolean;
  // Поля
  phone: string;
  password: string;
  // Коллбеки
  onChangePhone: (phoneNumber: string) => void;
  onChangePassword: (e: React.FormEvent<HTMLInputElement>) => void;
  goToRegistrationStep: () => void;
  goToRestoreStep: () => void;
  onEnter: () => void;
  // Ошибки
  errorPhone: string;
  errorPassword: string;
}

export const LoginForm: FC<LoginFormProps> = (props) => {
  const {
  isLoading,
  phone,
  password,
  onChangePhone,
  onChangePassword,
  errorPhone,
  errorPassword,
  goToRegistrationStep,
  goToRestoreStep,
  onEnter,
  } = props;

  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const onClickIconHandler = () => {
    setInputType((prev) => prev === 'password' ? 'text' : 'password');
  }

  const Icon = () => (
    <button className="resetButtonTagStyles" onClick={onClickIconHandler}>
      <img src={IconCloseEye} alt="*" />
    </button>
  );

  return (
    <VStack gap="24" align="stretch">
      <HStack justify="between">
        <TextConstructor fz="24" lh="20" color="#000000">
          Войти
        </TextConstructor>
        <button className="resetButtonTagStyles" onClick={goToRegistrationStep}>
          <TextConstructor fz="14" lh="20" color="#0D8E74">
            Регистрация
          </TextConstructor>
        </button>
      </HStack>
      <PhoneInput errorText={errorPhone} value={phone} onChange={onChangePhone} />
      <Input value={password} errorText={errorPassword} onChange={onChangePassword} rightNode={Icon()} label="Пароль" type={inputType} />
      <button className="resetButtonTagStyles" onClick={goToRestoreStep}>
        <TextConstructor color="#0D8E74" fz="14" lh="20">
          Восстановить пароль
        </TextConstructor>
      </button>
      <Button typeButton="ButtonSquare" onClick={onEnter} isLoading={isLoading}>
        Войти
      </Button>
    </VStack>
  );
};
