import { HStack, VStack } from "../../../../shared/ui/Stack";
import { TitleReviews } from "../../../../shared/ui/TitleReview/TitleReviews";
import { ButtonsReviews } from "../ButtonsReviews/ButtonsReviews";
import { ReviewBlocks } from "../ReviewBlocks/ReviewBlocks";
import s from './DesktopReviews.module.scss';

export const DesktopReviews = () => {
  return (
    <HStack className={s.desktopReviews}>
      <VStack gap="40">
        <TitleReviews devices="desktop" />
        <ButtonsReviews />
      </VStack>
      <ReviewBlocks device="desktop" className={s.reviewBlocks} />
    </HStack>
  );
};
