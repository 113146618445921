import { FC, ReactNode } from 'react';
import s from './NewContainer.module.scss';
import classNames from 'classnames';

interface NewContainerProps {
  className?: string;
  children: ReactNode;
}

export const NewContainer: FC<NewContainerProps> = (props) => {
  const {
    children,
    className,
  } = props;

  return (
    <div className={classNames(className, s.newContainer)}>
      {children}
    </div>
  );
};
