import classNames from "classnames";
import { FC, ReactNode } from "react";
import s from './TextConstructor.module.scss';

interface TextConstructorProps {
  children: ReactNode,
  textAlign?: 'left' | 'center' | 'right';
  color?: '#000000' | '#202020' | '#464646' | '#0E0E06' | '#9F9FA0' | '#0053B5' | '#E4E4E4' | '#242424' | '#0D8E74' | '#FF6161' | '#FFFFFF' | '#34C759' | '#5B5B5B' | '#C3C3C3' | "#1F1F1F" | "#FFAD30" | "#74B500";
  fz?: '12' | '13' | '14' | '15' | '16' | '18' | '20' | '24' | '26' | '28' | '30' | '32' | '36' | '40' | '56' | '64' | '96' | '100' | '180';
  lh?: '14' | '18' | '20' | '22' | '24' | '25' | '28' | '32' | '40' | '42' | '44' | '52' | '53' | '60' | '106' | '122' | '150' | '230';
  fw?: '400' | '500' | '600' | '700' | '800';
  textDecoration?: 'none' | 'underline';
  className?: string;
  width?: 'max-content',
  font?: 'Akzidenz-Grotesk',
}

export const TextConstructor: FC<TextConstructorProps> = (props) => {
  const {
    color,
    fw = '400',
    fz = '16',
    lh = '24',
    textAlign,
    children,
    textDecoration,
    className,
    width,
    font,
  } = props;

  return (
    <span
      style={{
        fontSize: `${fz}px`,
        fontWeight: `${fw}`,
        lineHeight: `${lh}px`,
        color,
        textAlign,
        textDecoration,
        width
      }}
      className={classNames(className, font && s[font])}
    >
      {children}
    </span>
  );
};
