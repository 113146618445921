import { Container } from "../../../shared/ui/Container";
import classes from './FAQPage.module.scss'
import { FAQ } from "../../../widgets/FAQ";
import { HeaderFAQ } from "./component/HeaderFAQ";
import { Footer } from "../../../widgets/Footer";
import { Header } from "../../../widgets/Header";

export const FAQPage = () => {


  return (
    <>
      <Header />

      <div style={{ marginTop: 70 }}>
        <HeaderFAQ/>
        <Container className={classes.FAQPage}>
          <FAQ />
        </Container>
        <Footer/>
      </div>
    </>
  );
};
