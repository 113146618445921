import classes from './Footer.module.scss';
import LogoIcon from '../../../../shared/assets/images/footer/footerLogo.svg';
import SupportIcon from '../../../../shared/assets/images//footer/support.svg';
import { Link } from 'react-router-dom';
import MailIcon from '../../../../shared/assets/images//footer/mail.svg';
import VKIcon from '../../../../shared/assets/images//footer/VK.svg';
import TelegramIcon from '../../../../shared/assets/images//footer/telegram.svg';
import classNames from 'classnames';
import { useMediaQuery } from '../../../../shared/hooks';

export const Footer = () => {
  const isMobile = useMediaQuery('(min-width: 900px)');

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <div className={classes.iconWrapper}>
          <Link to='/'>
            <img src={LogoIcon} alt='logo' className={classes.supportItem} />
          </Link>
          {!isMobile && (
            <div className={classNames(classes.supportItem, classes.vk)}>
              <a className={classes.supportLink} href='https://t.me/digitalgeneration_ru' rel="noreferrer" target='_blank'>
                <img src={TelegramIcon} alt='vk' />
              </a>
              <a className={classes.supportLink} href='https://vk.com/public222044633' rel="noreferrer" target='_blank'>
                <img src={VKIcon} alt='vk' />
              </a>
            </div>
          )}
        </div>
        <div className={classes.textWrapper}>
          {isMobile && (
            <div className={classes.leftSide}>
              <div className={classes.suppurtWrapper}>
                {/* <div className={classes.supportItem}>
                  <img src={SupportIcon} alt='support' />
                  Клиентская поддержка{' '}
                  <Link className={classes.supportLink} target='_blank' to={'/'}>
                    нажми для связи с нами
                  </Link>
                </div> */}
                <div className={classes.supportItem}>
                  <img src={MailIcon} alt='mail' />
                  Наш контакт: <a className={classes.mailLink} href='mailto:support@digitalgeneration.ru' rel="noreferrer" >support@digitalgeneration.ru</a>
                  
                </div>
                <div className={classNames(classes.supportItem, classes.vk)}>
                <a className={classes.supportLink} href='https://t.me/digitalgeneration_ru' rel="noreferrer" target='_blank'>
                <img src={TelegramIcon} alt='vk' />
              </a>
                <a className={classes.supportLink} href='https://vk.com/public222044633' rel="noreferrer" target='_blank'>
                <img src={VKIcon} alt='vk' />
              </a>
                </div>
              </div>

              <div className={classes.linksWrapper}>
                <Link to={'https://disk.yandex.ru/i/gPMvzrdwoRZ1cg'} download target='_blank' className={classes.linkItem}>
                  Политика конфиденциальности{' '}
                </Link>
                <Link download to={'https://disk.yandex.ru/i/oRaUUlm_Tj4lgQ'} target='_blank' className={classes.linkItem}>
                  Согласие на обработку персональных данных
                </Link>
                <Link to={'https://disk.yandex.ru/i/yb1GIFyh9SvlVQ'} download target='_blank' className={classes.linkItem}>
                  Договор оферта{' '}
                </Link>
              </div>
            </div>
          )}

          <div className={classes.infoWrapper}>
            <div className={classes.title}>ООО «Поколение»</div>
            <ul className={classes.list}>
              <li>ИНН: 9703031432</li>
              <li>КПП: 027301001</li>
              <li>ОГРН: 1217700159214</li>
              <li>Расчётный счет 40702810410000796191</li>
              <li>Бaнк АО «Тинькофф Банк» </li>
              <li>Корреспондентский сче 30101810145250000974</li>
              <li>БИК банка 044525974</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
