import { useEffect, useState } from "react";
import { toAuthorization, toReviews } from "../../../../shared/config/routeConfig/routeConfig";
import { Modal } from "../../../../shared/ui/Modal";
import { NewButton } from "../../../../shared/ui/NewButton";
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import s from './ButtonsReviews.module.scss';
import { Rate } from "antd";
import { usePublishCommentMutation } from "../../../../entities/Comment";
import { useIsAuth } from "../../../../shared/hooks/useIsAuth";

export const ButtonsReviews = () => {
  const isAuth = useIsAuth();
  const [isShow, setIsShow] = useState(false);
  const [rating, setRating] = useState(0);
  const [textareaValue, setTextareaValue] = useState('');

  const [req, { isSuccess }] = usePublishCommentMutation();

  const toggleShowModal = () => {
    setIsShow((prev) => !prev);
  };

  const onChange = (newRating: number) => {
    setRating(newRating);
  };

  const onChangeTextarea = ({ currentTarget: { value } }: React.KeyboardEvent<HTMLTextAreaElement>) => {
    setTextareaValue(value);
  };

  const onClickPublish = () => {
    req({ rating, text: textareaValue });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsShow(false);
    }
  }, [isSuccess]);

  const isDisabled = !textareaValue || !rating;

  return (
    <>
      <HStack gap="40">
        <NewButton
          typeButton="LinkSquare"
          to={toReviews()}
          variant="filled"
          color="greenMain"
          stretch
        >
          Все отзывы
        </NewButton>

        <button className="resetButtonTagStyles" onClick={toggleShowModal}>
          <NewText name="Bt">
            Оставить отзыыв
          </NewText>
        </button>
      </HStack>

      <Modal
        type="newPopUp"
        isShow={isShow}
        onClose={toggleShowModal}
      >
        {isAuth ? (
          <VStack gap="24" align="center">
            {/* @ts-ignore */}
            <textarea className={s.textarea} placeholder="Начните писать отзыв" value={textareaValue} onChange={onChangeTextarea} />

            <NewText name="Text">
              Оцените курс
            </NewText>

            <Rate
              value={rating}
              onChange={onChange}
            />

            <NewButton
              typeButton="ButtonSquare"
              color="greenMain"
              variant="filled"
              onClick={onClickPublish}
              disabled={isDisabled}
            >
              Опубликовать
            </NewButton>
          </VStack>
        ) : (
          <VStack gap="25" align="center">
            <NewText name="H6">
              Оставлять отзывы могут только зарегистрированные пользователи
            </NewText>

            <NewButton
              typeButton="LinkSquare"
              to={toAuthorization()}
              variant="filled"
              color="greenMain"
              stretch
            >
              Зарегистрироваться
            </NewButton>
          </VStack>
        )}
      </Modal>
    </>
  );
};
