import { Link } from 'react-router-dom';
import { Grid } from '../../../../shared/ui/Grid';
import { NewText } from '../../../../shared/ui/NewTypography/NewTypography';
import s from './BurgerMenu.module.scss';
import { toAbout, toCourses, toFaq, toFreeCourses, toProfile } from '../../../../shared/config/routeConfig/routeConfig';

export const BurgerMenu = () => {
  return (
    <div className={s.burgerMenu}>
      <Grid colums="2" verticalGap="30">
        <Link to={toCourses()}>
          <NewText name="H6">
            Каталог курсов
          </NewText>
        </Link>
        <Link to={toAbout()}>
          <NewText name="H6">
            О нас
          </NewText>
        </Link>
        <Link to={toFreeCourses()}>
          <NewText name="H6">
            Бесплатные курсы
          </NewText>
        </Link>
        <Link to={toFaq()}>
          <NewText name="H6">
            FAQ
          </NewText>
        </Link>
        <Link to={toProfile()}>
          <NewText name="H6">
            Профиль
          </NewText>
        </Link>
      </Grid>
    </div>
  );
};
