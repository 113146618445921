import s from './Certificate.module.scss';
import certificateBackgroundImage from '../../../shared/assets/images/certificateBackground.jpg'
import groupOfPeopleImg from '../../../shared/assets/images/groupOfPeople.png'
import logoImg from '../../../shared/assets/images/logo.png'
import signImg from '../../../shared/assets/images/sign.jpg'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import classNames from 'classnames';
import { useEffect } from 'react';
import { VStack } from '../../../shared/ui/Stack';
import { TextConstructor } from '../../../shared/ui/TextConstructor';
import { useSelector } from 'react-redux';
import { getUserAuthData } from '../../../entities/User/model/selectors/getUserData/getUserData';
import { useParams } from 'react-router-dom';
import { useCouseByIdQuery } from '../../../entities/Course';

export const Certificate = () => {
  const { courseId } = useParams();
  const userData = useSelector(getUserAuthData);
  const { data, isLoading } = useCouseByIdQuery({ courseId: courseId as string });

  const downloadPDF = () =>{
    const capture = document.querySelector('.actual-receipt') as HTMLElement;

    if (!capture) {
      return;
    }

    html2canvas(capture).then((canvas)=>{
      const imgData = canvas.toDataURL('img/png');
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      doc.save('Сертификат.pdf');
    })
  }

  useEffect(() => {
    if (!isLoading && data) {
      downloadPDF();
    }
  }, [data, isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <div className={classNames(s.certificate, 'actual-receipt')}>
      <img className={s.bg} src={certificateBackgroundImage} alt="*" />
      <img className={s.groupOfPeopleImg} src={groupOfPeopleImg} alt="*" />
      <div className={s.mainBlockWrapper}>
        <VStack className={s.mainBlock} align="center">
          <img src={logoImg} alt="*" />
          <TextConstructor color="#0D8E74" fw="800" fz="64" lh="44" textAlign="center" className={s.mt60}>
            СЕРТИФИКАТ
          </TextConstructor>
          <TextConstructor color="#0E0E06" fw="500" fz="15" lh="20" textAlign="center" className={s.mt60}>
            Этот  сертификат подтверждает, что
          </TextConstructor>
          <TextConstructor color="#0E0E06" fw="600" fz="40" lh="44" textAlign="center" className={s.mt20}>
            {userData?.full_name}
          </TextConstructor>
          <TextConstructor color="#0E0E06" fw="500" fz="15" lh="20" textAlign="center" className={s.mt20}>
            успешно завершил(-а) онлайн-курс
          </TextConstructor>
          <TextConstructor color="#0E0E06" fw="500" fz="15" lh="20" textAlign="center">
            «{data?.result.name}»
          </TextConstructor>
          <img src={signImg} alt="*" className={s.mt80} />
        </VStack>
      </div>
    </div>
  );
};
