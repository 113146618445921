import React, { FC } from 'react'
import classes from './LessonItem.module.scss'
import DoneIcon from '../../../../../shared/assets/images/done.svg'
import LockIcon from '../../../../../shared/assets/images/lock.svg'
import { getTimeLesson } from '../../../../../shared/utils/getTimeLesson'
import { LessonRequestResultType } from '../../../../../entities/Lesson/types/lessonTypes'
import { Link, useParams } from 'react-router-dom'
import { toLessonPage } from '../../../../../shared/lib/links/toLessonPage'
import classNames from 'classnames'

interface IProps {
  lesson: LessonRequestResultType;
  idx: number;
  isCompleted: boolean;
  isCurrent: boolean;
  isOpen: boolean;
}
export const LessonItem:FC<IProps> = ({idx, lesson, isCompleted, isCurrent, isOpen}):JSX.Element => {
  const { courseId } = useParams();

  return (
        <Link className={classes.link} to={toLessonPage(String(courseId), String(lesson?.id))}>
    <div className={classNames(classes.root, isCurrent && classes.isCurrent)}>

        <div className={classes.text__wrapper}>
          {isCompleted ? (
            <div className={classes.icon}>
              <img src={DoneIcon} alt="done" />
            </div>
          ) : null}
          {!isOpen ? (
            <div className={classes.icon}>
              <img src={LockIcon} alt="done" />
            </div>
          ) : null}
          <span style={{paddingLeft: lesson?.user_complete ? '0px' : '40px'}} className={classNames(classes.lesson, isCurrent && classes.lessonText)}>{idx + 1} урок</span>
          <span  className={classNames(classes.lesson__name, isCurrent && classes.lessonTextName)}>{lesson.name}</span>
        </div>

        {/* <span className={classNames(classes.time, isCurrent && classes.lessonTextTime)}>{getTimeLesson(lesson.learn_time)}</span> */}
      </div>
    </Link>
  )
}
