import { FC, ReactNode } from 'react';

interface DateFormaterProps {
  date: string;
  children: (formatDate: string) => ReactNode; 
}

export const DateFormater: FC<DateFormaterProps> = (props) => {
  const {
    date,
    children,
  } = props;

  const formatter = new Intl.DateTimeFormat('ru', {
    month: 'long',
    day: 'numeric',
  });

  const formatDate = formatter.format(new Date(date));

  return (
    <>
      {children(formatDate)}
    </>
  );
};
