import { useMediaQuery } from '../../../../shared/hooks';
import { DesktopIntroBlock } from '../DesktopIntroBlock/DesktopIntroBlock';
import { MobileIntroBlock } from '../MobileIntroBlock/MobileIntroBlock';
import { TabletIntroBlock } from '../TabletIntroBlock/TabletIntroBlock';

export const IntroBlock = () => {
  const showDesktop = useMediaQuery('(min-width: 1580px)');
  const showTablet = useMediaQuery('(min-width: 960px)');
  const showModile = useMediaQuery('(max-width: 959px)');

  if (showDesktop) {
    return (
      <DesktopIntroBlock />
    );
  }

  if (showTablet) {
    return (
      <TabletIntroBlock />
    );
  }

  if (showModile) {
    return (
      <MobileIntroBlock />
    );
  }

  return null;
};
