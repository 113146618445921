import { FC, useState } from "react";
import { RestorePhoneStep, RestorePhoneStepProps } from "./RestorePhoneStep/RestorePhoneStep";
import { RestoreSmsCodeStep } from "./RestoreSmsCodeStep/RestoreSmsCodeStep";

type RestoreProps = {
  toLoginStep: () => void;
  onStepCompleted: () => void;
};

export const Restore: FC<RestoreProps> = (props) => {
  const {
    toLoginStep,
    onStepCompleted,
  } = props;

  const [step, setStep] = useState<'phoneStep' | 'smsCodeStep'>('phoneStep');

  const onClickSendSmsCode: RestorePhoneStepProps['onClickSendSmsCode'] = ({ isErrors }) => {
    if (!isErrors) {
      setStep('smsCodeStep');
    }
  }

  return step === 'phoneStep' ? (
    <RestorePhoneStep
      toLoginStep={toLoginStep}
      onClickSendSmsCode={onClickSendSmsCode}
    />
  ) : (
    <RestoreSmsCodeStep onStepCompleted={onStepCompleted} />
  );
};
