import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REFRESH_TOKEN_LOCALSTORAGE_KEY, TOKEN_LOCALSTORAGE_KEY, USER_LOCALSTORAGE_KEY } from '../../../../shared/const/localstorage';
import { TestSchema } from '../types/testTypes';

const initialState: TestSchema = {
  versionId: 0,
  currentQuestionIndex: 0,
  selectedOptions: [],
};

const testSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
      setVersionId: (state, { payload }: PayloadAction<TestSchema['versionId']>) => {
        state.versionId = payload;
        state.currentQuestionIndex = 0;
        state.selectedOptions = [];
      },
      setCurrentQuestionIndex: (state, { payload }: PayloadAction<TestSchema['currentQuestionIndex']>) => {
        state.currentQuestionIndex = payload;
        state.selectedOptions = [];
      },
      setSelectedOptions: (state, { payload }: PayloadAction<TestSchema['selectedOptions']>) => {
        state.selectedOptions = payload;
      },
    },
});

export const { actions: testActions } = testSlice;
export const { reducer: testReducer } = testSlice;
