import WorldIcon from '../../../shared/assets/images/whyWeAre/world.svg'
import RocketIcon from '../../../shared/assets/images/whyWeAre/advantages.svg'

export const reasons = [
  {
    title: 'Учись из любой точки мира',
    subtitle: 'Занимайся в своем темпе, когда и где удобно',
    img: WorldIcon
  },
  {
    title: 'Начните свой путь обучения с наших бесплатных курсов',
    subtitle: 'У нас вы найдете короткие базовые курсы, которые помогут вам освоить основы знаний в интересующей области',
    img: RocketIcon,
  }
]