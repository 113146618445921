
import { Container } from '../../../shared/ui/Container'
import { Footer } from '../../../widgets/Footer'
import { useCoursesQuery } from '../../../entities/Courses'
import { FreeCourseCard } from '../../../shared/ui/FreeCourseCard'
import { Grid } from '../../../shared/ui/Grid'
import { Banners } from '../../../widgets/Banners';
import s from './FreeCourses.module.scss';
import { WhyWeAre } from '../../../widgets/WhyWeAre/ui/WhyWeAre'
import { useState } from 'react'
import { CourseCardWithMarketingProps } from '../../../entities/Course'
import { ReviewModal } from '../../../widgets/ReviewModal'
import { useMediaQuery } from '../../../shared/hooks'
import { useMyCoursesQuery } from '../../../widgets/MyCoursesList/api/myCoursesListApi'
import { Header } from '../../../widgets/Header'

export const FreeCourses = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [courseId, setCourseId] = useState<number | null>(null);
  const {data} = useCoursesQuery();
  const { data: myCoursesQuery } = useMyCoursesQuery();
  const listOfFreeCourses = data?.result?.filter(el => el?.active === true && el?.free === true);
  const match = useMediaQuery('(min-width: 1000px)'); 

  const onClickRating: CourseCardWithMarketingProps['onClickRating'] = (_courseId) => {
    setIsShowModal(true);
    setCourseId(_courseId);
  }

  const onClose = () => {
    setIsShowModal(false);
  }

  return (
    <div>
    <Header />

    <div className={s.bannersBg}>
      <Container>
        <Banners place_name="courses_1" className={s.bannersWrapper} />
      </Container>
    </div>

    <Container>
      <h1>Бесплатные курсы</h1>
      <Grid colums={match ? '2' : '1'} horizontalGap='20' verticalGap='20'>
        {listOfFreeCourses?.map((el, i) => (
          <FreeCourseCard
            key={i}
            commentCount={el?.comment_count}
            rating={el?.rating}
            courseId={el?.id}
            onClickRating={onClickRating}
            image={el?.image}
            descr={el?.description}
            name={el?.name}
            progress={el?.percent_complete}
            payLink={el.pay_link}
            isAvailable={!!myCoursesQuery?.result.some(({ id }) => ( el.id === id ))}
          />
        ))}
      </Grid>
      <WhyWeAre/>
    </Container>
    <Footer/>
    <ReviewModal
        isShow={isShowModal}
        onClose={onClose} 
        courseId={String(courseId)}
        name={data?.result.find(({ id }) => id === courseId)?.name || ''}
      />
    </div>
  )
}
