import { FC, ReactNode } from "react";
import { Grid } from "../../../../shared/ui/Grid";
import { useMediaQuery } from "../../../../shared/hooks";
import s from './CoursesList.module.scss';

interface CoursesListProps {
  children: ReactNode,
}

export const CoursesList: FC<CoursesListProps> = (props) => {
  const {
    children,
  } = props;

  const isShow2Colums = useMediaQuery('(max-width: 1540px)');
  const isShow1Colums = useMediaQuery('(max-width: 940px)');

  const getColums = () => {
    if (isShow1Colums) {
      return '1';
    }

    if (isShow2Colums) {
      return '2';
    }

    return '4';
  }

  return (
    <Grid colums={getColums()} horizontalGap="20" verticalGap="20" className={s.coursesList}>
      {children}
    </Grid>
  );
};
