import { RouteProps } from 'react-router-dom';
import { MainPage } from '../../../pages/MainPage';
import { AuthorizationPage } from '../../../pages/AuthorizationPage';
import { ForbiddenPage } from '../../../pages/ForbiddenPage';
import { NotFoundPage } from '../../../pages/NotFoundPage';
import { CoursesPage } from '../../../pages/CoursesPage';
import { CoursePage } from '../../../pages/CoursePage';
import { ProfilePage } from '../../../pages/ProfilePage';
import { MyStudyPage } from '../../../pages/MyStudyPage';
import { LessonPage } from '../../../pages/LessonPage';
import { PaymentPage } from '../../../pages/PaymentPage';
import { FAQPage } from '../../../pages/FAQPage/ui/FAQPage';
import { TestPage } from '../../../pages/TestPage';
import { SuccessPaymentPage } from '../../../pages/SuccessPaymentPage';
import { ErrorPaymentPage } from '../../../pages/ErrorPaymentPage';
import { AboutPage } from '../../../pages/AboutPage';
import { FreeCourses } from '../../../pages/FreeCourses';
import { CertificatePage } from '../../../pages/CertificatePage';
import { ReviewsPage } from '../../../pages/ReviewsPage';

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean;
}

export enum AppRoutes {
  MAIN = 'main',
  AUTHORIZATION = 'authorization',
  FORBIDDEN = 'forbidden',
  COURSES = 'courses',
  COURSE = 'course',
  PROFILE = 'profile',
  MY_STUDY = 'my_study',
  LESSON = 'lesson',
  PAYMENT = 'payment',
  FAQ = 'faq',
  FREE_COURSES = 'free-courses',
  PAYMENT_SUCCESS = 'payment_success',
  PAYMENT_ERROR = 'payment_error',
  TEST = 'test',
  ABOUT = 'about',
  CERTIFICATE = 'certificate',
  REVIEWS = 'reviews',
  // last
  NOT_FOUND = 'not_found',
}

export const toMain = () => '/';
export const toAuthorization = () => '/authorization';
export const toStudy = () => '/my-study';
export const toCourses = () => '/courses';
export const toAbout = () => '/about';
export const toFreeCourses = () => '/free-courses';
export const toFaq = () => '/faq';
export const toProfile = () => '/profile';
export const toCourse = (courseId: string) => `/my-study/course/${courseId}`;
export const toReviews = () => `/reviews`;

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: toMain(),
  [AppRoutes.AUTHORIZATION]: toAuthorization(),
  [AppRoutes.FORBIDDEN]: '/forbidden',
  [AppRoutes.COURSES]: toCourses(),
  [AppRoutes.COURSE]: toCourse(':courseId'),
  [AppRoutes.PROFILE]: toProfile(),
  [AppRoutes.MY_STUDY]: toStudy(),
  [AppRoutes.FAQ]: toFaq(),
  [AppRoutes.FREE_COURSES]: toFreeCourses(),
  [AppRoutes.ABOUT]: toAbout(),
  [AppRoutes.LESSON]: '/my-study/course/:courseId/lesson/:lessonId',
  [AppRoutes.PAYMENT]: '/payment/:formId',
  [AppRoutes.PAYMENT_SUCCESS]: '/payment/success',
  [AppRoutes.PAYMENT_ERROR]: '/payment/error',
  [AppRoutes.TEST]: '/my-study/course/:courseId/lesson/:lessonId/test/:testId',
  [AppRoutes.CERTIFICATE]: '/my-study/course/:courseId/certificate',
  [AppRoutes.REVIEWS]: toReviews(),
  // последний
  [AppRoutes.NOT_FOUND]: '*',
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
},
  [AppRoutes.AUTHORIZATION]: {
      path: `${RoutePath.authorization}`,
      element: <AuthorizationPage />,
  },
  [AppRoutes.FORBIDDEN]: {
      path: `${RoutePath.forbidden}`,
      element: <ForbiddenPage />,
  },
  [AppRoutes.COURSES]: {
      path: `${RoutePath.courses}`,
      element: <CoursesPage />,
  },
  [AppRoutes.COURSE]: {
      path: `${RoutePath.course}`,
      element: <CoursePage />,
      authOnly: true,
  },
  [AppRoutes.PROFILE]: {
      path: `${RoutePath.profile}`,
      element: <ProfilePage />,
      authOnly: true,
  },
  [AppRoutes.FAQ]: {
      path: `${RoutePath.faq}`,
      element: <FAQPage />,
  },
  [AppRoutes.FREE_COURSES]: {
      path: `${RoutePath['free-courses']}`,
      element: <FreeCourses />,
  },
  [AppRoutes.ABOUT]: {
      path: `${RoutePath.about}`,
      element: <AboutPage />,
  },
  [AppRoutes.MY_STUDY]: {
      path: `${RoutePath.my_study}`,
      element: <MyStudyPage />,
      authOnly: true,
  },
  [AppRoutes.LESSON]: {
      path: `${RoutePath.lesson}`,
      element: <LessonPage />,
      authOnly: true,
  },
  [AppRoutes.PAYMENT]: {
      path: `${RoutePath.payment}`,
      element: <PaymentPage />,
  },
  [AppRoutes.PAYMENT_SUCCESS]: {
      path: `${RoutePath.payment_success}`,
      element: <SuccessPaymentPage />,
  },
  [AppRoutes.PAYMENT_ERROR]: {
      path: `${RoutePath.payment_error}`,
      element: <ErrorPaymentPage />,
  },
  [AppRoutes.TEST]: {
      path: `${RoutePath.test}`,
      element: <TestPage />,
      authOnly: true,
  },
  [AppRoutes.CERTIFICATE]: {
      path: `${RoutePath.certificate}`,
      element: <CertificatePage />,
      authOnly: true,
  },
  [AppRoutes.REVIEWS]: {
      path: `${RoutePath.reviews}`,
      element: <ReviewsPage />,
  },
  // последний
  [AppRoutes.NOT_FOUND]: {
      path: RoutePath.not_found,
      element: <NotFoundPage />,
  },
};