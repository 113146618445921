import { FC, useEffect } from "react";
import { NewPopUp, NewPopUpProps } from './NewPopUp/NewPopUp';
import { FullScreenModal, FullScreenModalProps } from "./FullScreenModal/FullScreenModal";
import { PopUp, PopUpProps } from "./PopUp/PopUp";

interface FullScreenModalType extends FullScreenModalProps {
  type: 'fullScreen';
}

interface PopUpType extends PopUpProps {
  type: 'popUp';
}

interface NewPopUpType extends PopUpProps {
  type: 'newPopUp';
}

export type ModalProps = (FullScreenModalType | PopUpType | NewPopUpType) & {
  isShow: boolean;
};

export const Modal: FC<ModalProps> = (props) => {
  const {
    children,
    onClose,
    isShow,
    type,
  } = props;

  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (!bodyElement) {
      return;
    }

    if (isShow) {
      bodyElement.style.overflow = 'hidden';
    } else {
      bodyElement.style.overflow = 'visible';
    }

    return () => {
      bodyElement.style.overflow = 'visible';
    }
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  const getModal = () => {
    if (type === 'fullScreen') {
      return (
        <FullScreenModal onClose={onClose}>
          {children}
        </FullScreenModal>
      );
    };

    if (type === 'newPopUp') {
      return (
        <NewPopUp onClose={onClose}>
          {children}
        </NewPopUp>
      );
    }

    return (
      <PopUp onClose={onClose}>
        {children}
      </PopUp>
    );
  }

  return getModal();
};
