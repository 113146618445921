import { FC, useEffect, useRef } from "react";
import { HStack, VStack } from "../../Stack";
import { TextConstructor } from "../../TextConstructor";
import classNames from "classnames";
import s from './SmsCodeForm.module.scss';
import { Input } from "../../Input";

export interface SmsCodeFormProps {
  сodeValue: string;
  phone: string;
  onChangeCode: (value: string) => void;
  onClickSendSMSCodeAgain: () => void;
  className?: string;
  isError?: boolean;
}

export const SmsCodeForm: FC<SmsCodeFormProps> = (props) => {
  const {
    onChangeCode: onChangeCodeProp,
    onClickSendSMSCodeAgain,
    phone,
    сodeValue,
    className,
    isError,
  } = props;

  const firstInput = useRef<HTMLInputElement | null>(null);
  const secondInput = useRef<HTMLInputElement | null>(null);
  const threeInput = useRef<HTMLInputElement | null>(null);
  const fourthInput = useRef<HTMLInputElement | null>(null);

  const inputs = [firstInput, secondInput, threeInput, fourthInput]

  useEffect(() => {
    if (firstInput.current) {
      firstInput.current.focus();
    }
  }, []);

  const onChangeCode = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    const index = сodeValue.length;

    if (!value) {
      onChangeCodeProp(сodeValue.slice(0, -1));
      inputs[index - 2]?.current?.focus();
      return;
    }

    if ((value.length + сodeValue.length) > 4) {
      return;
    }

    const regex = new RegExp(/[^\d.-]+/g);

    const newValue = value.replace(regex, '');

    inputs[index + 1]?.current?.focus();

    onChangeCodeProp(сodeValue + newValue);
  }

  return (
    <VStack gap="24" className={classNames(className, s.smsCodeForm)} align="center">
        <HStack gap="4">
          <TextConstructor color="#E4E4E4" fz="15" lh="24" >
            Код отправлен на номер:
          </TextConstructor>
          <TextConstructor color="#FFFFFF" fz="15" lh="24" >
            {phone}
          </TextConstructor>
        </HStack>
      
      <HStack gap="20">
        <Input value={сodeValue[0]} onChange={onChangeCode} className={s.codeInput} placeholder="0" inputRefProp={firstInput} />
        <Input value={сodeValue[1]} onChange={onChangeCode} className={s.codeInput} placeholder="0" inputRefProp={secondInput} />
        <Input value={сodeValue[2]} onChange={onChangeCode} className={s.codeInput} placeholder="0" inputRefProp={threeInput} />
        <Input value={сodeValue[3]} onChange={onChangeCode} className={s.codeInput} placeholder="0" inputRefProp={fourthInput} />
      </HStack>

      {isError && (
        <div className={s.error}>
          <TextConstructor color="#FF6161" fw="500" fz="14" lh="20">
            Указан неверный код. Попробуйте ещё раз, либо запросите новый код
          </TextConstructor>
        </div>
      )}

      <HStack className={s.sendAgain} gap="4">
        <TextConstructor color="#E4E4E4">
          Не пришел код?
        </TextConstructor>
        <button onClick={onClickSendSMSCodeAgain} className="resetButtonTagStyles">
          <TextConstructor color="#34C759">
            Отправить ещё раз
          </TextConstructor>
        </button>
      </HStack>
    </VStack>
  );
};
