import { toAbout, toCourses, toFaq, toFreeCourses } from "../../../../shared/config/routeConfig/routeConfig";
import { HStack } from "../../../../shared/ui/Stack";
import { NavItem } from "../NavItem/NavItem";

export const Navbar = () => {
  return (
    <HStack gap="70">
      <NavItem
        label="Каталог курсов"
        href={toCourses()}
      />

      <NavItem
        label="Бесплатные курсы"
        href={toFreeCourses()}
      />

      <NavItem
        label="О нас"
        href={toAbout()}
      />

      <NavItem
        label="FAQ"
        href={toFaq()}
      />
    </HStack>
  );
};
