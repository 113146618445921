import { HStack, VStack } from "../../../../shared/ui/Stack";
import { TitleReviews } from "../../../../shared/ui/TitleReview/TitleReviews";
import { ButtonsReviews } from "../ButtonsReviews/ButtonsReviews";
import { ReviewBlocks } from "../ReviewBlocks/ReviewBlocks";
import s from './TabletReviews.module.scss';

export const TabletReviews = () => {
  return (
    <HStack className={s.tabletReviews}>
      <VStack gap="40">
        <TitleReviews devices="tablet" />
        <ButtonsReviews />
      </VStack>
      <ReviewBlocks device="tablet" className={s.reviewBlocks} />
    </HStack>
  );
};
