import { FC } from 'react'
import classes from './ReasonItem.module.scss'

interface IProps {
  img?: string
  title: string
  subtitle: string
}


export const ReasonItem:FC<IProps> = ({img, subtitle, title}):JSX.Element => {
  return (
    <div className={classes.root}>
      <img className={classes.img} src={img} alt="reasons" />
      <div>
        <div className={classes.title}>{title}</div>
        <div className={classes.subtitle}>{subtitle}</div>
      </div>
    </div>
  )
}
