import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { RegisterRequestQueryArg, RegisterRequestResultType } from "../types/registrationTypes";

const registrationApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    register: build.mutation<ApiResponseWrapper<RegisterRequestResultType>, RegisterRequestQueryArg>({
      query: (body) => {
        return {
          url: 'register/',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const { useRegisterMutation } = registrationApi;
