import { FC } from "react";
import { TabletTextBlock } from "../TabletTextBlock/TabletTextBlock";
import { DesktopTextBlock } from "../DesktopTextBlock/DesktopTextBlock";
import { MobileTextBlock } from "../MobileTextBlock/MobileTextBlock";

interface TextBlockProps {
  device: 'desktop' | 'tablet' | 'mobile';
  className?: string;
}

export const TextBlock: FC<TextBlockProps> = (props) => {
  const {
    device,
    className,
  } = props;

  if (device === 'desktop') {
    return (
      <DesktopTextBlock className={className} />
    );
  }

  if (device === 'tablet') {
    return (
      <TabletTextBlock className={className} />
    );
  }

  if (device === 'mobile') {
    return (
      <MobileTextBlock className={className} />
    );
  }

  return null;
};