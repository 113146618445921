import { FC } from "react"
import { BlockDataFile } from "../../../../../entities/Lesson/types/lessonTypes"
import classes from './FileItem.module.scss'
import DownloadIcon from '../../../../../shared/assets/images/Download.svg'

interface IProps {
  data: BlockDataFile
}
export const FileItem:FC<IProps> = ({data}):JSX.Element => {
  return (
    <a href={data?.link} download  className={classes.root}>
      <div className={classes.name}>{data?.name}</div>
      <div className={classes.right}>
        {/* <span className={classes.size}>{data?.size}</span> */}
        <img src={DownloadIcon} alt="DownloadIcon" />
      </div>

    </a>
  )
}
