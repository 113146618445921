import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { REFRESH_TOKEN_LOCALSTORAGE_KEY, TOKEN_LOCALSTORAGE_KEY } from "../../../shared/const/localstorage";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { RestoreRequestQueryArg, RestoreRequestResultType } from "../types/restoreTypes";

const restoreApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    restore: build.mutation<ApiResponseWrapper<RestoreRequestResultType>, RestoreRequestQueryArg>({
      invalidatesTags: ['User'],
      query: (body) => {
        return {
          url: 'verify/forgot_password/verify_phone_code/',
          method: 'POST',
          body,
        };
      },
      // @ts-ignore
      transformResponse: (response) => {
        // @ts-ignore
        localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, response?.result.tk || '');
        // @ts-ignore
        localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, response?.result.tk_refresh || '');

        return response;
      }
    }),
  }),
});

export const { useRestoreMutation } = restoreApi;
