import { FC, useEffect, useRef, useState } from 'react'
import { Card } from '../../../../../shared/ui/Card'
import classes from './LessonVideo.module.scss'
import { BlockDataVideo } from '../../../../../entities/Lesson/types/lessonTypes'
import { Tabs } from '../../../../../shared/ui/Tabs/Tabs'
import { Timing } from '../Timing/Timing'

interface IProps {
  data: BlockDataVideo 
  title: string
}

const TAB = [
  {value: 'description', label: 'Описание'},
  {value: 'timing', label: 'Тайминг'},
]
const TAB_WITHOUT = [
  {value: 'description', label: 'Описание'}
]
interface ITest {
  value: string
  label: string
}
export const LessonVideo:FC<IProps> = ({data, title}):JSX.Element => {
  const [tabData, setTabData] = useState<Array<ITest>>()
console.log(data, title)
  useEffect(() => {
    if(data?.timecodes) {
      setTabData(TAB)
    } else {
      setTabData(TAB_WITHOUT)
    }
  }, [data])

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [tab, setTab] = useState(TAB[0].value)
  const [height, setHeight] = useState(0);
  const [key, setKey] = useState(0);
  const [timing, setTiming] = useState('')

  const onChangeHeight = () => {
    const iframe = iframeRef.current;

    if (!iframe || !(iframe instanceof HTMLElement)) {
      return;
    }

    const width = iframeRef.current.clientWidth;
    const newHeight = Math.ceil(width / 1.79);

    setHeight(newHeight);
  };

  useEffect(() => {
    onChangeHeight();

    window.addEventListener('resize', onChangeHeight);

    return () => {
      window.removeEventListener('resize', onChangeHeight);
    };
  }, []);

  useEffect(() => {
    setKey(performance.now());
  }, [timing]);

  return (
    <Card
      title={`Видео: ${title}`}
    >
      <div style={{ height: `${600}px` }} className={classes.videoBlock}>
        <iframe
          title="This is a unique title"
          key={key}
          src={`${data?.link}#t=${timing}`}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          className={classes.iframe}
          ref={iframeRef}
        />
      </div>

      <Tabs onChange={setTab} options={tabData || []} selectedValue={tab}/>

      {tab === 'description' && (
        <p className={classes.description}>{data.description}</p>
      )}
      {tab === 'timing' && (
        <Timing timing={data.timecodes} setTiming={setTiming}/>
      )}
    </Card>
  );
}
