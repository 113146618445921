import classes from './NavigateBlock.module.scss'
import ClockIcon from '../../../../../shared/assets/images/time_circle_outline.svg'
import ChevronIcon from '../../../../../shared/assets/images/Chevron.svg'
import { Dispatch, FC, SetStateAction } from 'react'
import { toLessonPage } from '../../../../../shared/lib/links/toLessonPage'

import { Link } from 'react-router-dom'
import { LessonRequestResultType } from '../../../../../entities/Lesson/types/lessonTypes'
import { useCouseByIdQuery } from '../../../../../entities/Course'
import { useMediaQuery } from '../../../../../shared/hooks'

type TData = {
  isAvaible?: boolean
  id?: number
} | null

interface IProps {
  navigate: TData[]
  courseId?: string
  lessonInfo?: LessonRequestResultType
  setOpenMenu: Dispatch<SetStateAction<boolean>>
  openMenu: boolean
}
export const NavigateBlock:FC<IProps> = ({navigate, courseId, lessonInfo, setOpenMenu, openMenu}):JSX.Element => {
  const { data } = useCouseByIdQuery({ courseId: courseId || '' });
  const isMobile = useMediaQuery('(max-width: 1000px)');

  const modules = data?.result.modules;
  // @ts-ignore
  const currentModule = modules?.find(({ lessons }) => (lessons || []).some(( lesson ) => lesson?.name === lessonInfo?.name));
  // @ts-ignore
  const moduleName = currentModule?.name || '';

  return (
    <div className={classes.root}>
      <div className={classes.btns}>
      <Link className={classes.btn} to={navigate[0] ? toLessonPage(String(courseId), String(navigate?.[0]?.id)) : '#'}>
        {`<`}
      </Link>

        <div className={classes.lesson}>Модуль «{moduleName}» {lessonInfo?.weight} урок</div>
      <Link className={classes.btn} to={navigate[1] ? toLessonPage(String(courseId), String(navigate?.[1]?.id)) : '#'}>
        {`>`}
      </Link>

      </div>

      <div className={classes.bottom}>
        <div>
          <div className={classes.title}>{lessonInfo?.name}</div>
          <div className={classes.subtitle}><img src={ClockIcon} alt="clock" />Время прохождения {lessonInfo?.learn_time} мин.</div>
        </div>
        {!isMobile && (
          <button onClick={() => setOpenMenu(prev => !prev)} className={classes.menuBtn}>Меню курса <img src={ChevronIcon} alt="chevron" className={openMenu ? classes.active : ''}/></button>
        )}
      </div>
    </div>
  )
}
