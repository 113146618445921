import { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import s from './ButtonSquareUI.module.scss';
import { CommonButtonUIProps } from '../types/ButtonTypes';
import Loader from '../../Loader/Loader';

export type ButtonSquareUIProps = {
  stretch?: boolean,
  leftNode?: ReactNode,
  rightNode?: ReactNode,
  isLoading?: boolean,
} & CommonButtonUIProps;

export const ButtonSquareUI: FC<ButtonSquareUIProps> = (props) => {
  const {
    children, size, classNameButton, stretch, invertedTheme, color = 'green',
    nonInteractive, leftNode, rightNode, isLoading, variant = 'filled', disabled, ...otherProps
  } = props;

  const Content = useMemo(() => {
    if (isLoading) {
      return (
        <Loader color="#FFFFFF" size="48" />
      );
    }

    return (
      <>
        {leftNode && (
        <div className={s.leftNode}>{leftNode}</div>
        )}
        {children}
        {rightNode && (
          <div className={s.rightNode}>{rightNode}</div>
        )}
      </>
    );
  }, [children, isLoading, leftNode, rightNode]);

  return (
    <div
      className={classNames(
        size && s[size],
        s[variant],
        s[color],
        classNameButton,
        s.buttonSquareUI,
        stretch && s.stretch,
        disabled && s.disabled,
        invertedTheme && s.invertedTheme,
        nonInteractive && s.nonInteractive,
      )}
      {...otherProps}
    >
      {Content}
    </div>
  );
};
