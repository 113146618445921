import { FC } from "react";
import { HStack, VStack } from "../../../../shared/ui/Stack";
import { Avatar } from "../../../../shared/ui/Avatar";
import { TextConstructor } from "../../../../shared/ui/TextConstructor";
import { Rate } from "antd";
import { DateFormater } from "../../../../shared/ui/DateFormater";

interface CommentWithRatingProps {
  fullName: string,
  timeAgo: string,
  text: string;
  rating: number;
}

export const CommentWithRating: FC<CommentWithRatingProps> = (props) => {
  const {
    fullName,
    rating,
    text,
    timeAgo,
  } = props;

  return (
    <VStack gap="8" max>
      <HStack gap="12" max>
        <HStack>
          <Avatar />
        </HStack>

        <HStack justify="between" max>
          <TextConstructor color="#0E0E06" fw="500" fz="16" lh="24">
            {fullName}
          </TextConstructor>
            <DateFormater date={timeAgo}>
              {(date) => (
                <TextConstructor color="#9F9FA0" fw="400" fz="14" lh="18">
                  {date}
                </TextConstructor>
              )}
            </DateFormater>
        </HStack>
      </HStack>

      <TextConstructor color="#0E0E06" fz="15" lh="20" fw="400">
        {text}
      </TextConstructor>

      <Rate defaultValue={rating} disabled/>
    </VStack>
  );
};
