import { FC, useEffect, useRef, useState } from "react";
import { isCorrectPhone } from "../../../../shared/lib/phone";
import { authorizationFormActions } from "../../../../shared/model/authorizationForm/slices/authorizationForm";
import { useDispatch, useSelector } from "react-redux";
import { getAuthorizationFormPhone } from "../../../../shared/model/authorizationForm";
import { RestoreForm } from "../../../../entities/RestoreForm";

interface ValidateErrors {
  phone: 'Это обязательное поле' | 'Неккоректный номер телефона' | '',
}

export interface RestorePhoneStepProps {
  toLoginStep: () => void;
  onClickSendSmsCode: (arg: { isErrors: boolean }) => void;
};

export const RestorePhoneStep: FC<RestorePhoneStepProps> = (props) => {
  const {
    toLoginStep,
    onClickSendSmsCode: onClickSendSmsCodeProp,
  } = props;

  const validateErrors = useRef<ValidateErrors>({
    phone:'Это обязательное поле',
  });

  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  const phone = useSelector(getAuthorizationFormPhone);

  const onClickSendSmsCode = () => {
    setIsShow(true);
    
    if (!Object.values(validateErrors.current).some((error) => error)) {
      onClickSendSmsCodeProp({ isErrors: false });
      return;
    }

    onClickSendSmsCodeProp({ isErrors: true });
  }

  const onChangePhone = (phoneNumber: string) => {
    dispatch(authorizationFormActions.setAuthorizationFormPhone(phoneNumber));

    if (!isCorrectPhone(phoneNumber)) {
      validateErrors.current = {
        ...validateErrors.current,
        phone: 'Неккоректный номер телефона',
      }
    } else if (phoneNumber) {
      validateErrors.current = {
        ...validateErrors.current,
        phone: '',
      }
    } else {
      validateErrors.current = {
        ...validateErrors.current,
        phone: 'Это обязательное поле',
      }
    };
  };

  useEffect(() => {
    if (phone) {
      onChangePhone(phone);
    }
  }, []);

  return (
    <RestoreForm
      phone={phone}
      onChangePhone={onChangePhone}
      toLoginStep={toLoginStep}
      onClickSendSmsCode={onClickSendSmsCode}
      errorPhone={isShow ? validateErrors.current.phone : ''}
    />
  );
};
