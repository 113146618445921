import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import successImg from '../../../shared/assets/images/success.png';
import s from './SuccessPaymentPage.module.scss';
import { Button } from "../../../shared/ui/Button";

export const SuccessPaymentPage = () => {
  return (
    <VStack gap="24" align="center" className={s.successPaymentPage}>
      <TextConstructor color="#0E0E06" fz="40" lh="52" textAlign="center" fw="600">
        Спасибо!
      </TextConstructor>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        Ваш заказ оплачен
      </TextConstructor>
      <TextConstructor color="#5B5B5B" fz="16" lh="24" textAlign="center" fw="400">
        Письмо-подтверждение отправлено на электронную почту
      </TextConstructor>
      <HStack max justify="center">
        <img src={successImg} alt="*" className={s.img} />
      </HStack>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        Проверьте свою почту
      </TextConstructor>
      <TextConstructor color="#0E0E06" fz="24" lh="32" textAlign="center" fw="600">
        <div>
          <TextConstructor color="#5B5B5B" fz="16" lh="24" fw="400" textAlign="center">
            Если вы не получили письма, свяжитесь с
          </TextConstructor>
          {' '}
          <a href="mailto:support@digitalgeneration.ru" className="resetATagStyles">
            <TextConstructor color="#0D8E74" fz="16" lh="24" fw="400" textAlign="center">
              support@digitalgeneration.ru
            </TextConstructor>
          </a>
        </div>
      </TextConstructor>
      <Button
        typeButton="AnchorSquare"
        href="https://digitalgeneration.ru/my-study"
        classNameWrapper={s.btn}
        target="_blank"
      >
        Вернуться к моим курсам
      </Button>
    </VStack>
  );
};