import { FC } from "react";
import bigImage from '../../../../shared/assets/images/image (4).png';
import classNames from "classnames";
import s from './VisualElementsHaveQuestions.module.scss';
import { NewText } from "../../../../shared/ui/NewTypography/NewTypography";

interface VisualElementsHaveQuestionsProps {
  className?: string;
}

export const VisualElementsHaveQuestions: FC<VisualElementsHaveQuestionsProps> = ({
  className,
}) => {
  return (
    <div className={classNames(className, s.visualElementsHaveQuestions)}>
      <div className={s.visualElementsHaveQuestionsWrapper}>
        <img src={bigImage} alt="*" />
        <div className={s.firstCard}>
          <NewText name="question" color="white" className={s.text}>
            Сколько времени займет <br /> прохождение курса?
          </NewText>
        </div>
        <div className={s.secondCard}>
          <NewText name="question" color="white" className={s.text}>
            Какая длительность уроков?
          </NewText>
        </div>
        <div className={s.thirdCard}>
          <NewText name="question" color="white" className={s.text}>
            Получу ли я сертификат <br /> после окончания обучения?
          </NewText>
        </div>
      </div>
    </div>
  )
};
