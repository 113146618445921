import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from '../../../../shared/config/routeConfig/routeConfig';
import { useSelector } from 'react-redux';
import { getUserAuthData } from '../../../../entities/User/model/selectors/getUserData/getUserData';

interface RequireAuthProps {
    children: JSX.Element;
}

export function RequireAuth({ children }: RequireAuthProps) {
    const isAuth = !!useSelector(getUserAuthData);
    const location = useLocation();

    if (!isAuth) {
        return <Navigate to={RoutePath.authorization} state={{ from: location }} replace />;
    }

    return children;
}
