import { FC } from "react";
import { MONTHS, areEqual, fromDateStart, getMonthData, toDateStart } from "../lib/calendar";
import s from './Calendar.module.scss';
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { Grid } from "../../../shared/ui/Grid";
import classNames from "classnames";
import { useEventsQuery } from "../api/calendarApi";
import { Tooltip } from "antd";

interface CalendarProps {
  date?: Date;
  weekDayNames?: [string, string, string, string, string, string, string];
}

const COLUMNS = '7';

export const Calendar: FC<CalendarProps> = (props) => {
  const {
    date = new Date(),
    weekDayNames = ['Пн', 'Вт', 'Ср', 'Чт' , 'Пт', 'Сб', 'Вс'],
  } = props;

  const { data = { result: [] } } = useEventsQuery({ from_date_start: fromDateStart(date), to_date_start: toDateStart(date)});

  const month = date.getMonth();
  const monthData = getMonthData(date.getFullYear(), month);
  const dates = monthData.reduce((acc, week) => [...acc, ...week], []);

  return (
    <VStack
      className={s.calendar}
    >
      <TextConstructor color="#0E0E06" fz="18" lh="22" fw="500" className={s.blockTitle}>
        Предстоящие события
      </TextConstructor>

      <TextConstructor color="#5B5B5B" lh="22" fz="18" fw="500" className={s.blockMonth}>
        {MONTHS[date.getMonth()]}
      </TextConstructor>

        <Grid colums={COLUMNS} maxHorizontal maxVertical>
          {weekDayNames.map((weekDayName, i) => (
            <HStack key={i} justify="center" align="center">{weekDayName}</HStack>
          ))}

          {dates.map((_date, i) => {
            if (!_date) {
              return (
                <div key={i} />
              );
            }

            const isToday = areEqual(_date, date);
            const events = data.result.filter(({ date_start }) => areEqual(new Date(date_start), _date))
            const isDateWithEvent = !!events.length;

            const getColor = () => {
              if (isToday) {
                return '#0D8E74';
              }

              if (isDateWithEvent) {
                return '#FFFFFF';
              }

              return '#5B5B5B';
            }

            const getTooltipContent = () => {
              if (!events.length) {
                return null;
              }

              return (
                <VStack gap="16" key={i + 1000}>
                  <VStack gap="4">
                    {events.map(({ date_start, title, id, link }, i) => (
                      <a href={link} className="resetATagStyles" target="_blank" rel="noreferrer">
                        <HStack gap="4" key={id} justify="stretch" max>
                          <HStack
                            className={classNames(s.time, {
                              [s.blue]: i === 0,
                              [s.green]: i === 1,
                              [s.orange]: i > 1,
                            })}
                            align="center"
                            justify="center"
                          >
                            <TextConstructor color="#FFFFFF">
                              {`${new Date(date_start).getHours().toString().padStart(2, '0')}:${new Date(date_start).getMinutes().toString().padStart(2, '0')}`}
                            </TextConstructor>
                          </HStack>
                          <HStack
                            className={classNames(s.title, {
                              [s.blue]: i === 0,
                              [s.green]: i === 1,
                              [s.orange]: i > 1,
                            })}
                            align="center"
                            max
                          >
                            <TextConstructor color="#FFFFFF" fw="500" fz="15" lh="20">
                              {title}
                            </TextConstructor>
                          </HStack>
                        </HStack>
                      </a>
                    ))}
                  </VStack>
                </VStack>
              );
            }
            
            return (
              <Tooltip title={getTooltipContent()}>
                <div
                  className={classNames(s.dateWrapper)}
                  key={i}
                >
                  <div className={classNames(s.date, {
                    [s.dateToday]: isToday,
                    [s.dateWithEvent]: isDateWithEvent,
                  })}>
                    <TextConstructor color={getColor()} fw="500" fz="16" lh="24">
                      {_date.getDate()}
                    </TextConstructor>
                  </div>
                  <HStack className={s.points} gap="4">
                    {events.length > 0 && (
                      <div className={s.bluePoent} />
                    )}
                    {events.length > 1 && (
                      <div className={s.greenPoent} />
                    )}
                    {events.length > 2 && (
                      <div className={s.orangePoent} />
                    )}
                  </HStack>
                </div>
              </Tooltip>
            )
          })}
        </Grid>
    </VStack>
  );
};
