import { FC,  } from "react"
import classes from './Card.module.scss'
import classNames from "classnames"


interface IProps {
  title?: string
  children: React.ReactNode
  className?: string
}

export const Card:FC<IProps> = ({children, title, className}):JSX.Element => {
  return (
    <div className={classNames(classes.root, className)}>
      {title && (
        <h3 className={classes.title}>{title}</h3>
      )}
      {children}
    </div>
  )
}
