
import { VStack } from "../../../shared/ui/Stack";
import s from './MainPage.module.scss';
import { Header } from "../../../widgets/Header";
import { IntroBlock } from "../../../widgets/IntroBlock";
import { NewContainer } from "../../../shared/ui/NewContainer/NewContainer";
import { Courses } from "../../../widgets/Courses";
import { WhyChooseUs } from "../../../widgets/WhyChooseUs";
import { StartTrainingForFree } from "../../../widgets/StartTrainingForFree";
import { BlockWithInformationAboutPlatform } from "../../../widgets/BlockWithInformationAboutPlatform";
import { FreeCoursesShort } from "../../../widgets/FreeCoursesShort";
import { HaveQuestions } from "../../../widgets/HaveQuestions";
import { Footer } from "../../../widgets/Footer";
import { Reviews } from "../../../widgets/Reviews";

export const MainPage = () => {
  return (
    <div className={s.mainPage}>
      <Header />

      <VStack align="center" className={s.mainPageIntroBlock}>
        <IntroBlock />
      </VStack>

      <NewContainer className={s.mainPageCourses}>
        <Courses />
      </NewContainer>

      <NewContainer className={s.mainPageWhyChooseUs}>
        <WhyChooseUs />
      </NewContainer>

      <NewContainer className={s.mainPageStartTrainingForFree}>
        <StartTrainingForFree />
      </NewContainer>

      <NewContainer className={s.mainPageBlockWithInformationAboutPlatform}>
        <BlockWithInformationAboutPlatform />
      </NewContainer>

      <NewContainer className={s.mainPageFreeCoursesShort}>
        <FreeCoursesShort />
      </NewContainer>

      <NewContainer className={s.mainPageHaveQuestions}>
        <HaveQuestions />
      </NewContainer>

      <NewContainer className={s.mainPageReviews}>
        <Reviews />
      </NewContainer>

      <Footer />
    </div>
  );
};
