import { useParams } from "react-router-dom";
import { useCouseByIdQuery } from "../../../entities/Course";
import { ModuleItem } from "./components/ModuleItem/ModuleItem";
import classes from './ModulesList.module.scss'

export const ModulesList = () => {
  const { courseId } = useParams()

  const { data } = useCouseByIdQuery({ courseId: courseId || '' })
  const moduleInfo = data?.result?.modules
  // @ts-ignore
  const allLessons = moduleInfo?.reduce((acc, module) => ([...acc, ...(module?.lessons || [])]), []) || [];
  // @ts-ignore
  const currentLessonId = allLessons.find((lesson) => !!lesson?.is_available && !lesson?.user_complete)?.id || 0;

  return (
    <div className={classes.root}>
      {moduleInfo?.map((el, idx) => (
          <ModuleItem key={idx} moduleInfo={el} idx={idx} currentLessonId={currentLessonId} /> 
      ))}
    </div>
  );
};
