import { FC, useEffect, useState } from 'react';
import s from './SliderBlock.module.scss';
import classNames from 'classnames';
import { SlideOne } from '../Sliders/SlideOne/SlideOne';
import { SlideSecond } from '../Sliders/SlideSecond/SlideSecond';
import { SlideThird } from '../Sliders/SlideThird/SlideThird';
import { SlideFourth } from '../Sliders/SlideFourth/SlideFourth';
import { HStack, VStack } from '../../../../shared/ui/Stack';

interface SliderBlockProps {
  device: 'desktop' | 'tablet' | 'mobile';
}

export const SliderBlock: FC<SliderBlockProps> = ({ device }) => {
  const [showSlideIndex, setShowSlideIndex] = useState(0);
  const Component = device === 'mobile' ? VStack : HStack;

  useEffect(() => {
    let timer = setTimeout(() => {});

    const nextSlide = () => {
      timer = setTimeout(() => {
        setShowSlideIndex((prev) => prev + 1);
      }, 5000)
    }
    nextSlide();

    return () => {
      clearTimeout(timer);
    };
  }, [showSlideIndex]);

  const SlidersElements = [
    <SlideOne device={device} />,
    <SlideSecond device={device} />,
    <SlideThird device={device} />,
    <SlideFourth device={device} />,
  ];

  const SlidersElementsRender = SlidersElements
    .filter((_, i) => showSlideIndex === i)
    .map((slide) => slide);

  if (!SlidersElementsRender.length) {
    setShowSlideIndex(0);
  }

  return (
    <div className={classNames(s.sliderBlock, device)}>
      <div className={classNames(s.sliderBlockInside)}>
        {SlidersElementsRender}

        <Component className={s.dotsWrapper} gap="10">
          {SlidersElements.map((_, i) => {
            return (
              <button
                onClick={() => setShowSlideIndex(i)}
                className={classNames('resetButtonTagStyles', s.dot, {
                  [s.dotActive]: showSlideIndex === i,
                })}
              />
            )
          })}
        </Component>
      </div>
    </div>
  );
};
