import { useMediaQuery } from "../../../../shared/hooks";
import { DesktopReviews } from "../DesktopReviews/DesktopReviews";
import { MobileReviews } from "../MobileReviews/MobileReviews";
import { TabletReviews } from "../TabletReviews/TabletReviews";

export const Reviews = () => {
  const showDesktop = useMediaQuery('(min-width: 1500px)');
  const showTablet = useMediaQuery('(min-width: 900px)');

  if (showDesktop) {
    return (
      <DesktopReviews />
    );
  }

  if (showTablet) {
    return (
      <TabletReviews />
    );
  }

  return (
    <MobileReviews />
  );
};
