import { Link } from "react-router-dom";
import { CourseCard, CoursesList, mapCoursesWithVisualElements, mapCoursesWithAuthors } from "../../../entities/Course";
import { useCoursesQuery } from "../../../entities/Courses";
import { NewText } from "../../../shared/ui/NewTypography/NewTypography";
import { HStack, VStack } from "../../../shared/ui/Stack";
import { TextConstructor } from "../../../shared/ui/TextConstructor";
import { toCourses } from "../../../shared/config/routeConfig/routeConfig";
import { useMyCoursesQuery } from "../../MyCoursesList/api/myCoursesListApi";

export const Courses = () => {
  const { data } = useCoursesQuery();
  const { data: dataMyCourses } = useMyCoursesQuery();

  const myCourses = dataMyCourses?.result;

  const numberCourses = data?.result.length;

  const courses = [...(data?.result || [])].splice(0, 4);

  const coursesWithVisualElements = mapCoursesWithVisualElements(courses);
  const coursesWithAuthors = mapCoursesWithAuthors(coursesWithVisualElements);
  const coursesWithAvaible = coursesWithAuthors.map((course) => ({ ...course, isAvailable: !!myCourses?.some(({ id }) => id === course.id) }));

  return (
    <VStack gap="40" max>
      <HStack align="end" justify="between" max>
        <HStack gap="10" align="start">
          <NewText name="H2">
            Курсы
          </NewText>
          <TextConstructor
            fw="700"
            fz="30"
            lh="32"
            color="#74B500"
            font="Akzidenz-Grotesk"
          >
            {numberCourses}
          </TextConstructor>
        </HStack>

        <Link to={toCourses()}>
          <NewText name="Bt" color="greenMain">
            Все курсы
          </NewText>
        </Link>
      </HStack>

      <CoursesList>
        {coursesWithAvaible.map(({
          // @ts-ignore
          id, name, description, pay_button_text, visualElements, author, landing_link, isAvailable,
        }, index) => (
          <CourseCard
            id={String(id)}
            name={name}
            description={description}
            buttonText={pay_button_text}
            author={author}
            visualElements={{
              ...visualElements,
              variantFigureBackground: index + 1,
            }}
            href={landing_link}
            isAvailable={isAvailable}
            tags={['-30%', 'Хит']}
          />
        ))}
      </CoursesList>
    </VStack>
  );
};
