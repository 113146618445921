import { Link, useParams } from 'react-router-dom';
import { useLessonQuery } from '../../../entities/Lesson';
import { useNearbyLessons } from '../hooks/useNearbyLessons';
import { LessonList } from '../../../widgets/LessonList';
import { Container } from '../../../shared/ui/Container';
import classes from './LessonPage.module.scss';
import { NavigateBlock } from './component/NavigateBlock/NavigateBlock';
import { ModulesListMini } from '../../../widgets/ModulesListMini';
import { useState } from 'react';
import { Footer } from '../../../widgets/Footer';
import { BackButton } from '../../../shared/ui/BackButton';
import { toCoursePage, toLessonPage } from '../../../shared/lib/links';
import { HStack } from '../../../shared/ui/Stack';
import DescriptionIcon from '../../../shared/assets/images/description.svg'
import { Header } from '../../../widgets/Header';
import InnerHTML from 'dangerously-set-html-content';

export const LessonPage = () => {
  const { courseId, lessonId } = useParams();
  const [openMenu, setOpenMenu] = useState(false);

  const dataLesson = useLessonQuery({ lessonId: lessonId || '1' });
  const { data } = useNearbyLessons({ courseId: courseId || '1' });

  const lessonInfo = dataLesson?.data?.result;
  const description = lessonInfo?.description;

  return (
    <>
      <Header />

      <Container className={classes.root}>
        <BackButton link={toCoursePage(String(courseId))} text="Курс"/>
        <NavigateBlock
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          navigate={data}
          courseId={courseId}
          lessonInfo={lessonInfo}
        />

        {description && (
          <HStack className={classes.info} gap="32">
            <img src={DescriptionIcon} alt="*" />
            <InnerHTML html={description} key={lessonId} />
          </HStack>
        )}

        <div className={classes.wrapper}>
          <LessonList />
          {openMenu ? <ModulesListMini /> : null}
        </div>
        <div className={classes.bottomBtn}>
          <Link style={{opacity: data[0] ? '1' : '0.5'}} to={data[0] ? toLessonPage(String(courseId), String(data?.[0]?.id)) : '#'} className={classes.navBtn}>{'< Предыдущий'}</Link>
          <Link style={{opacity: data[0] ? '1' : '0.5'}} to={data[1] ? toLessonPage(String(courseId), String(data?.[1]?.id)) : '#'} className={classes.navBtn}>{'Следующий >'}</Link>
        </div>
      </Container>
      <Footer />
    </>
  );
};
