import { NewContainer } from "../../../shared/ui/NewContainer/NewContainer";
import { AllCourses } from "../../../widgets/AllCourses";
import { CardSoonMore } from "../../../widgets/CardSoonMore";
import { Footer } from "../../../widgets/Footer";
import { HaveQuestions } from "../../../widgets/HaveQuestions";
import { Header } from "../../../widgets/Header";
import s from './CoursesPage.module.scss';

export const CoursesPage = () => {
  return (
    <>
      <Header />

      <NewContainer className={s.allCoursesConteiner}>
        <AllCourses />
      </NewContainer>

      <NewContainer className={s.cardSoonMoreConteiner}>
        <CardSoonMore />
      </NewContainer>

      <NewContainer className={s.haveQuestionsConteiner}>
        <HaveQuestions />
      </NewContainer>

      <Footer />
    </>
  )
};
