import { rtkApiAuth } from "../../../shared/api/rtkApiAuth";
import { ApiResponseWrapper } from "../../../shared/types/api";
import { ChangePasswordRequestQueryArg, ChangePasswordRequestResultType } from "../types/changePasswordTypes";

const changePasswordApi = rtkApiAuth.injectEndpoints({
  endpoints: (build) => ({
    changePassword: build.mutation<ApiResponseWrapper<ChangePasswordRequestResultType>, ChangePasswordRequestQueryArg>({
      query: (body) => {
        return {
          url: 'user/change_password/',
          method: 'PUT',
          body,
        };
      },
    }),
  }),
});

export const { useChangePasswordMutation } = changePasswordApi;
