import { useMediaQuery } from "../../../../shared/hooks";
import { DesktopHaveQuestions } from "../DesktopHaveQuestions/DesktopHaveQuestions";
import { MobileHaveQuestions } from "../MobileHaveQuestions/MobileHaveQuestions";
import { TabletHaveQuestions } from "../TabletHaveQuestions/TabletHaveQuestions";
import s from './HaveQuestions.module.scss';

export const HaveQuestions = () => {
  const showDesktop = useMediaQuery('(min-width: 1500px)');
  const showTablet = useMediaQuery('(min-width: 900px)');

  const Content = () => {
    if (showDesktop) {
      return (
        <DesktopHaveQuestions />
      );
    }
  
    if (showTablet) {
      return (
        <TabletHaveQuestions />
      );
    }
  
    return (
      <MobileHaveQuestions />
    );
  };

  return (
    <div className={s.haveQuestions}>
      {Content()}
    </div>
  );
};
