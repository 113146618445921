import { FC } from "react";
import { HStack } from "../../../shared/ui/Stack";
import { Radio } from "../../../shared/ui/Radio/ui/Radio";
import s from './PaymentMethod.module.scss';
import SBPimage from '../../../shared/assets/images/SBP.png'
import VISAimage from '../../../shared/assets/images/VISA.png'
import tinkoffImage from '../../../shared/assets/images/paymingMethods.jpg'
import classNames from "classnames";

interface PaymentMethodProps {
  type: 'SBP' | 'VISA' | 'Tinkoff';
  onClick: () => void;
  isActive: boolean,
}

export const PaymentMethod: FC<PaymentMethodProps> = (props) => {
  const {
    type,
    onClick,
    isActive,
  } = props;

  const getImage = () => {
    if (type === 'SBP') {
      return SBPimage;
    }

    if (type === 'VISA') {
      return VISAimage;
    }

    if (type === 'Tinkoff') {
      return tinkoffImage;
    }
  }

  return (
    <button onClick={onClick} className="resetButtonTagStyles">
      <HStack gap="10" justify="between" max className={classNames(s.paymentMethod, isActive && s.active)}>
        <img src={getImage()} alt="*" />
        <Radio isChecked={isActive} />
      </HStack>
    </button>
  );
};
