import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REFRESH_TOKEN_LOCALSTORAGE_KEY, TOKEN_LOCALSTORAGE_KEY } from '../const/localstorage';
import { userActions } from '../../entities/User';
import { LMS_PAYMENT_API } from '../const/ENV';
import { getNewToken } from './getNewToken/getNewToken';

const baseQuery = fetchBaseQuery({
  baseUrl: LMS_PAYMENT_API,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${localStorage.getItem(TOKEN_LOCALSTORAGE_KEY)}`);

    return headers;
  }
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

  if (result?.error?.status === 401 && refreshToken) {
      const refreshResult = await getNewToken();

      if (refreshResult?.result) {
          localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, refreshResult?.result.tk);
          localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refreshResult?.result.tk_refresh);
          result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch(userActions.logout())
      }
  }

  return result
}

export const rtkApiPayment = createApi({
  reducerPath: 'apiPayment',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});