import { useEffect, useState } from 'react';

export const useFocus = (target: React.MutableRefObject<any>, defaultState = false) => {
  const [state, setState] = useState(defaultState);
  useEffect(() => {
    const onFocus = () => setState(true);
    const onBlur = () => setState(false);

    if (!target.current) {
      return;
    }

    target.current.addEventListener("focus", onFocus);
    target.current.addEventListener("blur", onBlur);

    return () => {
      if (!target.current) {
        return;
      }

      target.current.removeEventListener("focus", onFocus);
      target.current.removeEventListener("blur", onBlur);
    };
  }, [target]);

  return state;
};